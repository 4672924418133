import { useEffect, useState } from "react";
import AXIOS from "../../Services/axios";
import "./Home.css";
import { formatDate } from "../../Services/validation";
import { BiRupee } from "react-icons/bi";
import businessPeople from "./businessPeople.png";
import { useNavigate } from "react-router";
import { useUserContext } from "../../Services/contextSevices/indexedDbContext";
import { IoClose } from "react-icons/io5";
import { usePointsData } from "../../Services/contextSevices/pointsGlobalState";
import { FaWhatsapp } from "react-icons/fa";
import { CgMail } from "react-icons/cg";

const Home = () => {
  const [requirements, setRequirements] = useState([]);
  const navigate = useNavigate();
  const { storedUser } = useUserContext();
  const userId = storedUser ? storedUser.userId : null;
  const [isUser, setIsUser] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isClose, setIsClose] = useState(false);
  const [privacyPopupOpen, setPrivacyPopupOpen] = useState(false);

  const [leadInfo, setLeadInfo] = useState({
    name: "",
    number: "",
  });
  const { pointsData } = usePointsData();
  const { pointsBalance } = pointsData;
  const email = "thebenift@gmail.com";
  const phoneNumber = "7090106106";
  const whatsappUrl = `https://wa.me/${7090106106}`;

  useEffect(() => {
    if (userId !== null) {
      setIsUser(true);
    } else {
      setIsUser(false);
    }
  }, [userId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await AXIOS.get("/home/requirement", {
          withCredentials: true,
        });
        if (response.status === 200) {
          const data = response.data;
          if (data) {
            const formattedData = data.map((item) => ({
              ...item,
              expire: formatDate(new Date(item.expire.split("T")[0])),
            }));
            setRequirements(formattedData);
          }
        }
      } catch (error) {
        console.error("Error fetching data");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await AXIOS.get("/homePage/data");
        setData(response.data);
        const timeout = setTimeout(() => {
          setIsClose(true);
        }, 2000);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const handleViewMore = () => {
    if (isUser) {
      navigate("/viewRequirement");
    } else {
      navigate("/register");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLeadInfo({ ...leadInfo, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await AXIOS.post("/instaFund/lead", leadInfo, {
        headers: {
          "Content-Type": "application/json", // Set the proper content type
        },
      });

      if (response.status === 200) {
        setIsClose(false);
      }
    } catch (error) {
      console.error("Error:"); // Handle any errors
    }
  };

  const togglePopup = () => {
    setPrivacyPopupOpen(!privacyPopupOpen);
  };

  // if (loading) {
  //   return (
  //     <div>
  //       <div className="loading-container">
  //         <img src={loadingGif} alt="" />
  //         <p>Loading....</p>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div className="home-container">
      <div className="backgroundImgcontainer">
        <img
          className="fullscreenBackgroundImg"
          src="https://images.rawpixel.com/image_800/cHJpdmF0ZS9zdGF0aWMvaW1hZ2VzL3dlYnNpdGUvMjAyMi0wNS9ybTMxMi1rd2FuLTAxXzIuanBn.jpg?s=XWJOZuVucBcC68n4upisVQa4Q8XOjPyJa5Ave4oJiaE"
          alt=""
        />
      </div>

      {!isUser && isClose && (
        <div className="popUp">
          <div className="popUpContainer">
            <IoClose className="IoClose" onClick={() => setIsClose(false)} />
            <div className="popUpContent">
              <div className="contentSection">
                <p className="popUpMessage">
                  Seize the Opportunity to Grow Your Business with Our Instant
                  Funds for Your Business Transactions!
                </p>
                <form onSubmit={handleSubmit}>
                  <div className="inquirySection">
                    <p className="inquiryMessage">
                      Provide your Details for Instant Funds Enquiries
                    </p>
                    <div className="inputSection">
                      <input
                        type="text"
                        className="leadName"
                        placeholder="Enter Name"
                        name="name"
                        onChange={handleInputChange}
                        value={leadInfo.name}
                        required
                      />
                      <input
                        type="tel"
                        className="leadNumber"
                        placeholder="Contact Number"
                        maxLength={10}
                        name="number"
                        onChange={handleInputChange}
                        value={leadInfo.number.replace(/[^0-9]/g, "")}
                        required
                      />
                    </div>
                    <button type="submit" className="sendButton">
                      Send
                    </button>
                  </div>
                </form>
              </div>
              <p className="networkPromotion">
                Supercharge Your Business Network: Refer Five Members for a
                Whopping 1000 Points!
              </p>
              <p className="quote">
                Don't let this chance slip through your fingers.
              </p>
            </div>
          </div>
        </div>
      )}

      {isUser && isClose && pointsBalance < 25 && (
        <div className="popUp">
          <div className="popUpContainer">
            <IoClose className="IoClose" onClick={() => setIsClose(false)} />
            <div className="popUpContent">
              <div className="contentSection">
                <p className="popUpMessage">
                  Your Balance is currently running low. Add more Points to take
                  further action on requirements
                </p>
              </div>
              <p className="networkPromotion">
                KEEP A SUFFICIENT POINTS BALANCE FOR ALL ACTIONS.
              </p>
              <p className="quote">
                Strategic moves, bold decisions, lasting legacies.
              </p>
            </div>
          </div>
        </div>
      )}

      <div class="text-overlay">
        <div class="tagline-container">
          <p class="tagline">Demand it and We Deliver.</p>
          <p class="home-content">
            We bridge the gap between demand and supply by connecting people.
          </p>
          {!isUser && (
            <p class="call-to-action">
              Let's Get Started{" "}
              <span class="register-link" onClick={() => navigate("/register")}>
                Register Now
              </span>
            </p>
          )}
        </div>
      </div>

      <div className="contentContainer">
        <div class="platform-section">
          <h1>WE OFFER A PLATFORM FOR</h1>
          <div class="service-container">
            <div class="service">
              <p class="service-title">POST YOUR REQUIREMENTS</p>
              <p className="serviceDescription">
                Have a specific need or request? Our platform makes it easy to
                share your requirements. Whether it's a product, service, or
                assistance you seek, simply post your requirement, and let us
                connect you with the right solutions.
              </p>
            </div>
            <div class="service">
              <p class="service-title">VIEW CLIENTS REQUIREMENTS</p>
              <p className="serviceDescription">
                Explore the needs and preferences of our clients by accessing
                their posted requirements. Our platform offers you the
                opportunity to view detailed client requests, making it easier
                than ever to connect with potential customers who are seeking
                your products or services.
              </p>
            </div>
            <div class="service">
              <p class="service-title">EXPRESS INTEREST EASILY</p>
              <p className="serviceDescription">
                Expressing your interest in fulfilling requirements has never
                been simpler. Our platform offers a straightforward and
                user-friendly process for indicating your intent. Whether you're
                a service provider or a supplier, connecting with potential
                customers and clients is just a click away.
              </p>
            </div>
            <div class="service">
              <p class="service-title">EFFORTLESS DEAL CLOSURE</p>
              <p className="serviceDescription">
                Close deals with our assistance by finding the perfect partner
                for your requirements. Our platform specializes in matching you
                with the right businesses and professionals, ensuring that your
                needs are met efficiently and effectively. Join us today to
                experience seamless deal-making and achieve your goals.
              </p>
            </div>
          </div>
        </div>

        <div className="aboutCompany">
          <div>
            <img
              src="https://img.lovepik.com/photo/50057/1958.jpg_wh860.jpg"
              alt=""
            />
          </div>
          <div className="comapanyDetails">
            <div>
              <h1>ABOUT US</h1>
              <p>
                Welcome to Benift.in, we are the bridge that connects people and
                businesses, ensuring that demand and supply meet seamlessly in
                every industry. With a mission to empower individuals and
                organizations, we specialize in facilitating connections that
                drive growth and prosperity. Established in 2012, Scope Business
                Support Services, we've been serving the IT industry with
                professional hardware and software solutions. Our commitment to
                innovation and efficiency keeps us ahead in the ever-changing
                market landscape. Join us in this journey of collaboration and
                opportunity, where we believe that together, we can shape a
                world where every need finds its supply, and every vision
                becomes a reality.
              </p>
            </div>
            <div>
              <a href={`mailto:${email}`} className="aboutCompanyAction">
                CONTACT US
              </a>
            </div>
          </div>
        </div>

        {data && (
            <div class="stats-container">
              <div class="stat">
                <p class="stat-number">
                  {data.requirementsCounts}
                </p>
                <p class="stat-description">CURRENT REQUIREMENTS</p>
              </div>
              <div class="stat">
                <p class="stat-number">
                  <BiRupee />
                  {data.totalValueSum}
                </p>
                <p class="stat-description">TOTAL REQUIREMENTS VALUE</p>
              </div>
              <div class="stat">
                <p class="stat-number">
                  {data.membersCount}
                </p>
                <p class="stat-description">TOTAL MEMBERS AND COUNTING</p>
              </div>
            </div>
          )}

        {requirements.length > 2 && (
          <div className="sampleRequirements">
            <div className="sampleRequirementsHeading">
              <h1 class="sampleRequiremenTitle">TOP REQUIREMENTS</h1>
              <p class="viewMore" onClick={handleViewMore}>
                VIEW MORE
              </p>
            </div>

            <div className="homeRequirementListContainer">
              <div id="homeRequirementList">
                {requirements.map((requirement) => (
                  <div
                    key={requirement._id}
                    className="homeRequirementCard"
                    onClick={handleViewMore}
                  >
                    <div className="requirementHeader">
                      <div className="requirementTitle">Requirement</div>
                      <span className="requirementId ">{requirement.prId}</span>
                    </div>
                    <div className="requirementSection">
                      <div className="requirementInfo">
                        <span className="requirementLabel">Industry</span>
                        <span className="requirementValue">
                          {": "}
                          {requirement.businessIndustry}
                        </span>
                      </div>
                      <div className="requirementInfo">
                        <span className="requirementLabel">Category</span>
                        <span className="requirementValue">
                          {": "}
                          {requirement.businessCategory}
                        </span>
                      </div>

                      <div className="requirementInfo">
                        <span className="requirementLabel">Type</span>
                        <span className="requirementValue">
                          {": "}
                          {requirement.businessType}
                        </span>
                      </div>
                      <div className="requirementInfo">
                        <span className="requirementLabel">City</span>
                        <span className="requirementValue ">
                          {": "}
                          {requirement.city}
                        </span>
                      </div>
                    </div>
                    <div className="customRequirementValueSection">
                      <div className="customRequirementInfo">
                        <span className="customRequirementLabel">
                          Business Value
                        </span>
                        <span className="customRequirementValue totalValue">
                          <BiRupee />
                          {requirement.totalValue}
                        </span>
                      </div>
                      <div className="customRequirementInfo">
                        <span className="customRequirementLabel">
                          Fullfill By
                        </span>
                        <span className="customRequirementValue ">
                          {requirement.expire}
                        </span>
                      </div>
                      <span className="requirementExpireId ">
                        <span>Valid For</span> : {requirement.daysRemaining}{" "}
                        Days
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        <div class="encourageContainer">
          <div class="encourageImageContainer">
            <img src={businessPeople} alt="" class="encourageMainImage" />
          </div>
          <div class="encourageTextContainer">
            <div class="encourageMainText">
              LET'S COME TOGETHER TO SIMPLIFY LIFE AND MAKE IT EXTRAORDINARY.
            </div>
            <p class="encourageTagline">
              Where Needs Meet Resources: Our Business is Your Success
            </p>
            <p class="encourageStartButton" onClick={handleViewMore}>
              START NOW
            </p>
          </div>
        </div>

        <div className="testimonis">
          <div className="testimonialHeading">
            <p>TESTIMONIALS</p>
            <span>Voices of Industry Expertise</span>
          </div>

          <div className="testimonialsContainer1">
            <div class="personContainer">
              <div class="personName">PRADEEP KUMAR</div>
              <div class="personTitle">Information Technology</div>
              <p class="personQuote">
                "As an entrepreneur, finding reliable suppliers and customers is
                crucial. This website has been instrumental in connecting me
                with the right people, saving me time and helping my business
                thrive."
              </p>
            </div>

            <div class="personContainer">
              <div class="personName">NISHA GAJANAN</div>
              <div class="personTitle">Fashion Designer</div>
              <p class="personQuote">
                "I've used several platforms, but this one stands out. It's
                user-friendly, and the matchmaking is spot-on. It's not just
                about connecting people; it's about connecting the right people.
                Great job!"
              </p>
            </div>
          </div>

          <div className="testimonialsContainer2">
            <div class="personContainer">
              <div class="personName">RAMAMURTHY K</div>
              <div class="personTitle">Construction Industry</div>
              <p class="personQuote">
                "In my role, finding reliable suppliers is a top priority. This
                website has simplified the procurement process, allowing me to
                connect with reputable suppliers quickly and efficiently. A
                must-have for procurement professionals!"
              </p>
            </div>

            <div class="personContainer">
              <div class="personName">KISHORE S</div>
              <div class="personTitle">Pharma Industry</div>
              <p class="personQuote">
                "This platform has been a game-changer for my small business!
                Connecting with suppliers and buyers has never been easier. It's
                streamlined our processes and boosted efficiency. Highly
                recommend!"
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="footer">
        <div className="footerContactInfo">
          <CgMail className="footerIcon" />:{" "}
          <a href={`mailto:${email}`} target="_blank" className="footerEmail">
            {email}
          </a>{" "}
          / <FaWhatsapp className="footerIcon" />:{" "}
          <a href={whatsappUrl} target="_blank" className="footerEmail">
            {phoneNumber}
          </a>
        </div>
        <div className="rightsReserved">
          <span className="privacyPolicy" onClick={togglePopup}> Privacy Policy </span> | © All Rights
          Reserved by
          <span className="footerCompanyName">
            SCOPE BUSINESS SUPPORT SERVICES
          </span>
        </div>

        {privacyPopupOpen && (
          <div className="privacyPopUp">
            <div className="privacyPopUpContainer">
              <IoClose className="privacyIoClose" onClick={togglePopup} />

              <div className="privacyPopUpMessage">
                <h3 className="privacytitle">Privacy Policy</h3>

                <div>
                  <p>
                    This privacy statement applies to your use of any products,
                    services, content, features, technologies, or functions, and
                    all related websites, mobile apps, mobile sites or other
                    online platform or applications offered to you by us
                    (collectively the "Services/Platform"). We collect, use, and
                    share personal information to help the Benift.in and its
                    affiliate websites work and to keep it safe (details below).
                  </p>
                </div>

                <div>
                  <p>
                    Scope Business Support Services, acting itself and through
                    its subsidiaries, is the data controller of your personal
                    information collected. This policy is effective 01/01/2024.
                    <br />
                    Information posted on Benift.in is obviously publicly
                    available. Our servers are located in Mumbai, India.
                    Therefore, if you choose to provide us with personal
                    information, you are consenting to the transfer and storage
                    of that information on our servers.
                  </p>
                  <p>1. What data do we collect about you?</p>
                  <p>1.1. Data provided through direct interactions</p>
                </div>

                <div>
                  <ul>
                    <strong>
                      1.1.1. Registration and other account information
                    </strong>
                    <br />
                    When you register to use our Services, we may collect the
                    following information about you:
                    <li>
                      If you register using your Google account: first name,
                      last name and email address.
                    </li>
                    <li>
                      {" "}
                      If you register using your Facebook account: we collect
                      first name and last name as appeared on your Facebook
                      account, Facebook IDs and the URL to your Facebook profile
                      picture. In the event you have provided permission to
                      Facebook through their in-app privacy option (which
                      appears just before you register on our Platform), we may
                      collect your gender, age or email id depending on the
                      permissions granted by you.
                    </li>
                    <li>
                      {" "}
                      If you register using your Truecaller account, we collect
                      first name, last name, email address and your mobile
                      number
                    </li>
                    <li>
                      {" "}
                      If you register using your mobile number we collect your
                      mobile number
                    </li>
                    <li>
                      {" "}
                      If you register using your email id we collect your email
                      id
                    </li>
                    <li>
                      {" "}
                      If you register using your Apple ID: your first and last
                      name and email address; you can decide whether to share
                      your email address with us when you sign in your Apple
                      account
                    </li>
                    <li>
                      {" "}
                      We also collect your primary email id used to download the
                      application from the app store
                    </li>
                  </ul>
                </div>

                <div>
                  <p>
                    Depending on the choices you make during the log-in to our
                    Services or during the process of engaging our Services, you
                    may opt to give the following additional personal data:
                  </p>
                  <ul>
                    <li>Your Name</li>
                    <li>E-mail Address</li>
                    <li>Mobile number</li>
                    <li>
                      Your credit card/debit card details in case you choose to
                      purchase our paid services
                    </li>
                  </ul>
                </div>

                <div>
                  <strong>1.1.2. Validation of your account</strong>
                  <p>
                    We validate the accounts of Benift.in users by using Email
                    verification to ensure that each account is associated with
                    a real and unique user. This validation process is initiated
                    once you proceed with posting a requirement listing , take
                    an action on a requirement listing or initiate a purchase or
                    sales or services. In order to validate your account, we
                    will send you an email on a valid email address provided by
                    you. This process is entirely free of charge.
                  </p>
                  <p>
                    If you do not agree to validate your account, then your
                    account will remain active and you will be able to use our
                    Services with limited functionality. This limited
                    functionality of an account implies that you cannot publish
                    new postings of requirements listings or edit, update,
                    promote, extend, reactivate, deactivate or delete existing
                    requirement postings listings until your account is verified
                    by Email. You will also not be able to receive or reply to
                    any messages from other users.
                  </p>
                  <p>
                    In case you create several accounts using the same mobile
                    number or email address and validate all those accounts via
                    email verification, all such accounts will have a limited
                    functionality and you will be asked to choose one of them.
                    The account chosen by you will return to full functionality,
                    and the rest of the accounts will remain to have limited
                    functionality.
                  </p>
                  <p>
                    Once you have validated your account, it will remain
                    associated with the mobile number or email address used for
                    the email verification. If you wish to change the mobile
                    number associated with your account, you will need to
                    contact our Customer Support team.
                  </p>
                </div>

                <div>
                  <strong>
                    1.1.3. Communication through the chat feature on our
                    Platform
                  </strong>
                  <p>
                    When you use our chat feature to communicate with other
                    users, we collect information that you choose to provide to
                    other users through this feature.
                  </p>
                  <p>
                    1.2. Data we collect automatically when you use of our
                    Services
                  </p>
                  <p>
                    When you interact with our Services, we automatically
                    collect the following information about you:
                  </p>
                  <p>
                    We collect device-specific information such as operating
                    system version, unique identifiers, IMEI number, and
                    standard web log information. For example, the name of the
                    mobile network that you are using. We associate the device
                    identifiers with your account.
                  </p>
                </div>

                <div>
                  <strong>1.2.1 Device Information</strong>
                  <p>
                    We may require access to other apps installed on the device,
                    to enhance your experience. You may choose not to grant
                    access to such apps, which will allow you access to use our
                    services, but with limited functionality.
                  </p>
                </div>

                <div>
                  <strong> 1.2.2. Location information</strong>
                  <p>
                    We collect and process information about your location by
                    using the pin code entered by you. We DO NOT USE Your
                    location trackers in order to enable more privacy and
                    secured interactions while using benift.in
                    application/software. The Location information will be used
                    only while processing your requirement of product / service.
                    However otherwise the same is not shared with any
                    third-party.
                  </p>
                </div>

                <div>
                  <strong>1.2.3. Client and Log data</strong>
                  <p>
                    Technical details, including the Internet Protocol (IP)
                    address of your device, time zone and operating system. We
                    will also store your login information (registration date,
                    date of last password change, date of the last successful
                    login), type and version of your browser.
                  </p>
                </div>

                <div>
                  <strong>1.2.4. Clickstream data</strong>
                  <p>
                    We collect information about your activity on our Platform,
                    which includes
                  </p>
                  <p>
                    1.2.4.1. The sites from which you accessed our Platform,
                    date and time stamp of each visit, searches you have
                    performed, listings or advertisement banners you clicked,
                    your interaction with such advertisements or listings,
                    duration of your visit and the order in which you visit the
                    content on our Platform.
                  </p>
                  <p>
                    1.2.4.2. Computer sign-on data, statistics on page views,
                    traffic to and from Benift.in and Ad data (all through
                    cookies – you can take steps to disable the cookies on your
                    browser although this is likely to affect your ability to
                    use the site), Google advertising Id on Android App which is
                    a unique, user-resettable ID for advertising, provided by
                    Google Play services
                  </p>
                </div>

                <div>
                  <strong>1.2.5. Cookies and Similar Technologies</strong>
                  <p>
                    We use cookies to manage our users’ sessions, to store your
                    preference language selection and deliver you relevant
                    advertisements. "Cookies" are small text files transferred
                    from a web server to the hard drive of your device. Cookies
                    may be used to collect the date and time of your visit, your
                    browsing history, your preferences, and your username. You
                    can set your browser to refuse all or some cookies, or to
                    alert you when websites set or access cookies. If you
                    disable or refuse cookies, please note that some parts of
                    our Services/Platform may become inaccessible or not
                    function properly
                  </p>
                  <p>2. Why do we process your personal information?</p>
                  <p>
                    We will only use your personal data when the law allows us
                    to. Most commonly, we will use your personal data in the
                    following circumstances:
                  </p>
                  <ul>
                    <li>
                      Where we need to perform the contract we are about to
                      enter into or have entered into with you.
                    </li>
                    <li>
                      Where we share information with our business partners for
                      our business needs.
                    </li>
                    <li>
                      Where it is necessary for our legitimate interests to
                      improve our Services and to provide you a safe and secure
                      Platform.
                    </li>
                    <li>
                      Where we need to comply with a legal or regulatory
                      obligation.
                    </li>
                  </ul>
                  <p>
                    In certain circumstances, we may also process your personal
                    data based on your consent. If we do this, we will let you
                    know the purpose and the category of personal data to be
                    processed at the time we seek your consent.
                  </p>
                  <p>
                    We have set out below a description of the reasons for which
                    we use your personal data, [and which of the legal bases we
                    rely on to do so. We have also identified what our
                    legitimate interests are, where appropriate].
                  </p>
                </div>

                <div>
                  <p>
                    2.1. For providing access and delivering Services through
                    our Platform
                  </p>
                  <p>
                    <strong>2.1.1.</strong> Your login is based on the System
                    automatically generated ID which does not disclose your
                    identity, name, mobile number or email id. By logging in to
                    our website, you can manage your profile details, update or
                    edit, However you can not view other member/subscribers
                    information, and others also will not be able to access your
                    information. This is to safeguard the personal business and
                    communication information.
                  </p>
                  <p>
                    <strong> 2.1.1.</strong> You can access the account by
                    resetting your password through the forgot password process
                    which also will be emailed to the registered email id for
                    verification and validation.
                  </p>
                  <p>
                    <strong> 2.1.3.</strong>
                    We use third party payment service providers to process any
                    payment you make to our Services. Depending on the method of
                    payment, you may be requested to provide us with your
                    payment and credit card details which we will then provide
                    to the payment service provider in order to process your
                    payment. We do not store your credit card information,
                    unless you choose the option to save such information in
                    order to make recurring payments easier without having to
                    re-enter your details each time. In such cases, we only
                    store your card holder name, the card expiry date, your card
                    type and the last four digits of the card number. We do not
                    store any credit card code verification values and merely
                    forward such values and your credit card number in an
                    encrypted manner for the purpose of processing your payment
                    by our payment service provider. We process the above
                    information for adequate performance of our contract with
                    you.
                  </p>
                  <p>
                    <strong>2.1.4.</strong> Benift.in accesses metadata and
                    other information associated with other files stored on your
                    mobile device. This will include photographs, audio and
                    video clips, personal contacts and address book information.
                    If you permit Benift.in to access the address book on your
                    device, we may collect names and contact information from
                    your address book and share the same with telecom Companies
                    for promotional activities and to facilitate social
                    interactions through our services and for other purposes
                    described in this Policy or at the time of consent or
                    collection. We take reasonable efforts to ensure that third
                    parties adhere to our Privacy policies, rules and
                    guidelines. All the information we receive about you are
                    stored on secure servers and we have implemented technical
                    and organizational measures that are necessary to protect
                    your personal data.
                  </p>
                </div>

                <div>
                  <p>
                    2.2. For improving your experience on the Platform and
                    developing new functionalities of the Platform
                  </p>
                  <p>
                    <strong>2.2.1.</strong> We use clickstream data to:
                  </p>
                  <ul>
                    <li>
                      Offer you tailored content, such as giving you more
                      relevant search results when using our Services.
                    </li>
                    <li>
                      To determine how much time you spend on our Platform and
                      in what manner you navigate through our Platform in order
                      to understand your interests and to improve our Services
                      based on this data. For example, we may provide you with
                      suggestions on content that you can visit based on the
                      contents you have clicked.
                    </li>
                    <li>
                      To communicate marketing and promotional offers, to
                      monitor and report the effectiveness of the campaign
                      delivery to our business partners and internal business
                      analysis.
                    </li>
                  </ul>
                </div>

                <div>
                  <p>
                    <strong> 2.2.2.</strong> If you choose to provide us with
                    your location data, we use your location data for following
                    purposes:
                  </p>
                  <ul>
                    <li>
                      To compile anonymous and aggregated information about the
                      characteristics and behavior of users, including for the
                      purposes of business analysis, segmentation and
                      development of anonymous profiles
                    </li>
                    <li>
                      To enhance the performance of our Services and to
                      personalize the content we direct towards you. For example
                      - with the help of location data we display ad listings
                      which are in your vicinity to improve your buying
                      experience. For this purpose, Google Maps is integrated
                      into our Platform. This means that both Google and we are
                      responsible for the processing of your location data in
                      the context of Google Maps. In any case, we will not
                      process your location data for any other purposes but
                      those explained in this Privacy Statement. However, Google
                      might process such location data for their own purposes as
                      explained in the Google Privacy Policy which can be
                      reviewed Your use of Google Maps through our Platform is
                      subject to Google Maps’ Terms of Service.
                    </li>
                    <li>
                      To measure and monitor your interaction with the
                      third-party advertisement banners, we place on our
                      Platform.
                    </li>
                  </ul>
                  <p>
                    <strong>2.2.3.</strong> With the help of your account
                    information, which includes your email ID and phone number,
                    we map the different devices (such as desktop, mobile,
                    tablets) used by you to access our Platform. This allows us
                    to associate your activity on our Platform across devices
                    and helps us in providing you a seamless experience no
                    matter which device you use.
                  </p>
                  <p>
                    <strong>2.2.4.</strong> We use the images you upload, the
                    descriptions and prices you provide in your ad listings to
                    train machine learning models to personalize search results
                    in relation to ad ranking and user interest, to improve the
                    identification and presentation of ad listings, to improve
                    the search function and to increase the likelihood of a
                    successful sale. This helps us to improve our Services and
                    to provide you with a better user experience.
                  </p>
                  <p>
                    <strong>2.2.5.</strong> To show and recommend ad listings on
                    our Services that may be of interest to you, we make use of
                    algorithms that use information related to your browsing
                    behaviour, items you bought, clickstream data, your user ID
                    and your location data if you have given us permission to
                    use this. We use this form of automated decision-making on
                    the basis of our legitimate interest in improving our
                    Services and provide a better user experience by offering
                    you more relevant ad listings.
                  </p>
                  <p>
                    <strong>2.2.6.</strong> We access and analyze your chat
                    messages with other users conducted through the chat
                    function on our Platform, for product enhancement and to
                    provide you with a better user experience (e.g. to identify
                    sold items and to provide you with active listings only).
                    Therefore, we develop and train machine learning models and
                    algorithms to automatically analyze your chat content. To
                    build and train our machine learning models our machine
                    learning specialists may review exemplary chat content
                    manually. In these circumstances highly restricted access
                    rights apply to selected machine learning specialists
                    analyzing the chat content. During this process, we are
                    de-identifying chat content as much as possible by applying
                    a scanning filter to detect and hide personal data such as
                    names, phone numbers, e-mail addresses. However, there may
                    still be cases beyond our control in which the chat content
                    may show certain personal data that you have chosen to
                    provide. We process the above information for adequate
                    performance of our contract with you and on the basis of our
                    legitimate interest to improve your experience of our
                    Services
                  </p>
                </div>

                <div>
                  <p>
                    2.3. To take your feedback, promote and offer you Services
                    that may be of your interest
                  </p>
                  <p>
                    <strong>2.3.1.</strong> We use your mobile number or email
                    address, log data and unique device identifiers to
                    administer and protect our Platform (including
                    troubleshooting, data analysis, testing, fraud prevention,
                    system maintenance, support, reporting and hosting of data).
                  </p>
                  <p>
                    <strong>2.3.2.</strong> We access and analyze your chat
                    messages with other users conducted through the chat
                    function on our Services for customer satisfaction, safety
                    and for fraud prevention purposes (e.g. to block spam or
                    abusive messages that may have been sent to you by other
                    users). Therefore, we develop and train machine learning
                    models and algorithms helping us to automatically detect and
                    prevent inappropriate and fraudulent user behavior. During
                    the analyzation and training process, we are de-identifying
                    chat content as much as possible by anonymizing the unique
                    identification values assigned to users. However, there may
                    still be cases beyond our control in which the chat content
                    may show certain personal data that you have chosen to
                    provide. Only in limited cases and circumstances, our
                    customer safety and security specialists review chat content
                    manually, for example, if we have strong indications leading
                    to the urgent suspicion of fraudulent activities. In these
                    circumstances highly restrictive access rights apply to
                    selected customer safety and security specialists analyzing
                    the chat content.
                  </p>
                  <p>
                    <strong>2.3.2.</strong>To resolve disputes, prevent and
                    detect fraud on our Services, we make use of algorithms that
                    recognize suspicious or fraudulent user behavior based on
                    certain information such as user activity and posted
                    content, which may lead to us banning certain user accounts.
                    Automated banning happens when there is a very high degree
                    of certainty that an account or action is fraudulent.
                    Otherwise human review takes place by selecting customer
                    safety and security specialists on the basis of highly
                    restrictive access rights. We use this form of automated
                    decision-making on the basis of our legitimate interest to
                    detect and prevent fraud and to keep our Services safe and
                    secure for our users. If you think that your account was
                    wrongfully banned, you can contact our Customer Support
                    team, in which case our team will review the decision to ban
                    your account.
                  </p>
                  <p>
                    <strong>2.3.4.</strong> We collect certain information from
                    and in relation to the electronic device from which you are
                    accessing our Services on the basis of our legitimate
                    interest in preventing fraud on our Services. The
                    information we collect includes your user ID (depending on
                    whether you are logged in), country domain, IP address,
                    device language settings, device brand and type, device
                    operating system and version, browser type and version, and
                    device specific software information such as fonts, system
                    and browser Time zone, available video and audio formats.
                    The device related information is used to determine whether
                    the same device is being used when users interact with our
                    Services. We associate such information with a user fraud
                    score on the basis of which we may ban certain users. If you
                    think that your account was wrongfully banned, you can
                    contact us through our customer support helpline in which
                    case our team will review the decision to ban your account
                  </p>
                </div>

                <div>
                  <p>
                    2.4. To take your feedback, promote and offer you Services
                    that may be of your interest
                  </p>
                  <p>
                    2.4.1. We may contact you through your registered mobile
                    number or email id in order to take feedback from you about
                    our Services
                  </p>
                  <p>
                    2.4.2. We use your email address and mobile number (by SMS)
                    to make suggestions and recommendations to you about our
                    Services that may be of interest to you.
                  </p>
                  <p>
                    2.4.3. We use clickstream data to monitor and report the
                    effectiveness of the campaign delivery to our business
                    partners and internal business analysis.
                  </p>
                  <p>
                    2.4.4. If you choose to provide us with your location data,
                    we may use your location data to measure and monitor your
                    interaction with the third-party advertisement banners we
                    place on our Services.
                  </p>
                  <ul>
                    We process the above information based on our legitimate
                    interest in undertaking marketing activities to offer you
                    Services that may be of your interest. Specifically, you may
                    receive certain marketing communications from us or our
                    business partners:
                    <li>
                      By any preferred means of communication if you have
                      requested such information from us.
                    </li>
                    <li>
                      By email or phone, regarding similar products and
                      services, if you already use our Services or acquired some
                      of our products.
                    </li>
                    <li>
                      By phone or email, if you provided us with your details
                      when you entered a competition
                    </li>
                    <li>
                      By phone or email if you registered for a promotion.
                    </li>
                    <li>
                      By phone or email, if you have provided your feedback for
                      our Services through our Platform, social media, or any
                      other means.
                    </li>
                  </ul>
                </div>

                <div>
                  <p>
                    Being a registered user on our Platform, please note that if
                    you have registered yourself on DND/DNC/NCPR services, you
                    will still receive the above communications.
                  </p>
                  <p>
                    You can ask us to stop sending you such marketing
                    communication at any time by clicking on the opt-out link in
                    the email sent to you or by changing your notification
                    settings in your account or by stating our calling agent
                    that you do not wish to be contacted for the above marketing
                    communications.
                  </p>
                  <p>
                    You agree to receive an email atleast once a year informing
                    you that in case of non-compliance with the Information
                    Technology (Intermediaries Guidelines) Rules, 2011, these
                    terms, policies, Benift.in has the right to terminate access
                    or usage rights of users immediately or remove non-compliant
                    information, or both.
                  </p>
                  <p>
                    3. How will we inform you about changes in our privacy
                    statement?
                  </p>
                  <p>
                    General. We may update, upgrade, modify (partially &/or
                    fully) this policy at any time, with updates taking effect
                    when you next post or after 30 days, whichever is sooner. If
                    we or our corporate affiliates are involved in a merger or
                    acquisition, we may share personal information with another
                    company, but this policy will continue to apply. Send
                    questions about this policy to support@Benift.in You will
                    also receive an e-mail once a year notifying you of any
                    change to this Policy.
                  </p>
                  <p>4. Communication</p>
                  <p>
                    We will communicate with you by email, SMS or in the app
                    notification in connection with our Services/Platform to
                    confirm your registration, to inform you in case your ad
                    listing has become live/expired and other transactional
                    messages in relation to our Services. As it is imperative
                    for us to provide you such transactional messages you may
                    not be able to opt -out of such messages.
                  </p>
                  <p>5. Who do we share your data with?</p>
                  <p>
                    We may have to share your personal data with the parties set
                    out below for the purposes set out in section 2 above.
                  </p>
                </div>

                <div>
                  <p>
                    5.1. Corporate affiliates: We may share your data with our
                    group companies, which are located within as well as outside
                    India and help us in providing business operation services
                    such as product enhancements, customer support and fraud
                    detection mechanism who help detect or prevent potentially
                    illegal acts and provide joint services (Our corporate
                    affiliates will market only to users who ask them to)
                  </p>
                  <p>
                    5.2. Third Party Service Providers: We use third party
                    service providers to help us deliver certain aspect of our
                    Services for example, cloud storage facilities. We conduct
                    checks on our third-party service providers and require them
                    to respect the security of your personal data and to treat
                    it in accordance with the law. We do not allow them to use
                    your personal data for their own purposes and only permit
                    them to process your personal data for specified purposes
                    and in accordance with our instructions.{" "}
                  </p>
                  <p>
                    5.3. Advertising and analytics providers: In order to
                    improve our Services, we will sometimes share your
                    non-identifiable information with analytics providers that
                    help us analyze how people are using our Platform/Service.
                    We, share your information with them in non-identifiable
                    form for monitoring and reporting the effectiveness of the
                    campaign delivery to our business partners and for internal
                    business analysis.
                  </p>
                  <p>
                    5.5. Law enforcement authorities, regulators and others: We
                    may disclose your personal data to law enforcement
                    authorities, regulators, governmental or public bodies and
                    other relevant third parties comply with any legal or
                    regulatory requirements. We also reserve the right to make
                    use of the personal information in any investigation or
                    judicial process relating to fraud on account of such
                    transactions during the period Benift.in retains such
                    information. We may also disclose personal information to
                    enforce our policies, respond to claims that a posting or
                    other content violates other’s rights, or protects anyone’s
                    rights, property or safety.
                  </p>
                  <p>
                    We may choose to sell, transfer, or merge parts of our
                    business or our assets. Alternatively, we may seek to
                    acquire other businesses or merge with them. If a change
                    happens to our business, then the new owners may use your
                    personal data in the same way as set out in this privacy
                    statement.
                  </p>
                  <p>
                    5.7. Access, Modification, and Deletion - You can see,
                    modify or erase your personal information by reviewing your
                    posting or account status page. Contact customer support at
                    support@Benift.in to review any personal information we
                    store that is not available on Benift.in. There may be a
                    charge associated with such requests but these will not
                    exceed the amounts permitted by law.
                  </p>
                  <p>6. Where do we store your data and for how long?</p>
                  <p>
                    The data we collect about you will be stored and processed
                    in secure servers in order to provide the best possible user
                    experience. For example – for fast website or mobile
                    application build up.
                  </p>
                  <p>
                    We will only retain your personal data for as long as
                    necessary to fulfil the purposes we collected it for,
                    including for the purposes of satisfying any legal,
                    accounting, or reporting requirements. We delete personal
                    information when we no longer need it for the purposes we
                    described earlier. We retain personal information as
                    permitted by law to resolve disputes, enforce our policies;
                    and prevent bad guys from coming back.
                  </p>
                  <p>
                    To determine the appropriate retention period for personal
                    data, we consider the amount, nature, and sensitivity of the
                    personal data, the potential risk of harm from unauthorized
                    use or disclosure of your personal data, the purposes for
                    which we process your personal data and whether we can
                    achieve those purposes through other means, and the
                    applicable legal requirements.
                  </p>
                  <p>
                    In accordance with the Information Technology (Intermediary
                    Guidelines and Digital Media Ethics Code) Rules, 2021, we
                    shall retain your information for a period of 180 days or a
                    longer period if required by the court or authorised
                    government agencies after withdrawal or cancellation of your
                    registration.
                  </p>
                  <p>
                    7. Technical and organizational measures and processing
                    security
                  </p>
                  <p>
                    All the information we receive about you are stored on
                    secure servers and we have implemented technical and
                    organizational measures that are suitable and necessary to
                    protect your personal data (encryption, passwords, physical
                    security)), in accordance with the Information Technology
                    (Reasonable Security Practices and Procedures and Sensitive
                    Personal Information Rules), 2011
                  </p>
                  <p>
                    Benift.in continually evaluates the security of its network
                    and adequacy of its internal information security program,
                    which is designed to (a) help secure your data against
                    accidental or unlawful loss, access or disclosure, (b)
                    identify reasonably foreseeable risks to the security of the
                    network, and (c) minimize security risks, including through
                    risk assessment and regular testing. In addition, we ensure
                    that all payment data are encrypted using SSL technology.
                  </p>
                  <p>
                    Unfortunately, no data transmission over the internet can be
                    guaranteed to be completely secure. So while we strive to
                    protect such information, we cannot ensure or warrant the
                    security of any information you transmit to us and you do so
                    at your own risk. Once any personal information comes into
                    our possession, we will take reasonable steps to protect
                    that information from misuse and loss and from unauthorised
                    access, modification or disclosure
                  </p>
                  <p>8. Links to third-party websites</p>
                  <p>
                    Our Platform may contain links to third party websites or
                    apps. If you click on one of these links, please note that
                    each one will have its own privacy policy. We do not control
                    these websites/apps and are not responsible for those
                    policies. When you leave our Platform, we encourage you to
                    read the privacy notice of every website you visit.
                  </p>
                  <p>
                    If you have any queries relating to the processing/ usage of
                    information provided by you or Benift.in's Privacy Policy,
                    you may email at mail id or write to us at support@Benift.in
                    For Users residing in any other part of world:
                  </p>
                  <p>
                   <strong> Work Office 811, 10th A Main, Indiranagar, Bangalore –
                    560038.</strong>
                    <br />
                   <strong> Contact Numbers : 7090106106 / 7090109109</strong>
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) }
      </div>
      
    </div>
  );
};

export default Home;