import React from "react";
import "./paymentPage.css";
import qrcode from "../../profile/ProfileAccounts/AccountsComponents/Coins/qrcode.jpg";
import { BsArrowLeft } from "react-icons/bs";
import AXIOS from "../../../Services/axios";

function PaymentPage({
  type,
  setPayment,
  setFormData,
  transactionId,
  handlesubmit,
  setHideFileInput,
}) {
  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className="paymentPageContainer">
      <button
        className="payment-previousBtn"
        onClick={() => {
          setPayment(false);
          setHideFileInput(true);
        }}
      >
        Go to Previous
      </button>

      <div className="registration-transactionId-alert">
        Account will be activated after Profile verification & Payment Details.
      </div>
      <img className="registration-qrcodeImage" src={qrcode} alt="" />

      <div className="registration-UPI">UPI : 7090109109@barodampay</div>

      <div className="registration-offerDetails">
        <p>
          Registration requires a payment of{" "}
          <span class="registration-amount">
            {type === "individual" ? `₹3540` : `₹5900`}
          </span>
          .
        </p>
        <p style={{ marginTop: "-10px" }}>(Including GST)</p>
        <p>
          Includes <span class="offer-points">1000</span> Points offer for
          Platform activity
        </p>
      </div>

      <div className="registration-transactionInput">
        <label className="registration-transactionLabel">
          Transaction ID ( UPI Payment Id )
        </label>
        <input
          className="registration-transactionInputField"
          name="transactionId"
          type="text"
          placeholder="Enter Transaction ID"
          onChange={handleInput}
          value={transactionId}
          required
        />
        <button
          className="registration-TransactionButton"
          type="submit"
          onClick={handlesubmit}
        >
          Submit
        </button>
      </div>
    </div>
  );
}

export default PaymentPage;
