import React from "react";
import "./documents.css";
import AXIOS from "../../../Services/axios";
import { useUserContext } from "../../../Services/contextSevices/indexedDbContext";

const Documents = ({registrations}) => {
  const { storedUser } = useUserContext();



  const handleDownload = (fileUrl,filename)=>{
    const anchor = document.createElement("a");
    anchor.href = fileUrl;
    anchor.download = filename || "downloaded-file";
    anchor.click();
  }
 
  return (
    <div className="documents">
        <h2 className="documentTitle">Your Documents</h2>
      <div className="imageCommon">
        <div className="img-content">
        <div className="imagesCredential">
            <h6>Aadhaar Card</h6>
            <button
              className="download-button"
              onClick={() => handleDownload(registrations && registrations[0].aadharCard)}
            >
              Download
            </button>
          </div>

          {storedUser.userType === "business" && <div className="imagesCredential">
            <h6>GSTN Certificate</h6>
            <button
              className="download-button"
              onClick={() => handleDownload(registrations[0].gstnCertificate)}
            >
              Download
            </button>
          </div>}

          
        </div>

        <div className="img-content">
          
          <div className="imagesCredential">
            <h6>Business Card</h6>
            <button
              className="download-button"
              onClick={() => handleDownload(registrations[0].businessCard)}
            >
              Download
            </button>
          </div>

          { storedUser.userType === "business" &&  <div className="imagesCredential">
            <h6>Business Catalog</h6>
            <button
              className="download-button"
               onClick={() => handleDownload(registrations[0].businessCatalog)}
            >
              Download
            </button>
          </div>}
        </div>
      </div>
    </div>
  );
};

export default Documents;
