import React, { useEffect, useState } from "react";
import { BiRupee } from "react-icons/bi";
import "./intersetedRequirementList.css";
import { useUserContext } from "../../../Services/contextSevices/indexedDbContext";
import AXIOS from "../../../Services/axios";
import { formatDate } from "../../../Services/validation";
import loadingGif from "../../images/loading.gif";

function InterestRequirementList() {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const { storedUser } = useUserContext();
  const [userRequirements, setUserRequirements] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRequirements = async () => {
      try {
        const response = await AXIOS.get(
          `/interest-shown/requirement/${storedUser.userId}`
        );
        if (response.status === 200) {
          const data = response.data;
          if (data) {
            setUserRequirements(data);
            setLoading(false);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchRequirements();
  }, [storedUser.userId]);

  const handleHoverIn = (index) => {
    setHoveredIndex(index);
  };

  const handleHoverOut = () => {
    setHoveredIndex(null);
  };

  if (loading) {
    return (
      <div>
        <div className="loading-container">
          <img src={loadingGif} alt="" />
          <p>Loading....</p>
        </div>
      </div>
    );
  }

  return (
    <div className="interestedList">
      {userRequirements.length > 0 && (
        <div className="postActionTitle">INTEREST SHOWN REQUIREMENT</div>
      )}
      <div className="interestedListBox">
        <div className="interestedListContainer">
          {userRequirements.length === 0 ? (
            <div className="noRequirementsMessage">
              <div>NO INTEREST SHOWN REQUIREMENTS FOUND.</div>
              <p className="encouragementText">
                Discover potential business partners and suppliers by showing
                interest in requirements. Start now, and we'll assist you in
                finding the perfect match for your needs!
              </p>
            </div>
          ) : (
            userRequirements.map((requirement, index) => (
              <div
                className={`interestedListCard ${
                  hoveredIndex === index ? "hovering" : ""
                }`}
                // onMouseLeave={handleHoverOut}
                key={requirement._id}
              >
                <div className="cardInnerContent">
                  {hoveredIndex !== index && (
                    <div>
                      <div className="interestedListHeader">
                        <div className="interestedListTitle">
                          Requirement {index + 1}
                        </div>
                        <span className="interestedListId ">
                          {requirement.prId}
                        </span>
                      </div>
                      <div className="interestedListSection">
                        <div className="interestedListInfo">
                          <span className="interestedListLabel">Industry</span>
                          <span className="interestedListValue">
                            : {requirement.businessIndustry}
                          </span>
                        </div>
                        <div className="interestedListInfo">
                          <span className="interestedListLabel">Category</span>
                          <span className="interestedListValue">
                            : {requirement.businessCategory}
                          </span>
                        </div>

                        <div className="interestedListInfo">
                          <span className="interestedListLabel">Type</span>
                          <span className="interestedListValue">
                            : {requirement.businessType}
                          </span>
                        </div>
                        <div className="interestedListInfo">
                          <span className="interestedListLabel">
                            Required At{" "}
                          </span>
                          <span className="interestedListValue ">
                            : {requirement.city}
                          </span>
                        </div>
                      </div>
                      <div className="custominterestedListValueSection">
                        <div className="custominterestedListInfo">
                          <span className="custominterestedListLabel">
                            Business Value
                          </span>
                          <span className="custominterestedListValue totalValue">
                            <BiRupee /> {requirement.totalValue}
                          </span>
                        </div>
                        <div className="custominterestedListInfo">
                          <span className="custominterestedListLabel">
                            Status
                          </span>
                          {requirement.interestShown
                            .filter(
                              (interestShown) =>
                                interestShown.actionId === storedUser.userId
                            )
                            .map((interestShown, index) => (
                              <span className="custominterestedListValue status">
                                {interestShown.action}
                              </span>
                            ))}
                        </div>
                        <div className="custominterestedListInfo">
                          <span className="custominterestedListLabel">
                            Fullfill By
                          </span>
                          <span className="custominterestedListValue ">
                            {formatDate(
                              new Date(requirement.expire.split("T")[0])
                            )}
                          </span>
                        </div>

                        <span className="interestedListExpireId ">
                          <span>Valid For</span> : {requirement.daysRemaining}{" "}
                          Days
                        </span>
                      </div>
                      <p
                        className="interestAccessLine"
                        onClick={() => handleHoverIn(index)}
                      >
                        Click Here for Description.
                      </p>
                    </div>
                  )}

                  {hoveredIndex === index && (
                    <div className="backside">
                      <div className="interestedDescriptionContainer">
                        <span className="interestedDescriptionLabel">
                          Description
                        </span>
                        <span className="interestedDescriptionText">
                          {requirement.comment}
                        </span>
                      </div>
                      {requirement.interestShown
                        .filter(
                          (interestShown) =>
                            interestShown.actionId === storedUser.userId
                        )
                        .map((interestShown, index) => (
                          <div
                            className="interestedStatusContainer"
                            key={index + 1}
                          >
                            <div className="interestedStatusLabel">
                              Reason for {interestShown.action}
                            </div>
                            <div className="custominterestedReason">
                              {interestShown.reason
                                ? interestShown.reason
                                : "We will be in touch with you shortly."}
                            </div>
                          </div>
                        ))}
                        <p
                          className="ListaccessLine"
                          onClick={handleHoverOut}
                        >
                         Back
                        </p>
                    </div>
                  )}
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}

export default InterestRequirementList;
