import React, { useState } from "react";
import "./closedRequirementList.css";

function ClosedRequirementList() {
  const [userRequirements, setUserRequirements] = useState([]);
  return (
    <div className="closedRequirements">
      <div className="noRequirementsMessage">
        <div>THIS SECTION IS CURRENTLY NOT BEING UPDATED</div>
        <p className="encouragementText">
          This section is currently not being updated, as we have temporarily
          suspended any changes or revisions to its content to maintain its
          stability and consistency.
        </p>
      </div>
    </div>
  );
}

export default ClosedRequirementList;
