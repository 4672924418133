import React from "react";

function PersonalInfo({ registrations }) {
  return (
    <div className="business-profile-content">
      <h3 className="business-profile-content-title">Your Personal Details</h3>
      <div className="business-profile-details">
        {registrations.map((registration, index) => (
          <div key={index} className="busines-registration-details">
            <div className="business-person-info">
              <p className="businessI-person-label">User ID</p>
              <p className="businessI-person-type">: {registration.userId}</p>
            </div>
            <div className="business-person-info">
              <p className="businessI-person-label">Phone No</p>
              <p className="businessI-person-type">: {registration.phoneNo}</p>
            </div>
            <div className="business-person-info">
              <p className="businessI-person-label">Alternate No</p>
              <p className="businessI-person-type">: {registration.phoneNo2}</p>
            </div>
            <div className="business-person-info">
              <p className="businessI-person-label">Email</p>
              <p className="businessI-person-type">: {registration.email}</p>
            </div>
            <div className="business-person-info">
              <p className="businessI-person-label">PAN No</p>
              <p className="businessI-person-type">: {registration.panNo.toUpperCase()}</p>
            </div>
            <div className="business-person-info">
              <p className="businessI-person-label">Pincode</p>
              <p className="businessI-person-type">: {registration.pincode}</p>
            </div>
            <div className="business-person-info">
              <p className="businessI-person-label">Area</p>
              <p className="businessI-person-type">: {registration.area}</p>
            </div>
            <div className="business-person-info">
              <p className="businessI-person-label">City</p>
              <p className="businessI-person-type">: {registration.city}</p>
            </div>
            <div className="business-person-info">
              <p className="businessI-person-label">State</p>
              <p className="businessI-person-type">: {registration.state}</p>
            </div>
            <div className="business-person-info">
              <p className="businessI-person-label">Country</p>
              <p className="businessI-person-type">: {registration.country}</p>
            </div>
            <div className="business-person-info">
              <p className="businessI-person-label">Address</p>
              <p className="businessI-person-type">: {registration.address}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PersonalInfo;
