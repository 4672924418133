import React, { useState } from "react";
import "./businessInfo.css";

function BusinessInfo({ registrations }) {
  return (
    <div className="business-profile-content">
      <h2 className="business-profile-content-title">Your Business Details</h2>

      <div className="business-profile-details">
        {registrations.map((registration, index) => (
          <div key={index} className="busines-registration-details">
            <div className="business-person-info">
              <p className="businessI-person-label">GSTN</p>
              <p className="businessI-person-type">: {registration.gstn.toUpperCase()}</p>
            </div>

            <div className="business-person-info">
              <p className="businessI-person-label">Business Name</p>
              <p className="businessI-person-type">
                : {registration.businessName}
              </p>
            </div>

            <div className="business-person-info">
              <p className="businessI-person-label">Business Website</p>
              <p className="businessI-person-type">
                : {registration.businessWebsite}
              </p>
            </div>
            <div className="business-person-info">
              <p className="businessI-person-label">Business Area</p>
              <p className="businessI-person-type">: {registration.area}</p>
            </div>
            <div className="business-person-info">
              <p className="businessI-person-label">Business State</p>
              <p className="businessI-person-type">
                : {registration.businessState}
              </p>
            </div>

            <div className="business-person-info">
              <p className="businessI-person-label">Business Country</p>
              <p className="businessI-person-type">
                : {registration.businessCountry}
              </p>
            </div>

            <div className="business-person-info">
              <p className="businessI-person-label">Business Pincode</p>
              <p className="businessI-person-type">
                : {registration.businessPincode}
              </p>
            </div>

            <div className="business-person-info">
              <p className="businessI-person-label">Business City</p>
              <p className="businessI-person-type">
                : {registration.businessCity}
              </p>
            </div>
            <div className="business-person-info">
              <p className="businessI-person-label">Bulding Adress</p>
              <p className="businessI-person-type">: {registration.building}</p>
            </div>
            <div className="business-person-info">
              <p className="businessI-person-label">Primary Industry</p>
              <p className="businessI-person-type">
                : {registration.businessIndustry1}
              </p>
            </div>

            <div className="business-person-info">
              <p className="businessI-person-label">Primary Category</p>
              <p className="businessI-person-type">
                : {registration.businessCategory1}
              </p>
            </div>

            <div className="business-person-info">
              <p className="businessI-person-label">Primary Type</p>
              <p className="businessI-person-type">
                : {registration.businessType1}
              </p>
            </div>

            <div className="business-person-info">
              <p className="businessI-person-label">Secondary Industry</p>
              <p className="businessI-person-type">
                : {registration.businessIndustry2?registration.businessIndustry2:"NOT PROVIDED"}
              </p>
            </div>

            <div className="business-person-info">
              <p className="businessI-person-label">Secondary Category</p>
              <p className="businessI-person-type">
                : {registration.businessCategory3?registration.businessCategory3: "NOT PROVIDED"}
              </p>
            </div>

            <div className="business-person-info">
              <p className="businessI-person-label">Secondary Type</p>
              <p className="businessI-person-type">
                : {registration.businessType2?registration.businessType2:"NOT PROVIDED"}
              </p>
            </div>

            <div className="business-person-info">
              <p className="businessI-person-label">Tertiary Industry</p>
              <p className="businessI-person-type">
                : {registration.businessIndustry2?registration.businessIndustry2:"NOT PROVIDED"}
              </p>
            </div>

            <div className="business-person-info">
              <p className="businessI-person-label">Tertiary Category</p>
              <p className="businessI-person-type">
                : {registration.businessCategory3?registration.businessCategory3:"NOT PROVIDED"}
              </p>
            </div>

            <div className="business-person-info">
              <p className="businessI-person-label">Tertiary Type</p>
              <p className="businessI-person-type">
                : {registration.businessType3?registration.businessType3:"NOT PROVIDED"}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default BusinessInfo;
