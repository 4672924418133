import React, { useEffect, useState } from "react";
import "./profile.css";
import loadingGif from "../../components/images/loading.gif";
import AXIOS from "../../Services/axios";
import ProfileSidebar from "../../components/profile/profileSidebar/profileSidebar";
import PersonalInfo from "../../components/profile/personalInfo/personalInfo";
import BusinessInfo from "../../components/profile/businessInfo/businessInfo";
import UpdateProfile from "../../components/profile/updateProfile/updateProfile";
import ProfileAccounts from "../../components/profile/ProfileAccounts/profileAccounts";
import Documents from "../../components/profile/documents/documents";
import { useUserContext } from "../../Services/contextSevices/indexedDbContext";
import Instafund from "../../components/profile/Instafund/instafund";
import ClubBenefit from "../../components/profile/clubBenefit/clubBenefit";

const Profile = () => {
  const [profileName, setProfileName] = useState("personalDetails");
  const [registrations, setRegistrations] = useState([]);
  const [loading, setLoading] = useState(true);

  const { storedUser } = useUserContext();
  

  useEffect(() => {
    // Fetch data from the appropriate API URL based` on userType
    if (storedUser) {
      let apiUrl = "";

      if (storedUser.userType === "individual") {
        apiUrl = `/individual/registrations/${storedUser.userId}`;
      } else if (storedUser.userType === "business") {
        apiUrl = `/business/registrations/${storedUser.userId}`
      }

      AXIOS.get(apiUrl)
        .then((response) => {
          
          setRegistrations(response.data);
          setLoading(false); // Data fetched, loading is complete
        })
        .catch((error) => {
          setLoading(false); // Error occurred, loading is complete
        });
    }
  }, [storedUser]);

  const handleProfileName = (name) => {
    setProfileName(name);
  };

  

  if (loading) {
    return (
      <div>
        <div className="loading-container">
          <img src={loadingGif} alt="" />
          <p>Loading....</p>
        </div>
      </div>
    );
  }

  return (
    <div>
      {/* <HeadBar /> */}
      <div className="profile-container">
        <section className="profile-section">
          <div className="containerprofile-container">
            <div className="profile-box">

              {/* profile sidebar */}
              <ProfileSidebar
                userType={storedUser.userType}
                handleProfileName={handleProfileName}
                registrations={registrations}
              />

              {/* profile details */}
              { profileName === "personalDetails" && (
                <PersonalInfo registrations={registrations}  />
              )}

              {/* Bussinessdetails */}
              {profileName === "businessInfo" && (
                <BusinessInfo registrations={registrations}/>
              )}

              {/* Accounts */}
              {profileName ==="accounts" && (
                <ProfileAccounts />
              )}

              {/* Credintial */}
              {profileName === "Credential" && (
                <Documents registrations={registrations}/>
              )}

              {/* update Profile */}
              {profileName === "updateDetails" && (
                <UpdateProfile userType={storedUser.userType} registrations={registrations}/>
              )}
              {/* Instafund */}
              {profileName === "instafund" && (
                <Instafund/>
              )}
              {profileName === "clubBenifit" && (
                <ClubBenefit/>
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Profile;
