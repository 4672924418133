import axios from "./axios";

export const fetchCategoryData = async () => {
  try {
    const response = await axios.get("/business/category");
    const categoryData = response.data;
    
    // Create a map to organize categories by industry
    const categoriesByIndustry = {};
    
    // Iterate through the categoryData and group categories by industry
    categoryData.forEach((categoryItem) => {
      const { 'Business Industry': industry, 'Business Category': category } = categoryItem;
      
      if (!categoriesByIndustry[industry]) {
        categoriesByIndustry[industry] = [];
      }
      
      categoriesByIndustry[industry].push(category);
    });
    
    return categoriesByIndustry;
    
  } catch (error) {
    console.error(error);
    throw error;
  }
};
