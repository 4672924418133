import React, { createContext, useContext, useState, useEffect } from "react";
import Dexie from "dexie";

const UserContext = createContext();

export function useUserContext() {
  return useContext(UserContext);
}

export function UserProvider({ children }) {
  const [storedUser, setStoredUser] = useState(null);

  useEffect(() => {
    const db = new Dexie("myDatabase");

    db.version(1).stores({
      userData: "id,userId,userType",
    });

    db.userData
      .get(1)
      .then((userFromIndexedDB) => {
        if (userFromIndexedDB) {
          setStoredUser(userFromIndexedDB);
        }
      })
      .catch((error) => {
        console.error("Error fetching user data from IndexedDB:", error);
      });
  }, []);

  const contextValue = {
    storedUser,
    setStoredUser,
  };

  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  );
}
