import React, { useState } from "react";
import "./points.css";
import { FaCoins } from "react-icons/fa";
import { BiSolidCoinStack } from "react-icons/bi";
import { BiRupee } from "react-icons/bi";
import { usePointsData } from "../../../../../Services/contextSevices/pointsGlobalState";
import { IoClose } from "react-icons/io5";
import AXIOS from "../../../../../Services/axios";
import { useUserContext } from "../../../../../Services/contextSevices/indexedDbContext";
import qrcode from "./qrcode.jpg"

function Points({ pointsSection }) {
  const { pointsData } = usePointsData();
  const [showPointsTransaction, setShowPointsTransaction] = useState(true);
  const [showBadgeTable, setShowBadgeTable] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);
  const [idData, setIdData] = useState("");
  const { storedUser } = useUserContext();
  const { userId } = storedUser;

  const handleBadgeLevelsClick = () => {
    setShowPointsTransaction(false);
    setShowBadgeTable(true);
    setShowQRCode(false);
  };
  const handleBadgeTableClose = () => {
    setShowPointsTransaction(true);
    setShowBadgeTable(false);
    setShowQRCode(false);
  };
  const handleAddCoinsClick = () => {
    setShowQRCode(true);
    setShowPointsTransaction(false);
    setShowBadgeTable(false);
  };
  const handleQRCodeClose = () => {
    setShowQRCode(false);
    setShowPointsTransaction(true);
    setShowBadgeTable(false);
  };

  const handleInput = (e) => {
    const { value } = e.target;
    setIdData(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response =await AXIOS.post("/transactionId", {
        userId: userId,
        transactionId: idData,
      });
      if ((await response).status === 200) {
        setShowQRCode(false);
        setShowPointsTransaction(true);
        setShowBadgeTable(false);
      }
    } catch (error) {
      alert("An error occurred during submission. Please try again later.");
    }
  };

  return (
    <div>
      {!pointsSection && (
        <div className="coinsSections">
          <div className="coinsDetails">
            <div className="coinsHeading">YOUR POINTS</div>
            <div className="coinsCount">
              <span>{pointsData.pointsBalance}</span>{" "}
              <BiSolidCoinStack style={{ fill: "gold" }} />
              <FaCoins className="coinsIcon" />
            </div>
          </div>
        </div>
      )}
      {pointsSection && (
        <div className="pointsDetails">
          <div className="pointsBalanceContainer ">
            <div>
              <div className="points-Container">
                <div className="pointsBalance">
                  <p>Total Balance</p>
                  <span className={`${pointsData.pointsBalance < 50 ? "lessPoints" : ""}`}>{pointsData.pointsBalance}</span>
                </div>
                <div className="pointsCountSections">
                  <div className="pointsCountLine">
                    Your Total Actions via Rupees
                  </div>
                  <div className="pointsCounts">
                    <span>
                      <BiRupee />
                      {pointsData.totalActionAmount}
                    </span>
                    <p>Profit Awaits</p>
                  </div>
                </div>
              </div>
              <div className="userBadgeSections">
                <div className="userBadge">
                  <p className="userBadgeLabel">Current Badge</p>
                  <p className="userBadgeName">{pointsData.currentBadge}</p>
                </div>
                {showBadgeTable ? null : (
                  <div
                    className="viewBadgeLevels"
                    onClick={handleBadgeLevelsClick}
                  >
                    <p>Badge Levels</p>
                  </div>
                )}
              </div>
            </div>

            {!showQRCode &&<button className="pointsButton" onClick={handleAddCoinsClick}>
              Add Coins
            </button>}
          </div>
          {showPointsTransaction && (
            <div className="pointsTransaction">
              <div className="businessPost">
                <h6 className="sectionTitle">TOTAL BUSINESS POSTED</h6>
                <div className="infoContainer">
                  <div className="infoItem">
                    <p className="infoLabel">Postings Count</p>

                    <p className="infoValue">{pointsData.postingCount}</p>
                  </div>
                  <div className="infoItem">
                    <p className="infoLabel">Points Spent On Postings</p>

                    <p className="infoValue">
                      {pointsData.pointsSpentOnPostings}
                    </p>
                  </div>
                  <div className="infoItem">
                    <p className="infoLabel">Total Postings Value </p>

                    <p className="infoValue">
                      <BiRupee />
                      {pointsData.totalPostingValue}
                    </p>
                  </div>
                </div>
              </div>
              <div className="interestedShown">
                <h6 className="sectionTitle">TOTAL INTEREST SHOWN</h6>
                <div className="infoContainer">
                  <div className="infoItem">
                    <p className="infoLabel">Interest Shown</p>

                    <p className="infoValue">{pointsData.interestShownCount}</p>
                  </div>
                  <div className="infoItem">
                    <p className="infoLabel">
                      Points Spent On Showing Interest
                    </p>

                    <p className="infoValue">
                      {pointsData.pointsSpentOnShowingInterest}
                    </p>
                  </div>
                  <div className="infoItem">
                    <p className="infoLabel">Total Shown-Interests Value</p>

                    <p className="infoValue">
                      <BiRupee />
                      {pointsData.totalInterestShownValue}
                    </p>
                  </div>
                </div>
              </div>
              <div className="businessCompleted">
                <h6 className="sectionTitle">TOTAL BUSINESS COMPLETED</h6>
                <div className="infoContainer">
                  <div className="infoItem">
                    <p className="infoLabel">Business Closed</p>

                    <p className="infoValue">
                      {pointsData.businessClosedCount}
                    </p>
                  </div>
                  <div className="infoItem">
                    <p className="infoLabel">Total Business Closed Value</p>

                    <p className="infoValue">
                      <BiRupee />
                      {pointsData.totalClosedbusinessValue}
                    </p>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          )}
          {showBadgeTable && (
            <div className="BadgeTable">
              <IoClose
                className="closeIcon-Badgetable"
                onClick={handleBadgeTableClose}
              />
              <h4 className="Badge-Title">Badge Levels</h4>
              <table className="BadgeMy-table custom-table">
                <thead>
                  <tr>
                    <td className="badge-header">Badge Names</td>
                    <td className="badge-header">Target Values</td>
                  </tr>
                </thead>
                <tbody>
                  <tr className="custom-row">
                    <td className="badge-column">Bronze Badge</td>
                    <td className="badge-column">
                      <BiRupee />
                      50,000
                    </td>
                  </tr>
                  <tr className="custom-row">
                    <td className="badge-column">Silver Badge </td>
                    <td className="badge-column">
                      {" "}
                      <BiRupee />
                      200,000
                    </td>
                  </tr>
                  <tr className="custom-row">
                    <td className="badge-column">Gold Badge </td>
                    <td className="badge-column">
                      <BiRupee />
                      10,00,000
                    </td>
                  </tr>
                  <tr className="custom-row">
                    <td className="badge-column">Platinum Badge </td>
                    <td className="badge-column">
                      <BiRupee />
                      25,00,000
                    </td>
                  </tr>
                  <tr className="custom-row">
                    <td className="badge-column">Diamond Badge </td>
                    <td className="badge-column">
                      <BiRupee />
                      50,00,000
                    </td>
                  </tr>
                  <tr className="custom-row">
                    <td className="badge-column">Elite Badge </td>
                    <td className="badge-column">
                      <BiRupee />1 Crore
                    </td>
                  </tr>
                  <tr className="custom-row">
                    <td className="badge-column">Supreme Badge </td>
                    <td className="badge-column">
                      <BiRupee /> 5 Crore
                    </td>
                  </tr>
                  <tr className="custom-row">
                    <td className="badge-column">Apex Badge </td>
                    <td className="badge-column">
                      <BiRupee /> 10 Crore
                    </td>
                  </tr>
                  <tr className="custom-row">
                    <td className="badge-column">Legend Badge </td>
                    <td className="badge-column">
                      <BiRupee />
                      20 Crore
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {showQRCode && (
            <div className="qrcodeSection">
              <div className="qrcode-div">
                <IoClose
                  className="closeIcon-qrcode"
                  onClick={handleQRCodeClose}
                />
                <div className="qrcode-info">
                  <div className="qrimage-content">
                    <div className="QrcodeImage">
                      <img
                        src={qrcode}
                        alt=""
                      />
                    </div>
                    <div className="qrcode-upid_id">
                      <label className="qrcodeLable">UPI : 7090109109@barodampay</label>
                    </div>
                  </div>
                </div>
              </div>
              <form className="qrcode-details" onSubmit={handleSubmit}>
                <div className="qrcode">
                  <div className="qrcode-transaction_id">
                    <label className="transaction-qrcodeLable">
                      Transaction ID :
                    </label>
                    <input
                      type="text"
                      className="transaction_id"
                      placeholder="Your Transaction ID"
                      name="transactionId"
                      onChange={handleInput}
                      value={idData}
                    />
                  </div>

                  <div className="qurcode-message">
                    <span className="qurcode-message-span">
                      Coins will be added once you submit Transaction ID. Don't
                      forget to submit; the coin will be added shortly.
                    </span>
                  </div>
                </div>
                <div className="qrcodeButton">
                  <button type="submit" className="qrcode-submit-button">
                    Submit
                  </button>
                </div>
                
              </form>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Points;
