import React, { useEffect, useRef, useState } from "react";
import "./individualRegistration.css";
import IndividualRegistrationCaptcha from "./individualRegistrationCaptcha";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import {
  emailValidation,
  pancardValidation,
  pincodeValidation,
} from "../../../Services/validation";
import AXIOS from "../../../Services/axios";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import io from "socket.io-client";
import TermsAndCondition from "../../../pages/termsAndCondition/termsAndCondition";
import { IoClose } from "react-icons/io5";
import loadingGif from "../../images/loading.gif";
import _ from "lodash";
import PaymentPage from "../paymentPage/paymentPage";

const IndividualRegistration = ({ handleBackButton }) => {
  const [formData, setFormData] = useState({
    title: "",
    personName: "",
    businessName: "",
    professionalField: "",
    professionalType: "",
    phoneNo: "",
    phoneNo2: "",
    email: "",
    password: "",
    confirmPassword: "",
    address: "",
    area: "",
    city: "",
    pincode: "",
    state: "",
    country: "",
    panNo: "",
    otp: "",
    terms: false,
    transactionId: "",
  });
  const [referredId, setReferredId] = useState("");
  const [validReferredId, setValidReferredId] = useState(false);
  const [selectedArea, setSelectedArea] = useState([]);
  const [file, setFile] = useState({
    profileImg: null,
    aadharCard: null,
    businessCard: null,
  });
  const [registrationCount, setRegistrationCount] = useState(0);
  const [pincode, setPincode] = useState({
    personal: false,
  });
  const navigate = useNavigate();
  const [captcha, setCaptcha] = useState({
    isVerified: false,
    userCapcha: "",
  });
  const [showError, setShowError] = useState({
    panNo: false,
    email: false,
    pincode: false,
    password: false,
    confirmPassword: false,
    phoneNo2: false,
    userID: false,
  });
  const [verifyOtp, setVerifyOtp] = useState({
    isOtpSent: false,
    waitTime: false,
    isOtpVerify: false,
    totalSeconds: 180,
    resendClicked: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  const [welcome, setWelcome] = useState(false);
  const termsPopUpContainerRef = useRef();
  const [socketEmitCount, setSocketEmitCount] = useState(0);
  const [payment, setPayment] = useState(false);
  const [hideFileInput, setHideFileInput] = useState(false);

  useEffect(() => {
    if (verifyOtp.waitTime) {
      const countdown = setInterval(() => {
        setVerifyOtp((prevVerifyOtp) => ({
          ...prevVerifyOtp,
          resendClicked: false,
          totalSeconds: prevVerifyOtp.totalSeconds - 1,
        }));
      }, 1000);

      return () => clearInterval(countdown);
    }
  }, [verifyOtp.waitTime]);

  useEffect(() => {
    if (verifyOtp.totalSeconds <= 0 && !verifyOtp.resendClicked) {
      handleResendClick();
    }
  }, [verifyOtp.totalSeconds, verifyOtp.resendClicked]);

  const handleResendClick = () => {
    setVerifyOtp((prevVerifyOtp) => ({
      ...prevVerifyOtp,
      resendClicked: true,
    }));
  };

  // Convert seconds to minutes and seconds
  const minutes = Math.floor(verifyOtp.totalSeconds / 60);
  const seconds = verifyOtp.totalSeconds % 60;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await AXIOS.get(`/referredId/${referredId}`);
        if (response.status === 200) {
          setValidReferredId(true);
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setValidReferredId(false);
        }
      }
    };

    if (referredId.length > 4) {
      fetchData();
    }

    if (referredId.length < 4) {
      setValidReferredId(false);
    }
  }, [referredId]);

  const handleInputChange = (event) => {
    const { name, value, type, checked, files } = event.target;

    // Update form data based on input changes
    if (name === "phoneNo") {
      const numericValue = value.replace(/\D/g, "");
      setFormData((prevFormData) => ({
        ...prevFormData,
        phoneNo: numericValue,
      }));
    } else if (name === "phoneNo2") {
      const numericValue = value.replace(/\D/g, "");
      setFormData((prevFormData) => ({
        ...prevFormData,
        phoneNo2: numericValue,
      }));
    } else if (name === "password") {
      const numericValue = value.replace(/\D/g, "");
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: numericValue,
      }));
    } else if (name === "referredId") {
      setReferredId(value);
    } else if (type === "file") {
      setFile((prevFileArray) => ({
        ...prevFileArray,
        [name]: files[0],
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }

    if (type === "checkbox") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: checked,
      }));
    }

    // Check PAN card validation and update showError
    if (name === "panNo" && type !== "file") {
      const uppercasePAN = value.toUpperCase();
      const isValidPAN = pancardValidation(value);
      setShowError((prevErrors) => ({
        ...prevErrors,
        panNo: !isValidPAN,
      }));
      setFormData((prevFormData) => ({
        ...prevFormData,
        panNo: uppercasePAN,
      }));
    }

    if (name === "phoneNo2" && type !== "file") {
      if (value && value === formData.phoneNo) {
        setShowError((prevErrors) => ({
          ...prevErrors,
          phoneNo2: true,
        }));
      } else {
        setShowError((prevErrors) => ({
          ...prevErrors,
          phoneNo2: false,
        }));
      }
    }

    if (name === "email" && type !== "file") {
      const isValidEmail = emailValidation(value);
      setShowError((prevErrors) => ({
        ...prevErrors,
        email: !isValidEmail,
      }));
    }

    if (name === "password" && type !== "file") {
      if (value.length < 6) {
        setShowError((prevErrors) => ({
          ...prevErrors,
          password: true,
        }));
      }
      if (value.length === 6) {
        setShowError((prevErrors) => ({
          ...prevErrors,
          password: false,
        }));
      }
    }

    if (name === "confirmPassword" && type !== "file") {
      if (value && value !== formData.password) {
        setShowError((prevErrors) => ({
          ...prevErrors,
          confirmPassword: true,
        }));
      } else {
        setShowError((prevErrors) => ({
          ...prevErrors,
          confirmPassword: false,
        }));
      }
    }
    if (name === "pincode" && type !== "file") {
      const numericValue = value.replace(/[^0-9]/g, "");
      const isValidPincode = pincodeValidation(numericValue);
      setShowError((prevErrors) => ({
        ...prevErrors,
        pincode: !isValidPincode,
      }));
    }
  };

  const fetchData = async () => {
    try {
      const response = await AXIOS.get(`/excel/pincode/${formData.pincode}`);
      if (response.data && response.data !== "NOT FOUND") {
        const { city, state, country, areas } = response.data;
        setPincode((prev) => ({
          ...prev,
          personal: true,
        }));
        setFormData((prevFormData) => ({
          ...prevFormData,
          state: state,
          country: country,
          city: city,
          area: "",
        }));
        setSelectedArea(areas);
      } else if (response.data && response.data === "NOT FOUND") {
        setPincode((prev) => ({
          ...prev,
          personal: false,
        }));
        setFormData((prevFormData) => ({
          ...prevFormData,
          state: "",
          country: "",
          city: "",
          area: "",
        }));
        setSelectedArea([]);
      }
    } catch (error) {
      alert("Internal Server Error");
    }
  };

  useEffect(() => {
    if (formData.pincode.length === 6) {
      fetchData();
    }
  }, [formData.pincode]);

  useEffect(() => {
    if (
      formData.password &&
      formData.confirmPassword &&
      formData.confirmPassword !== formData.password
    ) {
      setShowError((prevErrors) => ({
        ...prevErrors,
        confirmPassword: true,
      }));
    } else {
      setShowError((prevErrors) => ({
        ...prevErrors,
        confirmPassword: false,
      }));
    }
  }, [formData.password]);

  useEffect(() => {
    if (formData.phoneNo && formData.phoneNo2 === formData.phoneNo) {
      setShowError((prevErrors) => ({
        ...prevErrors,
        phoneNo2: true,
      }));
    } else {
      setShowError((prevErrors) => ({
        ...prevErrors,
        phoneNo2: false,
      }));
    }
  }, [formData.phoneNo]);

  const generateUserID = (city, count) => {
    const cityPrefix = city.substring(0, 3).toUpperCase();
    return `I${cityPrefix}${registrationCount}`;
  };

  const handleOTP = async () => {
    if (formData.email === "") {
      alert("Please fill the email");
      return;
    }

    if (showError.email) {
      alert("Please Enter Correct Email");
      return;
    }

    if (verifyOtp.waitTime) {
      setVerifyOtp({ ...verifyOtp, waitTime: false });
    }

    const data = { userType: "individual", email: formData.email };

    try {
      setVerifyOtp({ ...verifyOtp, waitTime: true });
      const response = await AXIOS.post("/registrations/verify/email", data);
      if (response.status === 200) {
        alert("Please Check the OTP in your Email.");
        setVerifyOtp({ ...verifyOtp, waitTime: false, isOtpSent: true });
      }
    } catch (error) {
      setVerifyOtp({ ...verifyOtp, waitTime: false });
      if (error.response) {
        if (error.response.status === 404) {
          window.alert("Email already exists");
        } else {
          alert("Internal server error.Please try later");
        }
      }
    }
  };

  const verifyOTP = async () => {
    if (formData.email === "") {
      alert("Please fill the email");
      return;
    }

    if (showError.email) {
      alert("Please Enter Correct Email");
      return;
    }

    const data = {
      userType: "individual",
      email: formData.email,
      otp: formData.otp,
    };

    try {
      const response = await AXIOS.post("/registrations/verify/otp", data, {
        withCredentials: true,
      });
      if (response.status === 200) {
        setVerifyOtp({ ...verifyOtp, isOtpVerify: true });
      }
    } catch (error) {
      if (error.response) {
        setVerifyOtp({ ...verifyOtp, isOtpVerify: false });
        if (error.response.status === 404) {
          window.alert("OTP Expired");
        } else if (error.response.status === 401) {
          window.alert("Invalid OTP");
          setVerifyOtp({ ...verifyOtp, isOtpSent: true });
        }
      }
    }
  };

  const handleVerification = (item) => {
    setCaptcha({ ...captcha, isVerified: item });
  };

  const handleUserCaptch = (item) => {
    setCaptcha({ ...captcha, userCapcha: item });
  };
  //   event.preventDefault();
  //   if (captcha.userCapcha === "" || !captcha.isVerified) {
  //     alert("ENTER THE CORRECT CAPTCHA");
  //     return;
  //   }

  //   if (!verifyOtp.isOtpVerify) {
  //     alert("Please Verify Email");
  //     return;
  //   }

  //   if (
  //     formData.title === "" ||
  //     formData.personName === "" ||
  //     formData.professionalField === "" ||
  //     formData.professionalType === "" ||
  //     formData.phoneNo === "" ||
  //     formData.email === "" ||
  //     formData.password === "" ||
  //     formData.confirmPassword === "" ||
  //     formData.address === "" ||
  //     formData.area === "" ||
  //     formData.city === "" ||
  //     formData.pincode === "" ||
  //     formData.state === "" ||
  //     formData.country === "" ||
  //     formData.panNo === ""
  //   ) {
  //     alert("Please fill in all required fields.");
  //     return;
  //   }

  //   if (!formData.terms) {
  //     alert("Please accept the terms and conditions.");
  //     return;
  //   }

  //   if (file.profileImg === null) {
  //     alert("Please Upload Your Profile Image");
  //     return;
  //   }

  //   if (file.aadharCard === null) {
  //     alert("Please Upload Your Aadhar Card Image");
  //     return;
  //   }

  //   const { panNo, email, password, confirmPassword, phoneNo2 } = showError;
  //   if (formData.phoneNo.length < 10) {
  //     alert("Please fill in Phone Number correctly.");
  //     return;
  //   } else if (phoneNo2) {
  //     alert("Please fill in Alternate Number correctly.");
  //     return;
  //   } else if (email) {
  //     alert("Please fill in Email correctly.");
  //     return;
  //   } else if (password) {
  //     alert("Please fill in Password correctly.");
  //     return;
  //   } else if (confirmPassword) {
  //     alert("Please fill in Confirm Password correctly.");
  //     return;
  //   } else if (showError.pincode || !pincode.personal) {
  //     alert("Please fill in Valid Pincode.");
  //     return;
  //   } else if (panNo) {
  //     alert("Please fill in Pan Card correctly.");
  //     return;
  //   } else if (referredId.length > 0 && !validReferredId) {
  //     alert("Please fill in Valid Referred User ID.");
  //     return;
  //   } else {
  //     const userId = generateUserID(formData.city, registrationCount);
  //     const formDataToSend = new FormData();
  //     formDataToSend.append("userId", userId);

  //     for (const [key, value] of Object.entries(formData)) {
  //       if (typeof value === "string") {
  //         formDataToSend.append(key, value.toUpperCase());
  //       } else {
  //         formDataToSend.append(key, value); // Handle the case where the value is not a string
  //       }
  //     }

  //     for (const key in file) {
  //       if (file[key]) {
  //         formDataToSend.append(key, file[key]);
  //       }
  //     }

  //     try {
  //       const response = await AXIOS.post(
  //         "/individual/register",
  //         formDataToSend,
  //         {
  //           headers: {
  //             "Content-Type": "multipart/form-data",
  //           },
  //         }
  //       );

  //       if (response.status === 200) {
  //         setLoading(true);
  //         const socket = io(process.env.REACT_APP_BACKEND_BASE_URL);
  //         socket.emit("newRegistration", {
  //           type: "individual",
  //           city: formData.city,
  //         });
  //         setShowError({ ...showError, userID: true });
  //         const pointsDataResponse = await AXIOS.post("/individual/points", {
  //           userId,
  //         });

  //         const referredIdResponse = await AXIOS.post(`/referredId/${userId}`);

  //         if (validReferredId) {
  //           const updatReferredIdResponse = await AXIOS.put(
  //             `/referredId/${referredId}`,
  //             {
  //               newId: userId,
  //             }
  //           );
  //         }

  //         const transactionResponse = await AXIOS.post(
  //           "/individual/transactions",
  //           {
  //             userId,
  //           }
  //         );

  //         const subscriptionResponse = await AXIOS.post(
  //           `/userSubscription/${userId}`
  //         );

  //         setWelcome(true);

  //         setTimeout(() => {
  //           navigate("/login");
  //         }, 5000);

  //         const contactResponse = await AXIOS.post("/message/email", {
  //           email: formData.email,
  //           Id: userId,
  //           requirementType: "INDIVIDUAL",
  //         });
  //       }
  //     } catch (error) {
  //       setLoading(false);
  //       if (error.response) {
  //         if (error.response.status === 402) {
  //           alert("Only JPG, JPEG type files are allowed.");
  //         } else {
  //           alert(`${error.response.data.message}`);
  //         }
  //       } else {
  //         alert("An error occurred while sending data.");
  //       }
  //     }
  //   }
  // };

  const handleNextBtn = async (event) => {
    event.preventDefault();
    if (captcha.userCapcha === "" || !captcha.isVerified) {
      alert("ENTER THE CORRECT CAPTCHA");
      return;
    }

    if (!verifyOtp.isOtpVerify) {
      alert("Please Verify Email");
      return;
    }

    if (
      formData.title === "" ||
      formData.personName === "" ||
      formData.professionalField === "" ||
      formData.professionalType === "" ||
      formData.phoneNo === "" ||
      formData.email === "" ||
      formData.password === "" ||
      formData.confirmPassword === "" ||
      formData.address === "" ||
      formData.area === "" ||
      formData.city === "" ||
      formData.pincode === "" ||
      formData.state === "" ||
      formData.country === "" ||
      formData.panNo === ""
    ) {
      alert("Please fill in all required fields.");
      return;
    }

    if (!formData.terms) {
      alert("Please accept the terms and conditions.");
      return;
    }

    if (file.profileImg === null) {
      alert("Please Upload Your Profile Image");
      return;
    }

    if (file.aadharCard === null) {
      alert("Please Upload Your Aadhar Card Image");
      return;
    }

    const { panNo, email, password, confirmPassword, phoneNo2 } = showError;
    if (formData.phoneNo.length < 10) {
      alert("Please fill in Phone Number correctly.");
      return;
    } else if (phoneNo2) {
      alert("Please fill in Alternate Number correctly.");
      return;
    } else if (email) {
      alert("Please fill in Email correctly.");
      return;
    } else if (password) {
      alert("Please fill in Password correctly.");
      return;
    } else if (confirmPassword) {
      alert("Please fill in Confirm Password correctly.");
      return;
    } else if (showError.pincode || !pincode.personal) {
      alert("Please fill in Valid Pincode.");
      return;
    } else if (panNo) {
      alert("Please fill in Pan Card correctly.");
      return;
    } else if (referredId.length > 0 && !validReferredId) {
      alert("Please fill in Valid Referred User ID.");
      return;
    } else {
      setPayment(true);
    }
  };

  const handlesubmit = async (event) => {
    event.preventDefault();

    if (!formData.transactionId) {
      alert("Enter the correct Transaction Id");
      return;
    }

    const userId = generateUserID(formData.city, registrationCount);
    const formDataToSend = new FormData();
    formDataToSend.append("userId", userId);

    for (const [key, value] of Object.entries(formData)) {
      if (typeof value === "string") {
        formDataToSend.append(key, value.toUpperCase());
      } else {
        formDataToSend.append(key, value);
      }
    }

    for (const key in file) {
      if (file[key]) {
        formDataToSend.append(key, file[key]);
      }
    }

    try {
      setLoading(true);

      const response = await AXIOS.post(
        "/individual/register",
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        const socket = io(process.env.REACT_APP_BACKEND_BASE_URL);
        socket.emit("newRegistration", {
          type: "individual",
          city: formData.city,
        });
        setShowError({ ...showError, userID: true });
        const pointsDataResponse = await AXIOS.post("/individual/points", {
          userId,
        });

        const referredIdResponse = await AXIOS.post(`/referredId/${userId}`);

        if (validReferredId) {
          const updatReferredIdResponse = await AXIOS.put(
            `/referredId/${referredId}`,
            {
              newId: userId,
            }
          );
        }

        const transactionResponse = await AXIOS.post(
          "/individual/transactions",
          {
            userId,
          }
        );

        const subscriptionData = {
          userId,
          transactionId: formData.transactionId,
          subscriptionPlan: 3000,
        };

        const subscriptionResponse = await AXIOS.post(
          `/userSubscription`,
          subscriptionData
        );

        setWelcome(true);

        setTimeout(() => {
          navigate("/login");
        }, 5000);

        const contactResponse = await AXIOS.post("/message/email", {
          email: formData.email,
          Id: userId,
          requirementType: "INDIVIDUAL",
        });
      }
    } catch (error) {
      setLoading(false);
      setHideFileInput(true);
      setPayment(false);

      if (error.response) {
        if (error.response.status === 402) {
          alert("Only JPG, JPEG type files are allowed.");
        } else {
          alert(`${error.response.data.message}`);
        }
      } else {
        alert("An error occurred while sending data.");
      }
    }
  };

  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setShowPassword(!showPassword);
    } else if (field === "confirmPassword") {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  const handleButtonClick = (field) => {
    togglePasswordVisibility(field);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        termsPopUpContainerRef.current &&
        !termsPopUpContainerRef.current.contains(event.target)
      ) {
        // Clicked outside the termsPopUpContainer, so close the popup
        setShowTerms(false);
      }
    };

    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const emitCityDataDebounced = _.debounce(
    (city) => {
      if (socketEmitCount < 1) {
        const socket = io(process.env.REACT_APP_BACKEND_BASE_URL);
        socket.emit("cityData", { city, type: "individual" });
        setSocketEmitCount((prevCount) => prevCount + 1);
      } else {
        // Reset the count after emitting 3 times
        setSocketEmitCount(0);
      }
    },
    500 // Adjust the debounce delay as needed
  );

  useEffect(() => {
    const socket = io(process.env.REACT_APP_BACKEND_BASE_URL);
    if (formData.city && formData.pincode.length === 6) {
      emitCityDataDebounced(formData.city);
      socket.on("liveRegistrationCount", ({ city, count }) => {
        if (city === formData.city) {
          setRegistrationCount(count);
        } else if (city !== formData.city) {
          emitCityDataDebounced(formData.city);
        }
      });
    }

    return () => {
      // Clean up the event listener
      socket.off("liveRegistrationCount");
      // Disconnect the socket when the component unmounts
      socket.disconnect();
    };
  }, [
    formData.title,
    formData.personName,
    formData.businessName,
    formData.professionalField,
    formData.professionalType,
    formData.phoneNo,
    formData.phoneNo2,
    formData.email,
    formData.password,
    formData.confirmPassword,
    formData.address,
    formData.area,
    formData.city,
    formData.state,
    formData.country,
    formData.panNo,
    formData.otp,
    formData.terms,
    registrationCount,
    captcha,
  ]);

  

  if (!loading && !payment) {
    return (
      <div className="containerindival w-60 ">
        <BsArrowLeft onClick={handleBackButton} className="backRegistration" />
        <h1 className="textCenter">PROFESSIONAL REGISTARTION</h1>

        {showTerms && (
          <div className="termsPopUpContainer" ref={termsPopUpContainerRef}>
            <div className="termsIoClose">
              <IoClose onClick={() => setShowTerms(false)} />
            </div>
            <TermsAndCondition className="termsPopUpContent" />
          </div>
        )}

        {!showTerms && (
          <form
            className=" mt-0"
            // onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <div className="mb-3">
              <label htmlFor="title" className="form-label sudo-styles">
                Title
              </label>
              <select
                name="title"
                required
                className="form-control"
                value={formData.title}
                onChange={handleInputChange}
              >
                <option value="" selected disabled>
                  Select Your Title
                </option>
                <option value="Mr.">Mr.</option>
                <option value="Mrs.">Mrs.</option>
                <option value="Ms.">Ms.</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="personName" className="form-label sudo-styles">
                Person Name
              </label>
              <input
                type="text"
                className="form-control"
                id="personName"
                name="personName"
                value={formData.personName}
                onChange={handleInputChange}
                required
                placeholder="Your Name"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="personName" className="form-label">
                Business Name (Optional)
              </label>
              <input
                type="text"
                className="form-control"
                id="businessName"
                name="businessName"
                value={formData.businessName}
                onChange={handleInputChange}
                placeholder="Your Bussiness Name"
              />
            </div>

            <div className=" ProfessionalField">
              <div className="mb-4">
                <label className="form-label sudo-styles">Profession </label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    id="professionalField"
                    name="professionalField"
                    value={formData.professionalField}
                    onChange={handleInputChange}
                    required
                    placeholder=" Your Profession Field"
                    style={{ width: "255px" }}
                  />
                </div>
              </div>
              <div className="  ProfessionalType mb-4">
                <label className="form-label sudo-styles">Specialization</label>
                <div className="input-group">
                  <input
                    type="text"
                    placeholder=" Enter Specialization"
                    className="form-control"
                    id="professionalType"
                    name="professionalType"
                    value={formData.professionalType}
                    onChange={handleInputChange}
                    style={{ width: "255px" }}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="individualPhoneNumber">
              <div className="mb-3">
                <label
                  htmlFor="phoneNo"
                  maxLength={10}
                  className="form-label sudo-styles"
                >
                  Phone No
                </label>
                <div className="input-group">
                  <span className="input-group-text">+91</span>
                  <input
                    type="tel"
                    maxLength="10"
                    className="form-control"
                    id="phoneNo"
                    name="phoneNo"
                    value={formData.phoneNo}
                    onChange={handleInputChange}
                    required
                    placeholder="Your Phone No"
                  />
                </div>
                {formData.phoneNo.length != 0 &&
                  formData.phoneNo.length < 10 && (
                    <div className="errorLine">
                      PHONE NUMBER SHOULD HAVE 10 DIGITS
                    </div>
                  )}
              </div>
              <div className=" individualPhoneNumber2 mb-3">
                <label htmlFor="phoneNo2" maxLength={10} className="form-label">
                  Alternate Phone No
                </label>
                <div className="input-group">
                  <span className="input-group-text">+91</span>
                  <input
                    type="tel"
                    placeholder="Your Alternate Phone No"
                    maxLength="10"
                    className={`form-control ${
                      showError.phoneNo2 ? "is-invalid" : ""
                    }`}
                    id="phoneNo2"
                    name="phoneNo2"
                    value={formData.phoneNo2}
                    onChange={handleInputChange}
                  />
                </div>
                {showError.phoneNo2 && (
                  <div className="errorLine">
                    MOBILE NUMBERS SHOULD NOT BE SAME
                  </div>
                )}
              </div>
            </div>

            <div className="verification-business mb-3">
              <label htmlFor="email" className="form-label sudo-styles">
                E-mail
              </label>
              <div className="verification">
                <input
                  type="email"
                  className={`form-control ${
                    showError.email ? "is-invalid" : ""
                  }`}
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  placeholder="Your Email"
                  {...(verifyOtp.isOtpVerify ? { readOnly: true } : {})}
                />

                <div className="showVerification">
                  <div className="verification-details">
                    {!verifyOtp.isOtpVerify && (
                      <input
                        type="text"
                        className="hidden-input emailVerifyInput"
                        id="verification-input"
                        placeholder="Enter Verification Code"
                        name="otp"
                        onChange={handleInputChange}
                        value={formData.otp.replace(/\D/g, "")}
                        maxLength={6}
                      />
                    )}

                    {verifyOtp.waitTime && !verifyOtp.resendClicked && (
                      <span className="verification-span">
                        {minutes < 10 ? `Wait for 0${minutes}` : minutes}:
                        {seconds < 10 ? `0${seconds}` : seconds}
                      </span>
                    )}
                    {verifyOtp.waitTime && verifyOtp.resendClicked && (
                      <span className="verification-span">
                        We apologize.Please click on resend
                      </span>
                    )}
                    {!verifyOtp.waitTime &&
                      verifyOtp.isOtpSent &&
                      !verifyOtp.isOtpVerify && (
                        <span className="verification-span">
                          Enter Correct OTP
                        </span>
                      )}
                  </div>
                  <div>
                    {!verifyOtp.isOtpSent && !verifyOtp.isOtpVerify && (
                      <button
                        type="button"
                        className="resend-button emailBtn"
                        onClick={handleOTP}
                      >
                        Send
                      </button>
                    )}
                    {verifyOtp.isOtpSent && !verifyOtp.isOtpVerify && (
                      <button
                        type="button"
                        className="resend-button emailBtn"
                        onClick={verifyOTP}
                      >
                        Verify
                      </button>
                    )}
                    {verifyOtp.waitTime && verifyOtp.resendClicked && (
                      <button
                        type="button"
                        className="resend-button emailBtn"
                        onClick={handleOTP}
                      >
                        Resend
                      </button>
                    )}
                    {verifyOtp.isOtpVerify && (
                      <button
                        type="button"
                        className="resend-button verifiedButton"
                      >
                        Verified Successfully
                      </button>
                    )}
                  </div>
                </div>
              </div>

              {showError.email && (
                <div className="errorLine">ENTER CORRECT EMAIL ID</div>
              )}
            </div>

            <div className="individualPasswordGroup">
              <div className="mb-4">
                <label className="form-label sudo-styles">Password</label>
                <div className="input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    required
                    placeholder="Enter PassWord"
                    style={{ width: "255px" }}
                    maxLength={6}
                  />

                  <button
                    type="button"
                    className={`passwordToggle ${
                      showPassword ? "visible" : ""
                    }`}
                    onClick={() => handleButtonClick("password")}
                  >
                    <span className="passwordIcon">
                      {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                    </span>
                  </button>
                </div>
                {showError.password && (
                  <div className="alertPasswordLine">
                    SIX-DIGITS NUMERIC PASSWORD.
                  </div>
                )}
              </div>
              <div className="ProfessionalType mb-4">
                <label className="form-label sudo-styles">
                  Confirm Password
                </label>
                <div className="input-group">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    className={`form-control ${
                      showError.confirmPassword ? "is-invalid" : ""
                    }`}
                    id="confirmPassword"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                    required
                    placeholder="Confirm Password"
                    style={{ width: "255px" }}
                    maxLength={6}
                  />
                  <button
                    type="button"
                    className={`passwordToggle ${
                      showConfirmPassword ? "visible" : ""
                    }`}
                    onClick={() => handleButtonClick("confirmPassword")}
                  >
                    <span className="passwordIcon">
                      {showConfirmPassword ? (
                        <AiFillEyeInvisible />
                      ) : (
                        <AiFillEye />
                      )}
                    </span>
                  </button>
                </div>
                {showError.confirmPassword && (
                  <div className="errorLine">
                    PASSWORDS MUST MATCH EACH OTHER
                  </div>
                )}
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="address" className="form-label sudo-styles">
                Address
              </label>
              <input
                type="text"
                className="form-control"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                required
                placeholder="Your Address"
              />
            </div>

            <div className="individualPincode">
              <div className="mb-4">
                <label htmlFor="pincode" className="form-label sudo-styles">
                  Pincode
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    showError.pincode ? "is-invalid" : ""
                  }`}
                  id="pincode"
                  name="pincode"
                  value={formData.pincode}
                  onChange={handleInputChange}
                  onTouchEnd={handleInputChange}
                  placeholder="Enter a Pincode"
                  required
                  maxLength="6"
                  autoComplete="off"
                />
                {showError.pincode && (
                  <div className="errorLine">ENTER CORRECT PINCODE</div>
                )}
                {formData.pincode &&
                  !showError.pincode &&
                  !pincode.personal && (
                    <div className="errorLine">ENTER VALID PINCODE</div>
                  )}
              </div>

              {formData.pincode.length === 6 && pincode.personal && (
                <div className="individualArea mb-4">
                  <label htmlFor="area" className="form-label sudo-styles">
                    Area:
                  </label>
                  <select
                    className="form-select"
                    id="area"
                    name="area"
                    value={formData.area} // Bind selected area to formData.area
                    onChange={handleInputChange} // Handle changes to the area
                    required
                  >
                    <option value="">Select an Area</option>
                    {selectedArea.map(
                      (
                        area,
                        index // Map over selectedArea array
                      ) => (
                        <option key={index} value={area}>
                          {area}
                        </option>
                      )
                    )}
                  </select>
                </div>
              )}
            </div>

            {formData.pincode.length === 6 && pincode.personal && (
              <div className=" individualAreaInfo">
                <div className="mb-4">
                  <label className="form-label sudo-styles">City </label>
                  <div className="input-group">
                    <input
                      className="form-control "
                      id="city"
                      name="city"
                      value={formData.city}
                      readOnly
                      onChange={handleInputChange}
                      placeholder="Enter your City"
                    />
                  </div>
                </div>
                <div className=" mb-4">
                  <label className="form-label sudo-styles">State</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      id="state"
                      name="state"
                      value={formData.state}
                      onChange={handleInputChange}
                      required
                      placeholder="Enter your State"
                    />
                  </div>
                </div>
                <div className=" mb-4">
                  <label className="form-label sudo-styles">Country</label>
                  <div className="input-group">
                    <input
                      className="form-control "
                      id="country"
                      name="country"
                      value={formData.country}
                      onChange={handleInputChange}
                      readOnly
                      required
                      placeholder="Enter your Country"
                    />
                  </div>
                </div>
              </div>
            )}

            <div className=" IndividualPanNo col-md-3">
              <label htmlFor="panNo" className="form-label sudo-styles">
                PAN No
              </label>
              <input
                type="text"
                className={`form-control ${
                  showError.panNo ? "is-invalid" : ""
                }`}
                id="panNo"
                name="panNo"
                value={formData.panNo.toUpperCase()}
                onChange={handleInputChange}
                placeholder="Enter Your PAN No"
                required
              />
              {showError.panNo && (
                <div className="errorLine">ENTER CORRECT PAN CARD</div>
              )}
            </div>

            {formData.pincode.length > 5 &&
              formData.city &&
              !showError.userID &&
              pincode.personal && (
                <div className="userId-info mb-3">
                  <label className="form-label sudo-styles userId-label">
                    {" "}
                    UserID{" "}
                  </label>
                  <div div className="userId-input-container">
                    <input
                      type="text"
                      className="form-control userId-input"
                      id="userID"
                      name="userID"
                      value={generateUserID(formData.city, registrationCount)}
                      readOnly // Make the input read-only
                      required
                      placeholder="UserId"
                    />
                    <p className="userId-message">
                      Please remember this UserID for login purposes.
                    </p>
                  </div>
                </div>
              )}

            <div className="mb-3 referringSection">
              <label htmlFor="address" className="form-label">
                Referred By (Optional)
              </label>
              <input
                type="text"
                className={`form-control ${
                  referredId.length === 5 && !validReferredId
                    ? "is-invalid"
                    : ""
                }`}
                name="referredId"
                value={referredId.toUpperCase()}
                onChange={handleInputChange}
                placeholder="Enter the ID of the referred user."
              />
              {referredId.length > 0 && referredId.length < 5 && (
                <p className="errorLine">Enter correct User ID </p>
              )}
              {referredId.length >= 5 && !validReferredId && (
                <p className="errorLine">
                  Referred User ID is not found; Enter valid User ID{" "}
                </p>
              )}
              {referredId.length > 4 && validReferredId && (
                <p className="alertPasswordLine">Referred User ID is found</p>
              )}
            </div>

            <div className="TermsAndConditionsCheckBox">
              <input
                type="checkbox"
                id="terms"
                required
                name="terms"
                checked={formData.terms}
                onChange={handleInputChange}
              />
              <label for="terms">
                I have read and accepted the
                <span onClick={() => setShowTerms(true)}>
                  Terms and Conditions
                </span>
              </label>
            </div>

            {!hideFileInput && (
              <div className=" mb-3">
                <label
                  htmlFor="address"
                  className=" referenceimg form-label sudo-styles"
                >
                  Upload Your Profile Image
                </label>
                <input
                  type="file"
                  className="form-control"
                  id="profileAddress"
                  name="profileImg"
                  onChange={handleInputChange}
                  required
                  accept=".jpg, .jpeg"
                />
              </div>
            )}

            {!hideFileInput && (
              <div className=" mb-3">
                <label
                  htmlFor="address"
                  className=" referenceimg form-label sudo-styles"
                >
                  Upload Your Aadhar Card
                </label>
                <input
                  type="file"
                  className="form-control"
                  id="aadharAddress"
                  name="aadharCard"
                  onChange={handleInputChange}
                  required
                  accept=".jpg, .jpeg"
                />
              </div>
            )}

            {!hideFileInput && (
              <div className=" mb-3">
                <label htmlFor="address" className=" referenceimg form-label ">
                  Upload Your Business Card (Optional)
                </label>
                <input
                  type="file"
                  className="form-control"
                  id="businessCardAddress"
                  name="businessCard"
                  onChange={handleInputChange}
                  accept=".jpg, .jpeg"
                />
              </div>
            )}

            <IndividualRegistrationCaptcha
              handleVerification={handleVerification}
              handleUserCaptch={handleUserCaptch}
              userCapcha={captcha.userCapcha}
            />

            <br />
            <button
              type="submit"
              className="individualbutton"
              onClick={handleNextBtn}
            >
              Next
            </button>
          </form>
        )}
      </div>
    );
  }

  if (!loading && payment) {
    return (
      <PaymentPage
        type="individual"
        setPayment={setPayment}
        setFormData={setFormData}
        transactionId={formData.transactionId}
        handlesubmit={handlesubmit}
        setHideFileInput = {setHideFileInput}
      />
    );
  }

  if (loading) {
    return (
      <div>
        <div
          className="loading-container"
          style={{ paddingLeft: "10px", paddingRight: "10px" }}
        >
          {!welcome && <img src={loadingGif} alt="" />}
          {!welcome && <p>Wait For A While</p>}
          {welcome && (
            <p style={{ marginLeft: "0", marginBottom: "0" }}>
              Welcome to Benift
            </p>
          )}
          <br />
          {welcome && (
            <p
              style={{
                textAlign: "justify",
                marginLeft: "0",
                marginBottom: "0",
                color: "#4e797c",
              }}
            >
              Your <span style={{ color: "#ff8000" }}>USER ID</span> has been
              sent to your registered Email Id
            </p>
          )}
          <br />
          {welcome && (
            <p style={{ marginLeft: "0", marginBottom: "0" }}>
              Your Account will be Activated Soon
            </p>
          )}
        </div>
      </div>
    );
  }
};

export default IndividualRegistration;
