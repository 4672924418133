import React, { useEffect, useState } from "react";
import "./referAndEarn.css";
import AXIOS from "../../../../../Services/axios";
import { useUserContext } from "../../../../../Services/contextSevices/indexedDbContext";

function ReferAndEarn({ referAndEarnSection }) {
  const [data, setData] = useState("");
  const [cycleCount, setCycleCount] = useState("");
  const { storedUser } = useUserContext();
  const { userId } = storedUser;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await AXIOS.get(`/referredId/${userId}`);
        setData(response.data);
      } catch (error) {
        console.error("Error fetching referredId data:", error);
      }
    };

    fetchData();
  }, [userId]);

  function subtractFromLastDigit(number) {
    if (number >= 0) {
      const lastDigit = number % 10;

      if (lastDigit < 5) {
        return 5 - lastDigit;
      } else {
        return 10 - lastDigit;
      }
    } else {
      return "Input must be a non-negative number";
    }
  }

  subtractFromLastDigit();

  return (
    <div>
      {!referAndEarnSection && (
        <div className="referAndEarnSections">
          <div className="referAndEarnDetails">
            <div className="referAndEarnHeading">Refer and Earn</div>
            <div className="referedCounts">
              <span>{data.referredCount} Applicants Refered</span>
            </div>
          </div>
        </div>
      )}
      {referAndEarnSection && (
        <div class="referAndEarnSection">
          <div class="referalStats">
            <div class="referalPoints">
              <p className="referStats">Your Referral Stats</p>
              <div class="pointsWrapper">
                <span class="pointsValue">{data.referredPoints}</span>
                <span class="pointsLabel">Points</span>
              </div>
            </div>
            <div className="referalStatsPoints">
              <div class="referalEarning">
                BRING IN <span class="earningValue">5</span> CLIENTS, EARN{" "}
                <span class="earningValue">1000</span> POINTS!
              </div>
              <div class="totalReferrals">
                <p>
                  Your Total Referrals :{" "}
                  <span className="referralsCounts">{data.referredCount}</span>
                </p>
              </div>
            </div>
          </div>

          <div class="cycleContainer">
            <div class="presentCycle">
              <div>Present Cycle</div>
              <p>
                You've completed{" "}
                <span class="cycleProgress">
                  {5 - subtractFromLastDigit(data.referredCount)} out of 5
                </span>{" "}
                referrals. {(5 - subtractFromLastDigit(data.referredCount))>0 && <span>Keep it up!</span>}
              </p>
              <p>
                Just{" "}
                <span class="cycleNumber">
                  {subtractFromLastDigit(data.referredCount)}
                </span>{" "}
                more to earn <span class="cyclePoints">1000</span> points
              </p>
            </div>
            <div class="pendingReferrals">
              <p>Referred IDs</p>
              <ul className="referralList">
                {data.referredID && data.referredID.length > 0 ? (
                  data.referredID.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))
                ) : (
                  <h6 class="referalEarning" >NO REFERRED IDs FOUND</h6>
                )}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ReferAndEarn;
