

import React, { useState, useEffect, useRef } from 'react';
import { FiRefreshCcw } from "react-icons/fi";
import "./individualCaptcha.css"

const IndividualRegistrationCaptcha = ({handleVerification,handleUserCaptch,userCapcha}) => {
  // State variables
  const [captcha, setCaptcha] = useState(generateCaptcha());
  const [userInput, setUserInput] = useState('');
  const [verificationStatus, setVerificationStatus] = useState('');
  const canvasRef = useRef(null);

  // Function to generate a random captcha

  function generateCaptcha() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const captchaLength = 6; // You can adjust the captcha length

    for (let i = 0; i < captchaLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }

    return result;
  }

  // Function to handle user input
  function handleUserInput(event) {
    setUserInput(event.target.value);
    handleUserCaptch(event.target.value)
  }


  // Function to refresh captcha
  function refreshCaptcha(event) {
    event.preventDefault();
    const newCaptcha = generateCaptcha();
    setCaptcha(newCaptcha);
    setUserInput('');
    setVerificationStatus('');
    drawCaptcha(newCaptcha);
    handleUserCaptch("")
  }

  useEffect(() => {
    if (userInput === "") {
      setVerificationStatus('Enter correct captcha.');
    }else if (userInput === captcha) {
      setVerificationStatus('Captcha verified!');
      handleVerification(true)
    } else {
      setVerificationStatus('Enter correct captcha.');
      handleVerification(false)
    }
  }, [userInput, captcha]);

// Function to draw captcha as an image on canvas
function drawCaptcha(text) {
  const canvas = canvasRef.current;
  const ctx = canvas.getContext('2d');
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.font = '29px Arial';
  ctx.fillStyle = 'white'; // Set the text color to white

  
  // Calculate the spacing between characters
  const charSpacing = 23;

  for (let i = 0; i < text.length; i++) {
    ctx.fillText(text[i], 10 + i * charSpacing, 30);
  }

}

  useEffect(() => {
    drawCaptcha(captcha);
  }, [captcha]);


  return (
<div className="captcha-container">
      <p className="captcha-text">Enter the following captcha:</p>
      <div className="captcha-row">
        <canvas ref={canvasRef} width={150} height={40} className="captcha-canvas"></canvas>
        <button onClick={refreshCaptcha} className="refresh-button">
          <FiRefreshCcw />
        </button>
      </div>
      <div className="captcha-input-container">
        <input
          className='captcha-input'
          type="text"
          value={userCapcha}
          onChange={handleUserInput}
          placeholder="Enter captcha here" // Add a placeholder for better user guidance
        />
      </div>
      <p className="verification-status">{verificationStatus}</p>
    </div>
  );
};

export default IndividualRegistrationCaptcha;
