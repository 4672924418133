import React, { useState } from "react";
import "../InstafundUserFrom/InstaUserInfoForm.css";
import AXIOS from "../../../../Services/axios";
import { useUserContext } from "../../../../Services/contextSevices/indexedDbContext";

const InstaUserInfoForm = ({ selectedPlan, setInstaFundForm }) => {
  const { monthlyPay, availFunds } = selectedPlan;
  const { storedUser } = useUserContext();
  const { userId } = storedUser;
  const [formData, setFormData] = useState({
    purpose: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if(!formData.purpose){
      alert ("Please complete the form correctly")
      return
    }

    try {
      const response = await AXIOS.post("/instaFund", {
        userId,
        monthlyPay,
        availFunds,
        purpose:formData.purpose
      });
      if (response.status === 200) {
        alert("Form submitted successfully");
        setInstaFundForm(false);
      } else {
        alert("An error occurred while submitting the form");
      }
    } catch (error) {
      if (error.response) {
        alert("An error occurred during submission. Please try again later.");
      }
    }
  };

  return (
    <div className="instafund-user-form">
      <h4 className="InstafundfromTitle"> INSTAFUND FORM</h4>
      <form onSubmit={handleSubmit} className="instaFundfrom-container">
        <div className="commonInstaFund">
          <div>
            <label htmlFor="monthlyPay">Monthly Pay</label>
            <input
              type="text"
              className="InputborderInstafund"
              value={monthlyPay}
              placeholder="Monthly Pay"
              readOnly
              required
            />
          </div>
          <div>
            <label htmlFor="availFunds">Avail Funds Up to/For 7 Days</label>
            <input
              type="text"
              className="InputborderInstafund"
              value={availFunds}
              placeholder="Avail Funds"
              readOnly
              required
            />
          </div>
        </div>

        <div className="instafundField">
          <div>
            <label className="purpose">Reason:</label>
            <textarea
              className="purposeTextarea"
              name="purpose"
              placeholder="Reason For Opting Instafund"
              value={formData.purpose}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>

        <div className="instafund-terms">
          <p class="acceptance-text">Accept all Terms and Conditions</p>

          <p class="instafund-description">
            Instafund is to financially support our members in difficult
            situations where they are unable to procure materials to process a
            transaction. With up to 2 transactions will be supported by us per
            year (within their membership tenure of 12 months).
          </p>

          <div class="checkbox-section">
            <div class="checkbox-item" >
              <input type="checkbox" class="checkbox-input" required />
              <p class="checkbox-description">
                The monthly subscription amount paid by members will not carry
                any interest / share or bonus. It is for the benefit of all
                members that the fund will be used (at No financial interest)
                for a short period to carry a specific transaction.
              </p>
            </div>

            <div class="checkbox-item">
              <input type="checkBox" class="checkbox-input" required/>
              <p class="checkbox-description">
                The membership process of accepting and rejecting the member
                from joining the instafund scheme will be a sole decision of
                Scope BSS and no influence or force will be entertained.{" "}
              </p>
            </div>

            <div class="checkbox-item">
              <input type="checkBox" class="checkbox-input" required/>
              <p class="checkbox-description">
                No explanation or reason will be provided verbally or in writing
                for rejection of the Instafund scheme membership or cancellation
                within the specified period.
              </p>
            </div>

            <div class="checkbox-item">
              <input type="checkBox" class="checkbox-input" required/>
              <p class="checkbox-description">
                The amount paid against the transaction is to be returned back
                within 5 days, with maximum extension of 2 days which totals to7
                days.
              </p>
            </div>

            <div class="checkbox-item">
              <input type="checkBox" class="checkbox-input" required/>
              <p class="checkbox-description">
                The amount required will be paid against the Invoice only to the
                materials supplier directly.{" "}
              </p>
            </div>

            <div class="checkbox-item">
              <input type="checkBox" class="checkbox-input" required/>
              <p class="checkbox-description">
                Amount will be paid against invoice for materials / products
                only. Any Services or Service charges will be not paid.{" "}
              </p>
            </div>

            <div class="checkbox-item">
              <input type="checkBox" class="checkbox-input" required/>
              <p class="checkbox-description">
                GST & Other government taxes / charges included in the Invoice
                will also NOT BE PAID.
              </p>
            </div>

            <div class="checkbox-item">
              <input type="checkBox" class="checkbox-input" required/>
              <p class="checkbox-description">
                Discontinuation of the process of monthly subscription payment
                will lead to cancellation of member from the Instafund process
                and will not be allowed to join back the scheme for the specific
                membership period of 12 months.
              </p>
            </div>

            <div class="checkbox-item">
              <input type="checkBox" class="checkbox-input" required/>
              <p class="checkbox-description">
                While taking the benefit of the InstaFund, the member needs to
                give the below for processing:
                <ul>
                  <li>
                    Endorsement by 5 of the existing group members with their
                    consent for approving the instafund amount. The endorsing
                    members should also be part of the instafund scheme.
                  </li>
                  <li>
                    A company Post dated Cheque mentioning the 7th day date,
                    seal and signature attached with the agreement copy to be
                    given.
                  </li>
                  <li>
                    Signatures of all the 5 endorsee members to be taken on the
                    agreement.
                  </li>
                  <li>
                    Agreement will mention the funding for the purpose of which
                    transaction and the amount paid to which party clearly.
                  </li>
                </ul>
              </p>
            </div>

            <div class="checkbox-item">
              <input type="checkBox" class="checkbox-input" required/>
              <p class="checkbox-description">
                InstaFund amount when taken against any invoice if not returned
                back on or before 7th day, the cheque given will be produced
                directly into the bank, if any case the cheque is returned
                uncleared further legal process will be taken against the
                borrower person & Company.
              </p>
            </div>

            <div class="checkbox-item">
              <input type="checkBox" class="checkbox-input" required/>
              <p class="checkbox-description">
                If failure to payback the instafund amount will also lead to
                black marking the individual & company in the members group and
                in other groups as well.
              </p>
            </div>

            <div class="checkbox-item">
              <input type="checkBox" class="checkbox-input" required/>
              <p class="checkbox-description">
                Service Charges of 5% will be applicable on all instafund
                payments plus service taxes as per the government policies.
              </p>
            </div>

            <div class="checkbox-item">
              <input type="checkBox" class="checkbox-input" required/>
              <p class="checkbox-description">
                Refund Terms and conditons :
                <ul>
                  <li>
                    Monthly subscription fund is refundable without any interest
                    / bonus, other benefits or deductions.
                  </li>
                  <li>
                    Refund of the paid subscription amount will be refunded
                    after completion of 12 months (1 Year) membership period.
                  </li>
                  <li>
                    Refund if insisted will be refunded after deducting 5%
                    service charges and along with government taxes on service
                    charges (Tax bill will be provided).
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>

        <div className="button-row">
          <button type="submit" className="uploadSubmitButton">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default InstaUserInfoForm;
