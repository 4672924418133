import React, { useEffect, useState } from "react";
import "./postRequirements.css";
import AXIOS from "../../Services/axios";
import { calculatePoints, pincodeValidation } from "../../Services/validation";
import { usePointsData } from "../../Services/contextSevices/pointsGlobalState";
import { useUserContext } from "../../Services/contextSevices/indexedDbContext";
import { fetchCategoryData } from "../../Services/businessCategoryFunctionality";
import { useNavigate } from "react-router";
import { useContactData } from "../../Services/contextSevices/contactContext";

const PostRequirement = () => {
  const navigate = useNavigate();
  const [postRequirement, setPostRequirement] = useState({
    businessCategory: "",
    businessIndustry: "",
    businessType: "",
    comment: "",
    pincode: "",
    city: "",
    expire: "",
    expireCount: "",
    totalValue: "",
  });

  const [fees, setFees] = useState("");

  const [imgFile, setImgFile] = useState({
    sampleImage1: null,
    sampleImage2: null,
  });
  const [showError, setShowError] = useState({
    pincode: false,
    businessValue: false,
    expire: false,
    fees: false,
  });
  const [imageURL, setImageURL] = useState({
    sampleImage1: "",
    sampleImage2: "",
  });
  const [category, setCategory] = useState({});
  const { storedUser } = useUserContext();
  const { pointsData, updateTrigger, setUpdateTrigger } = usePointsData();
  const {
    pointsBalance,
    pointsSpentOnPostings,
    totalPostingValue,
    postingCount,
    totalActionAmount,
  } = pointsData;
  const { contactData } = useContactData();
  const { email } = contactData;

  const [thumbnail1, setThumbnail1] = useState(null);
  const [thumbnail2, setThumbnail2] = useState(null);
  const [pincode, setPincode] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const categoryData = await fetchCategoryData();
        setCategory(categoryData);
      } catch (error) {
        alert("Internal Server Error");
      }
    }

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (name === "totalValue") {
      const numericValue = value.replace(/[^0-9]/g, "");
      setPostRequirement((prevFormData) => ({
        ...prevFormData,
        totalValue: numericValue,
      }));
      setShowError((prevErrors) => ({
        ...prevErrors,
        businessValue: parseInt(numericValue) < 5000,
      }));
    } else if (name === "pincode") {
      const numericValue = value.replace(/[^0-9]/g, "");
      setPostRequirement((prevFormData) => ({
        ...prevFormData,
        pincode: numericValue,
      }));
      const isValidPincode = pincodeValidation(numericValue);
      setShowError((prevErrors) => ({
        ...prevErrors,
        pincode: !isValidPincode,
      }));
    } else if (name === "expire") {
      const today = new Date();
      const selectedDate = new Date(value);

      if (selectedDate < today) {
        setPostRequirement((prevFormData) => ({
          ...prevFormData,
          expire: today.toISOString().split("T")[0],
          expireCount: 0,
        }));
      } else {
        const timeDifference = selectedDate.getTime() - today.getTime();
        const daysDifference = Math.ceil(
          timeDifference / (1000 * 60 * 60 * 24)
        );

        setPostRequirement((prevFormData) => ({
          ...prevFormData,
          expire: value.split("T")[0],
          expireCount: daysDifference,
        }));
      }

      setShowError((prevErrors) => ({
        ...prevErrors,
        expire: false,
      }));
    } else {
      if (type === "file") {
        // Handle file inputs
        setImgFile((prevImgFile) => ({
          ...prevImgFile,
          [name]: files[0],
        }));
        // Add code to display a thumbnail if needed
        if (files[0]) {
          const reader = new FileReader();
          reader.onload = (e) => {
            const thumbnail = e.target.result;
            if (name === "sampleImage1") {
              setThumbnail1(thumbnail);
            } else if (name === "sampleImage2") {
              setThumbnail2(thumbnail);
            }
          };
          reader.readAsDataURL(files[0]);
        }
      } else {
        setPostRequirement((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      }
    }
  };

  useEffect(() => {
    setFees(calculatePoints(postRequirement.totalValue));
  }, [postRequirement.totalValue]);

  const fetchData = async () => {
    try {
      const response = await AXIOS.get(
        `/pincode/area/${postRequirement.pincode}`
      );
      if (response.data && response.data !== "NOT FOUND") {
        setPincode(true);
        setPostRequirement((prevFormData) => ({
          ...prevFormData,
          city: response.data,
        }));
      } else if (response.data && response.data === "NOT FOUND") {
        setPincode(false);
        setPostRequirement((prevFormData) => ({
          ...prevFormData,
          city: "",
        }));
      }
    } catch (error) {
      console.log(error);
      alert("Internal Server Error");
    }
  };

  useEffect(() => {
    if (postRequirement.pincode.length === 6) {
      fetchData();
    } else if (postRequirement.pincode.length < 6) {
      setPostRequirement((prev) => ({
        ...prev,
        city: "",
      }));
    }
  }, [postRequirement.pincode]);

  const generatePRID = (userId, count) => {
    return `${userId}PR${count}`;
  };

  const generateNotificationID = (prId) => {
    const currentDate = new Date();
    const monthString = (currentDate.getMonth() + 1)
      .toString()
      .padStart(2, "0");
    const dayString = currentDate.getDate().toString().padStart(2, "0");
    const hourString = currentDate.getHours().toString().padStart(2, "0");

    // Generate a random three-digit number
    const randomNumber = Math.floor(Math.random() * 100)
      .toString()
      .padStart(2, "0");

    // Combine date and random number to create a unique ID
    const formattedDate = `${monthString}${dayString}${hourString}`;
    const notificationId = `N${prId}${formattedDate}${randomNumber}`;
    return notificationId;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    for (const key in postRequirement) {
      if (postRequirement[key] === "") {
        alert(`Please fill in all fields`);
        return;
      }
    }

    if (fees > pointsBalance) {
      alert("You Are Running Low On Points. Please Add More Points.");
      return;
    }

    if (showError.businessValue) {
      alert("Business Amount should be minimum 5000");
      return;
    }

    if (pointsBalance < 50) {
      alert("Action needs 50 points minimum in Balance");
    }

    if (!showError.businessValue) {
      try {
        const indianTimezoneOffset = 5.5 * 60 * 60 * 1000;
        const currentIndianTime = new Date(Date.now() + indianTimezoneOffset);

        const prId = generatePRID(storedUser.userId, postingCount + 1);
        const formData = new FormData();
        formData.append("userId", storedUser.userId);
        formData.append("prId", prId);
        for (const key in postRequirement) {
          formData.append(key, postRequirement[key]);
        }
        formData.append("submissionDate", currentIndianTime);
        formData.append("sampleImage1", imgFile.sampleImage1);
        formData.append("sampleImage2", imgFile.sampleImage2);

        const fees = calculatePoints(postRequirement.totalValue);

        const updatedPointsBalance = pointsBalance - fees;
        const updatedPostingValue =
          totalPostingValue + parseInt(postRequirement.totalValue, 10);
        const updatedPostingCount = postingCount + 1;
        const updatedPointsSpentOnPostings = pointsSpentOnPostings + fees;
        const updatedTotalActionAmount =
          totalActionAmount + parseInt(postRequirement.totalValue, 10);

        const updatePointsResponse = await AXIOS.put(
          storedUser.userType === "individual"
            ? `/individual/points/${storedUser.userId}`
            : `/business/points/${storedUser.userId}`,
          {
            pointsBalance: updatedPointsBalance,
            totalPostingValue: updatedPostingValue,
            postingCount: updatedPostingCount,
            pointsSpentOnPostings: updatedPointsSpentOnPostings,
            totalActionAmount: updatedTotalActionAmount,
          }
        );

        if (updatePointsResponse.status === 200) {
          const updatedHistory = {
            action: "POSTING",
            id: prId,
            value: postRequirement.totalValue,
            points: fees,
            closed: "NO",
          };

          const transactionResponse = await AXIOS.post(
            storedUser.userType === "individual"
              ? `/individual/transactions/${storedUser.userId}`
              : `/business/transactions/${storedUser.userId}`,
            { history: updatedHistory }
          );

          const response = await AXIOS.post("/requirement", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          if (response.data.success) {
            const notificationId = generateNotificationID(prId);

            const data = {
              notificationId,
              prId,
              type: "posting_requirement",
              industry: postRequirement.businessIndustry,
              subject: `Your industry has a business requirement linked to PR ID - ${prId}`,
              description: postRequirement.comment,
            };
            const notificationResponse = await AXIOS.post(
              "/notification",
              data
            );

            setPostRequirement({
              businessCategory: "",
              businessIndustry: "",
              businessType: "",
              comment: "",
              city: "",
              expire: "",
              expireCount: "",
              totalValue: "",
              submissionDate: "",
            });

            setImageURL({
              sampleImage1: null,
              sampleImage2: null,
            });

            setUpdateTrigger(!updateTrigger);
            alert("Requirements Sent Successfully!");
            navigate("/viewRequirement");

            const contactResponse = await AXIOS.post("/message/email", {
              email,
              Id: prId,
              requirementType: "POSTED",
            });
          } else {
            alert("Requirements Failed. Please try again.");
          }
        } else {
          console.error("Failed to update points balance:");
          alert("Failed to update points balance.");
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 402) {
            alert("Only image (JPG, JPEG) are allowed.");
          } else {
            alert(
              "An error occurred during submission. Please try again later."
            );
          }
        }
      }
    } else {
      alert("PLEASE FILL THE REQUIREMENT CORRECTLY");
    }
  };

  return (
    <div>
      <div className="postContainer">
        <div>
          <h1 className="textCenterPost">Post Requirement</h1>
        </div>
        <form
          className="postRequirementFrom"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <div id="prId">
            <label className="prIdLabel">PR ID</label>
            <input
              type="text"
              className="prIdValue"
              placeholder="Enter Business industry"
              value={generatePRID(storedUser.userId, postingCount + 1)}
              onChange={handleChange}
              required
              readOnly
            />
          </div>
          <div className="formGroup">
            <label className="formLabel">Business Industry</label>
            <select
              type="text"
              className="formControl"
              name="businessIndustry"
              placeholder="Enter Business industry"
              value={postRequirement.businessIndustry}
              onChange={handleChange}
              required
            >
              <option value="">Select Business Industry</option>
              {Object.keys(category).map((industry, index) => (
                <option key={index} value={industry}>
                  {industry}
                </option>
              ))}
            </select>
          </div>
          {postRequirement.businessIndustry && (
            <div className="formGroup">
              <label className="formLabel">Business Category</label>
              <select
                type="text"
                className="formControl"
                name="businessCategory"
                placeholder="Enter Business category"
                value={postRequirement.businessCategory}
                onChange={handleChange}
                required
              >
                <option value="">Select Business Category</option>
                {postRequirement.businessIndustry &&
                  category[postRequirement.businessIndustry]?.map(
                    (categoryItem, index) => (
                      <option key={index} value={categoryItem}>
                        {categoryItem}
                      </option>
                    )
                  )}
              </select>
            </div>
          )}

          {postRequirement.businessIndustry &&
            postRequirement.businessCategory && (
              <div className="formGroup">
                <label className="formLabel">Business Type</label>
                <select
                  className="formControl"
                  name="businessType"
                  value={postRequirement.businessType}
                  onChange={handleChange}
                  placeholder="Enter Business Type"
                >
                  <option value="">Select Business Type</option>
                  <option value="WE Want to Sell - WholeSale - Product">
                    WE Want to Sell - WholeSale - Product
                  </option>
                  <option value="WE Want to Sell - Retail - Product">
                    WE Want to Sell - Retail - Product
                  </option>
                  <option value="WE Want to Avail - Service">
                    WE Want to Avail - Service
                  </option>
                  <option value="WE Want to Provide - Service">
                    WE Want to Provide - Service
                  </option>
                  <option value="WE Want to Buy - Product">
                    WE Want to Buy - Product
                  </option>
                  <option value="We Want to extend Service - Consultation">
                    We Want to Extend Service - Consultation
                  </option>
                  <option value="We Want Manufacturer">
                    We Want Manufacturer
                  </option>
                  <option value="We are Manufacturer">
                    We are Manufacturer
                  </option>
                </select>
              </div>
            )}

          <div className="formGroup">
            <label className="formLabel">Requirement Description</label>
            <textarea
              className="formControl"
              rows={3}
              name="comment"
              placeholder="Enter Your Requirement Description In Detail"
              value={postRequirement.comment}
              onChange={handleChange}
              required
            />
          </div>

          {!postRequirement.city && (
            <div className="formGroup">
              <label className="formLabel">City Pincode</label>
              <input
                type="text"
                className="formControl"
                name="pincode"
                placeholder="Enter city pincode"
                value={postRequirement.pincode}
                onChange={handleChange}
                required
                maxLength={6}
              />
              {postRequirement.pincode && showError.pincode && (
                <p className="errorMessage">ENTER CORRECT PINCODE</p>
              )}
              {postRequirement.pincode && !showError.pincode && !pincode && (
                <div className="errorMessage">ENTER VALID PINCODE</div>
              )}
            </div>
          )}

          {postRequirement.pincode.length === 6 && postRequirement.city && (
            <div className="expireGroup">
              <div className="formGroup">
                <label className="formLabel">City Pincode</label>
                <input
                  type="text"
                  className="formControl"
                  name="pincode"
                  placeholder="Enter city pincode"
                  value={postRequirement.pincode}
                  onChange={handleChange}
                  required
                  maxLength={6}
                />
                {showError.pincode && (
                  <p className="errorMessage">ENTER CORRECT PINCODE</p>
                )}
                {postRequirement.pincode && !showError.pincode && !pincode && (
                  <div className="errorMessage">ENTER VALID PINCODE</div>
                )}
              </div>
              <div className="formGroup">
                <label className="formLabel">Requirement City</label>
                <input
                  type="text"
                  className="formControl"
                  name="city"
                  placeholder="Enter Requirement city"
                  value={postRequirement.city}
                  onChange={handleChange}
                  required
                  readOnly
                />
              </div>
            </div>
          )}

          <div className="expireGroup">
            <div className="formGroup">
              <label className="formLabel">Expire Date</label>
              <input
                type="date"
                className="formControl"
                name="expire"
                value={postRequirement.expire}
                onChange={handleChange}
                required
              />
            </div>
            <div className="formGroup">
              <label className="formLabel">Valid For</label>
              <input
                type="text"
                className="formControl"
                name="expireCount"
                value={
                  postRequirement.expire
                    ? `${postRequirement.expireCount} Days`
                    : ""
                }
                onChange={handleChange}
                required
                placeholder="Enter Expire Date"
                readOnly
              />
            </div>
          </div>
          <div>
            <div
              className={`formGroup ${
                showError.businessValue ? "showError" : ""
              }`}
            >
              <label className="formLabel">Business Value</label>
              <input
                type="text"
                className={`formControl ${
                  showError.businessValue ? "errorBorder" : ""
                }`}
                name="totalValue"
                placeholder="Enter Business Value"
                value={postRequirement.totalValue}
                onChange={handleChange}
                required
              />
              {showError.businessValue && (
                <p className="errorMessage">
                  Business Amount should be minimum 5000
                </p>
              )}
            </div>
            {postRequirement.totalValue && !showError.businessValue && (
              <div className="formGroup">
                <label className="formLabel">Fees Covered By Points</label>
                <input
                  type="text"
                  className={`formControl ${
                    showError.fees ? "errorBorder" : ""
                  }`}
                  name="totalValue"
                  placeholder="Enter Business Value"
                  value={postRequirement.totalValue >= 5000 ? fees : ""}
                  required
                  readOnly
                />
              </div>
            )}
            {postRequirement.totalValue && fees > pointsBalance && (
              <p className="errorMessage">
                Insufficient points. Add {fees - pointsBalance} more
              </p>
            )}
          </div>
          <div>
            <div className="imageGroup">
              <div className="imgGroup1">
                <label className="formLabel">Sample Image 1</label>

                <input
                  type="file"
                  className="formControlFile"
                  name="sampleImage1"
                  accept=".jpeg, .jpg"
                  onChange={handleChange}
                />
              </div>
              <div className="Group1">
                <div className="thumbnail">
                  {thumbnail1 && <img src={thumbnail1} alt="" />}
                </div>
              </div>
            </div>
            <div className="imageGroup">
              <div className="imgGroup1">
                <label className="formLabel">Sample Image 2</label>

                <input
                  type="file"
                  className="formControlFile"
                  name="sampleImage2"
                  accept=".jpeg, .jpg"
                  onChange={handleChange}
                />
              </div>
              <div className="Group1">
                <div className="thumbnail">
                  {thumbnail2 && <img src={thumbnail2} alt="" />}
                </div>
              </div>
            </div>
          </div>

          {pointsBalance >= 25 && (
            <button type="submit" className="postButton">
              Post
            </button>
          )}
          {pointsBalance < 25 && (
            <button className="postButton alertButton">
              Action require a minimum points balance.
            </button>
          )}
        </form>
      </div>
    </div>
  );
};

export default PostRequirement;
