import React from "react";
import "./clubBenift.css";

function ClubBenefit() {
  return (
    <div className="clubBenefitContainer">
      <div className="clubBenefitSection">
        <h4 className="clubBenefit-Title">Club Benift</h4>
        <p className="clubBenefit-title-line1">
          Being part of <span className="beniftIn">Benift</span> is just going to make your life awesome.
        </p>
        <p className="clubBenefit-title-line2">
          Are you a member of Benift.in? Then
          you are our Club Member as well.
        </p>
      </div>
      <div className="clubBenefitSection2">
        <p>
          Benift, a group of business organizations and entrepreneurs, will
          provide a place for all our elite members to enjoy the club benefit.
        </p>
        <ul className="benefitList">
          <li>
            Refreshment outstation trips at a very nominal cost for the members.
          </li>
          <li>
            Special Business Refreshment Meeting at prominent places
            (Hotels/Restaurants) to connect personally with other members, share
            the business, and joy of our network.
          </li>
          <li>
            Enjoy the events such as Product Launch, Movie Screenings, Music
            Concerts, Parties, and Stage Performances. All updates will be
            available at special pricing for club benefit members.
          </li>
        </ul>
      </div>
    </div>
  );
}

export default ClubBenefit;
