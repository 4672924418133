import React, { useEffect, useState } from "react";
import "./recharge.css";
import { LiaRupeeSignSolid } from "react-icons/lia";
import { useUserContext } from "../../../../../Services/contextSevices/indexedDbContext";
import AXIOS from "../../../../../Services/axios";

function Recharge({ rechargeSection }) {
const [data,setData] = useState("")
const { storedUser } = useUserContext();
  const { userId } = storedUser;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await AXIOS.get(`/userSubscription/${userId}`);
        if (response.status === 200) {
          const data = response.data;
          if (data) {
            const expireOnDate = new Date(data.expireOn);
            const formattedExpireOn = expireOnDate.toDateString();
            const currentDate = new Date();
            const timeDiff = Math.abs(expireOnDate.getTime() - currentDate.getTime());
            const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)); 
            data.expireOn = formattedExpireOn;
            data.subscriptionDaysLeft = diffDays;
            setData(data);
          }
        }
      } catch (error) {
        console.error("Error fetching referredId data:", error);
      }
    };
    fetchData();
  }, [userId]);

  return (
    <div>
      {!rechargeSection && (
        <div className="rechargeSections">
          <div className="rechargeDetails">
            <div className="rechargeHeading">Your Subscription</div>
            <div className="rechargeExpiry">
              <span>{data.subscriptionDaysLeft} days left</span>
            </div>
          </div>
        </div>
      )}

      {rechargeSection && (
        <div className="rechargeBody">
        <div className="rechargeSection">
          <div className="rechargeSectionDetails">
            <div className="existingPlan">Existing Plan</div>
            <div className="planAmount">
              <p><LiaRupeeSignSolid/>{data.subscriptionPlan}</p>
            </div>
            <p className="planExpiry">Expire on {data.expireOn}</p>
          </div>
          <div className="rechargePlan">
            <div className="daysLeft">
              <p>{data.subscriptionDaysLeft} days left</p>
            </div>
          </div>
          <div className="rechargeAction">
            <button className="rechargeButton">Renew Here</button>
          </div>
        </div>
        </div>
      )}
    </div>
  );
}

export default Recharge;
