import AXIOS from "../../../Services/axios";
import React, { useState } from "react";
import { BsFillPersonFill, BsPersonCircle } from "react-icons/bs";
import { GiReceiveMoney } from "react-icons/gi";
import { IoDocumentSharp } from "react-icons/io5";
import { CiMenuKebab } from "react-icons/ci";
import { MdAddBusiness } from "react-icons/md";
import { RiFundsFill } from "react-icons/ri";
import { FaPeopleGroup } from "react-icons/fa6";

function ProfileSidebar({ userType, handleProfileName, registrations }) {
  const [activeLink, setActiveLink] = useState("personalDetails");
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const [profileMenu, setProfileMenu] = useState("Personal Info");

  const handleLinkClick = (linkName) => {
    setActiveLink(linkName);
    handleProfileName(linkName);
  };
  const toggleMobileMenu = () => {
    setMobileMenuVisible(!mobileMenuVisible);
  };

  return (
    <div className="profile-sidebar-container">
      <div className="profile-sidebar">
        <div className="profile-pictures">
          {registrations.map((registration, index) => (
            <div key={index} className="profile-picture">
              <img src={registration && registration.profileImg} alt="" />
              <h4 className="profile-name">
                {registration.title !== "Other" ? registration.title : ""}{" "}
                {registration.personName}
              </h4>
            </div>
          ))}
        </div>

        <nav className="profile-nav">
          <div
            onClick={() => handleLinkClick("personalDetails")}
            className={`profile-nav-link ${
              activeLink === "personalDetails" ? "active" : ""
            }`}
          >
            <div className="profile-link-content">
              <BsFillPersonFill className="profileIcons" />
              <span className="profilesidbarTitle">Personal Info</span>
            </div>
          </div>

          {userType === "business" && (
            <div
              onClick={() => handleLinkClick("businessInfo")}
              className={`profile-nav-link ${
                activeLink === "businessInfo" ? "active" : ""
              }`}
            >
              <div className="profile-link-content">
                <MdAddBusiness className="profileIcons" />
                <span className="profilesidbarTitle">Business Info</span>
              </div>
            </div>
          )}

          <div
            onClick={() => handleLinkClick("accounts")}
            className={`profile-nav-link ${
              activeLink === "accounts" ? "active" : ""
            }`}
          >
            <div className="profile-link-content">
              <GiReceiveMoney className="profileIcons" />
              <span className="profilesidbarTitle">Your Account</span>
            </div>
          </div>

          <div
            onClick={() => handleLinkClick("Credential")}
            className={`profile-nav-link ${
              activeLink === "Credential" ? "active" : ""
            }`}
          >
            <div className="profile-link-content">
              <IoDocumentSharp className="profileIcons" />
              <span className="profilesidbarTitle">Documents</span>
            </div>
          </div>

          <div
            onClick={() => handleLinkClick("updateDetails")}
            className={`profile-nav-link ${
              activeLink === "updateDetails" ? "active" : ""
            }`}
          >
            <div className="profile-link-content">
              <BsPersonCircle className="profileIcons" />
              <span className="profilesidbarTitle">Update Profile</span>
            </div>
          </div>
          <div
            onClick={() => handleLinkClick("instafund")}
            className={`profile-nav-link ${
              activeLink === "instafund" ? "active" : ""
            }`}
          >
            <div className="profile-link-content">
              <RiFundsFill className="profileIcons" />
              <span className="profilesidbarTitle"> Insta Fund</span>
            </div>
          </div>
          <div
            onClick={() => handleLinkClick("clubBenifit")}
            className={`profile-nav-link ${
              activeLink === "clubBenifit" ? "active" : ""
            }`}
          >
            <div className="profile-link-content">
              <FaPeopleGroup className="profileIcons" />
              <span className="profilesidbarTitle">Club Benift</span>
            </div>
          </div>
        </nav>
      </div>
      <div className="menuProfile">
        <p className="menuProfile-title">{profileMenu}</p>
        {!mobileMenuVisible && (
          <CiMenuKebab id="profileMenuIcon" onClick={toggleMobileMenu} />
        )}
        {mobileMenuVisible && (
          <CiMenuKebab id="profileMenuIcon" onClick={toggleMobileMenu} />
        )}
      </div>

      {mobileMenuVisible && (
        <nav className="profile-nav-mobile-nav">
          <div
            onClick={() => {
              toggleMobileMenu();
              handleLinkClick("personalDetails");
              setProfileMenu("Personal Info");
            }}
            className={`profile-nav-link ${
              activeLink === "personalDetails" ? "active" : ""
            }`}
          >
            <div className="profile-link-content">
              <BsFillPersonFill className="profileIcons" />
              <span className="profilesidbarTitle"> Personal Info</span>
            </div>
          </div>

          {userType === "business" && (
            <div
              onClick={() => {
                toggleMobileMenu();
                handleLinkClick("businessInfo");
                setProfileMenu("Business Info");
              }}
              className={`profile-nav-link ${
                activeLink === "businessInfo" ? "active" : ""
              }`}
            >
              <div className="profile-link-content">
                <MdAddBusiness className="profileIcons" />
                <span className="profilesidbarTitle"> Business Info</span>
              </div>
            </div>
          )}

          <div
            onClick={() => {
              toggleMobileMenu();
              handleLinkClick("accounts");
              setProfileMenu("Your Account");
            }}
            className={`profile-nav-link ${
              activeLink === "accounts" ? "active" : ""
            }`}
          >
            <div className="profile-link-content">
              <GiReceiveMoney className="profileIcons" />
              <span className="profilesidbarTitle"> Your Account</span>
            </div>
          </div>
          {userType === "business" && (
            <div
              onClick={() => {
                toggleMobileMenu();
                handleLinkClick("Credential");
                setProfileMenu("Documents");
              }}
              className={`profile-nav-link ${
                activeLink === "Credential" ? "active" : ""
              }`}
            >
              <div className="profile-link-content">
                <IoDocumentSharp className="profileIcons" />
                <span className="profilesidbarTitle"> Documents</span>
              </div>
            </div>
          )}
          <div
            onClick={() => {
              toggleMobileMenu();
              handleLinkClick("updateDetails");
              setProfileMenu("Update Profile");
            }}
            className={`profile-nav-link ${
              activeLink === "updateDetails" ? "active" : ""
            }`}
          >
            <div className="profile-link-content">
              <BsPersonCircle className="profileIcons" />
              <span className="profilesidbarTitle">Update Profile</span>
            </div>
          </div>
          <div
            onClick={() => {
              toggleMobileMenu();
              handleLinkClick("instafund");
              setProfileMenu("Insta Fund");
            }}
            className={`profile-nav-link ${
              activeLink === "instafund" ? "active" : ""
            }`}
          >
            <div className="profile-link-content">
              <RiFundsFill className="profileIcons" />
              <span className="profilesidbarTitle">Insta Fund</span>
            </div>
          </div>
          <div
            onClick={() => {
              toggleMobileMenu();
              handleLinkClick("clubBenifit");
              setProfileMenu("clubBenifit");
            }}
            className={`profile-nav-link ${
              activeLink === "clubBenifit" ? "active" : ""
            }`}
          >
            <div className="profile-link-content">
              <FaPeopleGroup className="profileIcons" />
              <span className="profilesidbarTitle">Club Benift</span>
            </div>
          </div>
        </nav>
      )}
    </div>
  );
}

export default ProfileSidebar;
