import React, { useEffect, useState } from "react";
import { BiRupee } from "react-icons/bi";
import "./postedRequirementList.css";
import AXIOS from "../../../Services/axios";
import { useUserContext } from "../../../Services/contextSevices/indexedDbContext";
import { formatDate } from "../../../Services/validation";
import loadingGif from "../../images/loading.gif";
import { IoClose } from "react-icons/io5";
import { useContactData } from "../../../Services/contextSevices/contactContext";

function PostedRequirementList() {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const { storedUser } = useUserContext();
  const [userRequirements, setUserRequirements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isClose, setIsClose] = useState("");
  const [popUpData, setPopUpData] = useState("");
  const { contactData } = useContactData();
  const { email } = contactData;

  useEffect(() => {
    const fetchRequirements = async () => {
      try {
        const response = await AXIOS.get(
          `/posted-requirement/${storedUser.userId}`
        );
        if (response.status === 200) {
          const data = response.data;
          if (data) {
            setUserRequirements(data);
            setLoading(false);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchRequirements();
  }, [storedUser.userId,isClose]);

  const handleHoverIn = (index) => {
    setHoveredIndex(index);
  };

  const handleHoverOut = () => {
    setHoveredIndex(null);
  };

  const handlePopupData = (data) => {
    setPopUpData(data);
  };


  const handleAcceptClient = async (prId, fulfillmentId) => {
    const confirmed = window.confirm("Are you sure you want to accept this client?");
    
    if (confirmed) {
      try {
        const response = await AXIOS.put(`/requirement/${prId}/${fulfillmentId}`);
        if (response.status === 200) {
          let rejectedId;
          // Assuming popUpData is an array
          popUpData.forEach((data, index) => {
            if (data.fulfillmentId !== fulfillmentId) {
              rejectedId = data.fulfillmentId;
            }
          });
          setIsClose("");
          setHoveredIndex(null);
           AXIOS.post(`/requirement-accepted/${prId}/${fulfillmentId}`);
           AXIOS.post(`/requirement-rejected/${prId}/${rejectedId}`);
        }
      } catch (error) {
        alert("Internal Server Error");
      }
    }
  };
  

  


  if (loading) {
    return (
      <div>
        <div className="loading-container">
          <img src={loadingGif} alt="" />
          <p>Loading....</p>
        </div>
      </div>
    );
  }

  return (
    <div className="postedRequirementList">
      {isClose && (
        <div className="popUp">
          <div className="popUpClientContainer">
            <IoClose className="IoClose" onClick={() => setIsClose("")} />
            <div className="popUpContent">
              <div className="contentSection">
                <p className="popUpClientHeading">
                  MEET OUR TOP TWO SHORTLISTED FULFILLMENTS
                </p>
                <div className="popUpClientSection">
                  <span className="popUpClientId">{isClose}</span>
                  <p className="popUpClientMessage">CONFIRM YOUR FULFILLMENT</p>
                  <div class="popUpClientInfo">
                    {popUpData.map((data,index) => (
                      <div
                        className="popUpClientDetails"
                        key={data.id}
                      >
                        <p className="popUpClientName">{`BEST OPTION ${index+1}`}</p>
                        <div className="popUpClientData">
                          <div className="popUpClientLabelDetails">
                            <label className="popUpClientLabel">
                              Agreed Amount
                            </label>
                            {":"}
                            <p className="popUpClientValue popUpClientAmount">
                              <BiRupee />
                              {data.agreedAmount}
                            </p>
                          </div>
                          <div className="popUpClientLabelDetails">
                            <label className="popUpClientLabel">
                              Delivery Date
                            </label>
                            {":"}
                            <p className="popUpClientValue">
                              {data.deliveryDate}
                            </p>
                          </div>
                          <p className="popUpClientSupplyDetails">
                            {data.reason}
                          </p>
                        </div>
                        <div className="popUpClientAcceptButton" onClick={()=>handleAcceptClient(isClose,data.fulfillmentId)}>
                          <button className="popUpClientAcceptBtn">
                            ACCEPT
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <p className="popUpClientQuote">
                Don't let this chance slip through your fingers.
              </p>
            </div>
          </div>
        </div>
      )}
      {userRequirements.length > 0 && (
        <div className="postActionTitle">REQUIREMENTS POSTED</div>
      )}
      <div className="postedRequirementListBox">
        <div className="requirementListContainer">
          {userRequirements.length === 0 ? (
            <div className="noRequirementsMessage">
              <div>NO POSTED REQUIREMENTS FOUND.</div>
              <p className="encouragementText">
                Your next business partner or supplier could be just a click
                away. Post your requirements now, and let us help you find the
                perfect match for your needs!
              </p>
            </div>
          ) : (
            <>
              {userRequirements.map((requirement, index) => (
                <div
                  className={`requirementListCard ${
                    hoveredIndex === index ? "hovering" : ""
                  } ${requirement.assigned?"assignedCard":""}`}
                  key={requirement._id}
                >
                  <div className="cardInnerContent">
                    {hoveredIndex !== index && (
                      <div>
                        <div className="requirementListHeader">
                          <div className="requirementListTitle">
                            Requirement {index + 1}
                          </div>
                          <span className="requirementListId">
                            {requirement.prId}
                          </span>
                        </div>
                        <div className="requirementListSection">
                          <div className="requirementListInfo">
                            <span className="requirementListLabel">
                              Industry
                            </span>
                            <span className="requirementListValue">
                              : {requirement.businessIndustry}
                            </span>
                          </div>
                          <div className="requirementListInfo">
                            <span className="requirementListLabel">
                              Category
                            </span>
                            <span className="requirementListValue">
                              : {requirement.businessCategory}
                            </span>
                          </div>
                          <div className="requirementListInfo">
                            <span className="requirementListLabel">Type</span>
                            <span className="requirementListValue">
                              : {requirement.businessType}
                            </span>
                          </div>
                          <div className="requirementListInfo">
                            <span className="requirementListLabel">
                              Required At
                            </span>
                            <span className="requirementListValue">
                              : {requirement.city}
                            </span>
                          </div>
                        </div>
                        <div className="customRequirementListValueSection">
                          <div className="customRequirementListInfo">
                            <span className="customRequirementListLabel">
                              Business Value
                            </span>
                            <span className="customRequirementListValue totalValue">
                              <BiRupee /> {requirement.totalValue}
                            </span>
                          </div>
                          <div className="customRequirementListInfo">
                            <span className="customRequirementListLabel">
                              Status
                            </span>
                            <span className="customRequirementListValue status">
                              {requirement.status}
                            </span>
                          </div>
                          <div className="customRequirementListInfo">
                            <span className="customRequirementListLabel">
                              Fulfill By
                            </span>
                            <span className="customRequirementListValue">
                              {formatDate(
                                new Date(requirement.expire.split("T")[0])
                              )}
                            </span>
                          </div>
                          <span className="requirementListExpireId">
                            <span>Valid For</span>: {requirement.daysRemaining}{" "}
                            Days
                          </span>
                        </div>
                        <p
                          className={`ListaccessLine ${requirement.topClients.length > 0 && !requirement.assigned  ? "topClientsLine" : ""}`}
                          onClick={() => handleHoverIn(index)}
                        >
                          {`${requirement.topClients.length>0 && !requirement.assigned ?"Click Here for Top Clients.":"Click Here for Description."}`}
                        </p>
                      </div>
                    )}

                    {hoveredIndex === index && (
                      <div className="backside">
                        <div className="postedDescriptionContainer">
                          <span className="postedDescriptionLabel">
                            Description:
                          </span>
                          <span className="postedDescriptionText">
                            {requirement.comment}
                          </span>
                        </div>
                        <div className="postedStatusContainer">
                          <div className="postedStatusLabel">
                            Clients Actions
                          </div>
                          <div className="customRequirementListInfo">
                            <span className="customRequirementListLabel items">
                              Expressed Interest
                            </span>
                            <span className="customRequirementListValue items">
                              {requirement.interestShown.length} Clients
                            </span>
                          </div>
                          <div className="customRequirementListInfo" style={{marginBottom:'5px'}}>
                            <span className="customRequirementListLabel items">
                              Shortlisted
                            </span>
                            <span className="customRequirementListValue items">
                              {requirement.shortlist.length} Clients
                            </span>
                          </div>
                          {requirement.topClients.length>0 && !requirement.assigned && (
                            <p
                              className="ListaccessLine topCLients"
                              onClick={() => {
                                handlePopupData(requirement.topClients);
                                setIsClose(requirement.prId);
                              }}
                            >
                              Top Clients
                            </p>
                          )}
                          <p
                          className="ListaccessLine"
                          onClick={handleHoverOut}
                        >
                         Back
                        </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default PostedRequirementList;
