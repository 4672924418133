import React, { createContext, useContext, useState, useEffect } from 'react';
import AXIOS from '../axios';
import { useUserContext } from './indexedDbContext';
const PointsDataContext = createContext();

export const usePointsData = () => {
  return useContext(PointsDataContext);
};

// Create a provider component to manage the global state
export const PointsDataProvider = ({ children }) => {
  const [pointsData, setPointsData] = useState({});
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const { storedUser } = useUserContext();

  useEffect(() => {
    if (storedUser) {
      const { userId, userType } = storedUser;

      const fetchPointsData = async () => {
        try {
          const response = await AXIOS.get(
            userType === "individual"
              ? `/individual/points/${userId}`
              : `/business/points/${userId}`
          );
          setPointsData(response.data);
        } catch (error) {
          console.error("Error fetching points data:", error);
        }
      };

      fetchPointsData();
    }
  }, [storedUser,updateTrigger]);

  const contextValue = {
    pointsData,
    updateTrigger,
    setUpdateTrigger, // Add setUpdateTrigger to the context object
  };

  return (
    <PointsDataContext.Provider value={contextValue}>
      {children}
    </PointsDataContext.Provider>
  );
};
