import React, { useEffect, useState } from "react";
import "./headBar.css";
import { useNavigate, useLocation } from "react-router-dom";
import { ImMenu3, ImMenu4 } from "react-icons/im";
import { AiFillHome } from "react-icons/ai";
import {
  BsFillFileEarmarkPostFill,
  BsFillPersonLinesFill,
  BsPersonFillLock,
} from "react-icons/bs";
import { HiViewGrid } from "react-icons/hi";
import { MdAppRegistration } from "react-icons/md";
import { IoLogOut } from "react-icons/io5";
import AXIOS from "../../Services/axios";
import Dexie from "dexie";
import { useUserContext } from "../../Services/contextSevices/indexedDbContext";
import logo from "../headBar/Benift.png";
import { IoIosNotifications } from "react-icons/io";
import io from "socket.io-client";

function HeadBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [auth, setAuth] = useState(false);
  const [user, setUser] = useState("");
  const { setStoredUser, storedUser } = useUserContext();
  const { userId } = storedUser ? storedUser : {};
  const [count, setCount] = useState(0);

  useEffect(() => {
    AXIOS.get("/check/auth", { withCredentials: true })
      .then((res) => {
        if (res.status === 200) {
          setAuth(true);
        } else {
          setAuth(false);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.error("Error checking authorization:");
        }
        setAuth(false);
      });
  }, []);

  const toggleMobileNav = () => {
    setShowMobileNav(!showMobileNav);
  };

  const handleLogout = async () => {
    try {
      const response = await AXIOS.get("/logout", { withCredentials: true });

      if (response.status === 200) {
        const db = new Dexie("myDatabase");
        db.version(1).stores({
          userData: "id,userId,userType",
        });

        await db.userData.clear();
        setStoredUser(null);

        setTimeout(() => {
          window.location.reload();
        }, 0);
        navigate("/");
      } else {
        alert("Logout failed. Please try again.");
      }
    } catch (error) {
      alert("An error occurred during logout. Please try again later.");
    }
  };

  useEffect(() => {
    // Fetch data from the appropriate API URL based` on userType
    if (storedUser && userId) {
      let apiUrl = "";

      if (storedUser.userType === "individual") {
        apiUrl = `/individual/registrations/${userId}`;
      } else if (storedUser.userType === "business") {
        apiUrl = `/business/registrations/${userId}`;
      }

      AXIOS.get(apiUrl)
        .then((response) => {
          setUser(response.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [userId]);

  // useEffect(() => {
  //   const fetchRequirements = async () => {
  //     try {
  //       const response = await AXIOS.get(
  //         `/notificationCount/${userId}`
  //       );
  //       if (response.status === 200) {
  //         const data = response.data;
  //         if (data) {
  //           setCount(data);
  //         }
  //       }
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   if(userId){
  //     fetchRequirements();
  //   }

  // }, [location.pathname,userId]);

  const fetchNotificationCount = (socket) => {
    socket.emit("getNotificationCount", userId);
  };

  useEffect(() => {
    const socket = io(process.env.REACT_APP_BACKEND_BASE_URL);

    const updateNotificationCount = (count) => {
      setCount(count);
    };

    const handleError = (error) => {
      console.error("Error fetching notification count:", error);
    };

    socket.on("connect", () => {
      fetchNotificationCount(socket);
    });

    socket.on("notificationCountUpdate", updateNotificationCount);

    socket.on("notificationCountError", handleError);

    return () => {
      socket.disconnect();
    };
  }, [userId]);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_BACKEND_BASE_URL);

    const interval = setInterval(() => {
      fetchNotificationCount(socket);
    }, 5000);

    return () => clearInterval(interval);
  }, [userId]);

  return (
    <header className="headBar">
      <div className="logo">
        <img className="logoImg" src={logo} alt="Logo" />
      </div>
      <nav className="main-Nav">
        <ul className="nav-List">
          <li
            onClick={() => {
              navigate("/");
            }}
            className={`nav-Item ${location.pathname === "/" ? "active" : ""}`}
          >
            Home
          </li>
          {auth && (
            <React.Fragment>
              <li
                onClick={() => {
                  navigate("/postRequirement");
                }}
                className={`nav-Item ${
                  location.pathname === "/postRequirement" ? "active" : ""
                }`}
              >
                Post Requirement
              </li>
              <li
                onClick={() => {
                  navigate("/viewRequirement");
                }}
                className={`nav-Item ${
                  location.pathname === "/viewRequirement" ? "active" : ""
                }`}
              >
                View Requirement
              </li>
              <li
                onClick={() => {
                  navigate("/dashboard");
                }}
                className={`nav-Item ${
                  location.pathname === "/dashboard" ? "active" : ""
                }`}
              >
                Dashboard
              </li>
              <li
                onClick={() => {
                  navigate("/profile");
                }}
                className={`nav-Item ${
                  location.pathname === "/profile" ? "active" : ""
                }`}
              >
                Profile
              </li>
            </React.Fragment>
          )}
          {!auth && (
            <li
              onClick={() => {
                navigate("/register");
              }}
              className={`nav-Item ${
                location.pathname === "/register" ? "active" : ""
              }`}
            >
              Register
            </li>
          )}
        </ul>
        <ul className="user-List">
          {!auth && (
            <li
              onClick={() => {
                navigate("/login");
              }}
              className={`nav-Item ${
                location.pathname === "/login" ? "active" : ""
              }`}
            >
              Login
            </li>
          )}

          {/* notification */}
          {/* {auth && (
            <div
              className="nav-Item notificationSection"
              onClick={() => navigate("/notifications")}
            >
              <IoIosNotifications className="notificationIcon" />
              <p className="notificationCount">{count}</p>
            </div>
          )} */}

          {auth && user && (
            <li className="nav-Item">
              <div className="headbar-profile">
                <img
                  className="headbar-profile-image"
                  src={user && user[0].profileImg}
                  alt=""
                />
                <p className="headbar-user-id">{user[0].userId}</p>
              </div>
            </li>
          )}
          {auth && (
            <li onClick={handleLogout} className="nav-Item">
              Logout
            </li>
          )}
        </ul>
      </nav>

      <div className="mobileNav-container">
        {auth && user && (
          <div className="headbar-profile">
            {user[0].userId[0] === "I" && (
              <img
                className="headbar-profile-image"
                src={user && user[0].referenceImage}
                alt=""
              />
            )}
            {user[0].userId[0] === "B" && (
              <img
                className="headbar-profile-image"
                src={user && user[0].profileImg}
                alt=""
              />
            )}
            <p className="headbar-user-id">{user[0].userId}</p>
          </div>
        )}

        {/* notification */}
        {/* {auth && user && (
          <div
            className="nav-Item notificationSection"
            onClick={() => navigate("/notifications")}
          >
            <IoIosNotifications className="notificationIcon" />
            <p className="notificationCount">599</p>
          </div>
        )} */}

        {!showMobileNav && <ImMenu3 id="menuIcon" onClick={toggleMobileNav} />}
        {showMobileNav && <ImMenu4 id="menuIcon" onClick={toggleMobileNav} />}
        {showMobileNav && (
          <nav className="mobileNav">
            <ul className="mobileNavList">
              {location.pathname !== "/" && (
                <li
                  onClick={() => {
                    navigate("/");
                    toggleMobileNav();
                  }}
                  className={`navItem ${
                    location.pathname === "/" ? "active" : ""
                  }`}
                >
                  <AiFillHome /> Home
                </li>
              )}
              {auth && location.pathname !== "/postRequirement" && (
                <li
                  onClick={() => {
                    navigate("/postRequirement");
                    toggleMobileNav();
                  }}
                  className={`navItem ${
                    location.pathname === "/postRequirement" ? "active" : ""
                  }`}
                >
                  <BsFillFileEarmarkPostFill /> Post Requirement
                </li>
              )}
              {auth && location.pathname !== "/viewRequirement" && (
                <li
                  onClick={() => {
                    navigate("/viewRequirement");
                    toggleMobileNav();
                  }}
                  className={`navItem ${
                    location.pathname === "/viewRequirement" ? "active" : ""
                  }`}
                >
                  <HiViewGrid /> View Requirement
                </li>
              )}
              {!auth && location.pathname !== "/register" && (
                <li
                  onClick={() => {
                    navigate("/register");
                    toggleMobileNav();
                  }}
                  className={`navItem ${
                    location.pathname === "/register" ? "active" : ""
                  }`}
                >
                  <MdAppRegistration /> Register
                </li>
              )}
              {auth && location.pathname !== "/dashboard" && (
                <li
                  onClick={() => {
                    navigate("/dashboard");
                    toggleMobileNav();
                  }}
                  className={`navItem ${
                    location.pathname === "/dashboard" ? "active" : ""
                  }`}
                >
                  <BsFillPersonLinesFill /> Dashboard
                </li>
              )}
              {auth && location.pathname !== "/profile" && (
                <li
                  onClick={() => {
                    navigate("/profile");
                    toggleMobileNav();
                  }}
                  className={`navItem ${
                    location.pathname === "/profile" ? "active" : ""
                  }`}
                >
                  <BsFillPersonLinesFill /> Profile
                </li>
              )}
              {!auth && location.pathname !== "/login" && (
                <li
                  onClick={() => {
                    navigate("/login");
                    toggleMobileNav();
                  }}
                  className={`navItem ${
                    location.pathname === "/login" ? "active" : ""
                  }`}
                >
                  <BsPersonFillLock /> Login
                </li>
              )}
              {auth && (
                <li onClick={handleLogout} className="navItem">
                  <IoLogOut /> Logout
                </li>
              )}
            </ul>
          </nav>
        )}
      </div>
    </header>
  );
}

export default HeadBar;
