import Dashboard from "../pages/dashboard/dashboard";
import Home from "../pages/homePage/Home";
import Login from "../pages/loginPage/Login";
import NotificationPage from "../pages/notificationPage/notificationPage";
import PostRequirement from "../pages/postRequirements/PostRequirement";
import Profile from "../pages/profile/profile";
import Register from "../pages/registration/registration";
import ViewRequirement from "../pages/viewRequirements/ViewRequirement";


export const ROUTES = [
    {
      path: '/',
      element: <Home/>
    },
    {
      path: '/login',
      element: <Login/>
    },
    {
        path: '/register',
        element: <Register/>
      },
      {
        path: '/postRequirement',
        element: <PostRequirement/>
      }
      ,
      {
        path: '/viewRequirement',
        element: <ViewRequirement/>
      },
      {
        path: '/viewRequirement/:prId',
        element: <ViewRequirement/>
      }
      ,
      {
        path: '/profile',
        element:<Profile/>
      },
      {
        path: '/dashboard',
        element:<Dashboard/>
      },
      {
        path: '/notifications',
        element:<NotificationPage/>
      }
  ];