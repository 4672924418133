import axios from 'axios';


// Define your base URL
const baseURL = process.env.REACT_APP_BACKEND_BASE_URL; // Change to your API base URL

// Create an instance of Axios with the base URL
const AXIOS = axios.create({
  baseURL,
});

export default AXIOS;