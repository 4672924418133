import React, { useEffect } from "react";
import { useState } from "react";
import BussinessRegistration from "../../components/registration/bussinessRegistration/bussinessRegistration";
import IndividualRegistration from "../../components/registration/individualRegistration/individualRegistration";
import "./registration.css";
import loadingGif from "../../components/images/loading.gif";
import { useExcelContext } from "../../Services/contextSevices/excelContext";


const Register = () => {
  const [IndividualBusines, setIndividualBussiness] = useState(false);
  const [GroupBusiness, setGroupBussiness] = useState(false);

  
  const Individual = () => {
    setIndividualBussiness(true);
    setGroupBussiness(false);
  };

  const groupIndividual = () => {
    setGroupBussiness(true);
    setIndividualBussiness(false);
  };

  const handleBackButton = () => {
    if (IndividualBusines) {
      setIndividualBussiness(false);
    } else if (GroupBusiness) {
      setGroupBussiness(false);
    }
  };


  return (
    <div>
      
      <div className="registrationBody">
      {!IndividualBusines && !GroupBusiness && (
        <div className="RegistrationConentDetails">
          <div className="HomeRegister-buttons">
            <h4 className="HeadingRegister">WHO CAN REGISTER AS INDIVIDUAL</h4>
            <ul className="contentOf-Register">
              <li>
                People who have jobs like architects, doctors, or consultants
                can register individually. This is important because they often
                provide specialized services and may need official recognition.
                </li>
             
              <li>
                Managers and Admins: Even if you work in a company as a manager,
                administrator, or director, you might need to register
                individually. This could be necessary for legal or tax purposes,
                especially if you have other income sources.
              </li>
              <li>
                
                Non-GST Businesses: Some businesses are too small to be required
                to register for Goods and Services Tax (GST). If you have such a
                business, you can register as an individual. This allows you to
                handle your business income and taxes on a personal level.
              </li>
              <li>
                Freelancers and Self-Employed: If you're a freelancer or
                self-employed, you work for yourself, not a company. Registering
                as an individual lets you legally run your business and handle
                taxes as a sole proprietor.
              </li>

              <li>
               
                Independent Contractors: People who work on specific projects
                for different clients, like IT professionals or writers, can
                register as individuals. It helps them manage their earnings and
                taxes.
              </li>
              <li>
                Small Business Owners: Some people prefer keeping their business
                simple. Instead of forming a separate company, they register as
                individuals. This simplifies their business structure and tax
                reporting.
              </li>
            </ul>
          </div>
          <div className="HomeRegister-buttons">
            <h4 className="HeadingRegister">WHO CAN REGISTER AS BUSINESS</h4>
            <ol className="contentOf-Register">
              <li>
                Limited Companies: Businesses operating as either Public Limited
                or Private Limited companies can register as business entities.
                These are typically larger, formal organizations.
              </li>
              <li>
                Partnership Firms: Partnerships, where two or more people run a
                business together, can register as business entities.
              </li>
              <li>
                
                Proprietorship Firms: Sole proprietorships, where a single
                person owns and runs the business, can also register as business
                entities.
              </li>
              <li>
                Trusts: Trusts, including those with a focus on non-profit
                activities, can register under government regulations. They
                often serve charitable or social causes.
              </li>
              <li>
                Non-Profit Organizations: Non-profit organizations that are
                officially recognized by the government of India can register as
                business entities. These entities typically work for the
                betterment of society.
              </li>
              <li>
                All Business Names: Any business that operates using a business
                name (tradename) can register as a business entity. This
                includes a wide range of businesses, from small shops to large
                corporations.
              </li>
            </ol>
          </div>
        </div>
      )}
        <div id="bussinessButton">
          {!IndividualBusines && !GroupBusiness && (
            <button onClick={Individual}>INDIVIDUAL </button>
          )}
          {!IndividualBusines && !GroupBusiness && (
            <button onClick={groupIndividual}> BUSINESS</button>
          )}
        </div>

        {IndividualBusines && (
          <IndividualRegistration handleBackButton={handleBackButton} />
        )}

        {GroupBusiness && (
          <BussinessRegistration handleBackButton={handleBackButton} />
        )}
      </div>
    </div>
  );
};

export default Register;
