import React, { useEffect, useState } from "react";
import "./notificationPage.css";
import logo from "./Be.png";
import AXIOS from "../../Services/axios";
import { useUserContext } from "../../Services/contextSevices/indexedDbContext";
import { useNavigate } from "react-router";

function NotificationPage() {
  const { storedUser } = useUserContext();
  const { userId } = storedUser;
  const [notifications, setNotifications] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRequirements = async () => {
      try {
        const response = await AXIOS.get(`/notification/${userId}`);
        if (response.status === 200) {
          const data = response.data;
          if (data) {
            setNotifications(data);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (userId[0] === "B") {
      fetchRequirements();
    }
  }, []);

  const handleNavigation = async (prId,notificationId)=>{
    try{
      const response = await AXIOS.put(`/notification/${notificationId}/${userId}`)
      if(response.status === 200){
        navigate(`/viewRequirement/${prId}`)
      }
    }catch(err){
      console.error(err);
    }
  }

  return (
    <div
      className="notificationContainer"
      style={{ paddingTop: notifications ? "70px" : "" }}
    >
      {!notifications && (
        <div className="noRequirements">
          <div className="noRequirementsMessage">
            <div>NO NOTIFICATION FOUND.</div>
            <p className="encouragementText">
              Currently, there are no notifications available. We sincerely
              appreciate your continued engagement. Your next business
              opportunity may be just around the corner! Keep checking back for
              updates, and in the meantime, feel free to explore other features
              of our platform.
            </p>
          </div>
        </div>
      )}

      {notifications && (
        <div className="notificationListSection">
          {notifications &&
            notifications.map((notification, index) => (
              <div class="notificationContent" key={index} onClick={()=>handleNavigation(notification.prId,notification.notificationId)}>
                <div class="notificationIcon">
                  <img src={logo} alt="" />
                </div>
                <div class="notificationMessage">{notification.subject}</div>
                <div class="notificationDescription">
                  {notification.description}
                </div>
                <div class="notificationDate">{notification.createdAt}</div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

export default NotificationPage;
