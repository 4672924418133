import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import "./login.css";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import Dexie from "dexie";
import AXIOS from "../../Services/axios";
import { useUserContext } from "../../Services/contextSevices/indexedDbContext";

const Login = () => {
  const [formData, setFormData] = useState({
    userId: "",
    password: "",
    userType: "",
    email: "",
    otp: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false); // State for password visibility
  const navigate = useNavigate();
  const { storedUser, setStoredUser } = useUserContext();
  const [forgotPasswordSection, setForgotPasswordSection] = useState(false);
  const [verifyOtp, setVerifyOtp] = useState({
    isOtpSent: false,
    waitTime: false,
    isOtpVerify: false,
    totalSeconds: 180,
    resendClicked: false,
  });
  const [showError, setShowError] = useState({
    inActive: false,
    blocked: false,
  });

  useEffect(() => {
    // Check if storedUser exists and has a userId property
    if (storedUser && storedUser.userId) {
      const clearUserData = async () => {
        const db = new Dexie("myDatabase");
        db.version(1).stores({
          userData: "id,userId,userType",
        });

        await db.userData.clear();
        setStoredUser(null);
      };

      clearUserData();
    }
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "userId" || name === "password" || name === "userType") {
      setShowError({ inActive: false, blocked: false });
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    } else if (name === "newPassword" || name === "confirmPassword") {
      const numericValue = value.replace(/\D/g, "");
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: numericValue,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleLogin = async () => {
    try {
      const response = await AXIOS.post("/login", formData, {
        withCredentials: true,
      });

      if (response.status === 200) {
        const user = {
          userId: formData.userId,
          userType: formData.userType,
        };

        const db = new Dexie("myDatabase");

        // Define the schema and create the table (if not exists)
        db.version(1).stores({
          userData: "id,userId,userType", // Define your data structure here
        });

        user.id = 1;

        await db.userData.add(user);
        setTimeout(() => {
          window.location.reload();
        }, 0);
      }
    } catch (error) {
      console.log(error.response);
      if (error.response && error.response.status === 402) {
        
        setShowError({ ...showError, inActive: true });
      } else if (error.response && error.response.status === 403) {
        setShowError({ ...showError, blocked: true });
      } else if (error.response && error.response.status === 401) {
        alert("Login failed. Invalid credentials.");
      } else {
        console.log(error);
        alert("An unexpected error occurred. Please try again later.");
      }
      setFormData({
        ...formData,
        newPassword: "",
        confirmPassword: "",
        otp: "",
        email: "",
        userType: "",
        password: "",
      });
    }
  };

  useEffect(() => {
    if (verifyOtp.waitTime) {
      const countdown = setInterval(() => {
        setVerifyOtp((prevVerifyOtp) => ({
          ...prevVerifyOtp,
          resendClicked: false,
          totalSeconds: prevVerifyOtp.totalSeconds - 1,
        }));
      }, 1000);

      return () => clearInterval(countdown);
    }
  }, [verifyOtp.waitTime]);

  useEffect(() => {
    if (verifyOtp.totalSeconds <= 0 && !verifyOtp.resendClicked) {
      handleResendClick();
    }
  }, [verifyOtp.totalSeconds, verifyOtp.resendClicked]);

  const handleResendClick = () => {
    setVerifyOtp((prevVerifyOtp) => ({
      ...prevVerifyOtp,
      resendClicked: true,
    }));
  };

  // Convert seconds to minutes and seconds
  const minutes = Math.floor(verifyOtp.totalSeconds / 60);
  const seconds = verifyOtp.totalSeconds % 60;

  const handleOTP = async () => {
    if (formData.userType === "" || formData.email === "") {
      alert("Please fill the required Input");
      return;
    }

    const data = { userType: formData.userType, email: formData.email };

    try {
      setVerifyOtp({ ...verifyOtp, waitTime: true });
      const response = await AXIOS.post("/verify/email", data);
      if (response.status === 200) {
        alert("Please Check the OTP in your Email.");
        setVerifyOtp({ ...verifyOtp, waitTime: false, isOtpSent: true });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          window.alert("User not found for the specified email.");
          setForgotPasswordSection(false);
          setVerifyOtp({
            isOtpSent: false,
            isOtpVerify: false,
            totalSeconds: 180,
          });
          setFormData({
            ...formData,
            newPassword: "",
            confirmPassword: "",
            otp: "",
            email: "",
            userType: "",
            password: "",
            userId: "",
          });
        } else {
          alert("Internal server error.Please try later");
        }
      }
    }
  };

  const verifyOTP = async () => {
    const data = {
      userType: formData.userType,
      email: formData.email,
      otp: formData.otp,
    };

    try {
      const response = await AXIOS.post("/verify/otp", data, {
        withCredentials: true,
      });
      if (response.status === 200) {
        alert("OTP Verified");
        setVerifyOtp({ ...verifyOtp, isOtpVerify: true });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          window.alert("OTP Expired");
        } else if (error.response.status === 401) {
          window.alert("Invalid credentials");
        } else {
          console.error("Error:", error.response.data);
        }
      } else if (error.request) {
        console.error("Error:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  const handleSave = async () => {
    if (verifyOtp.isOtpVerify && formData.newPassword.length < 6) {
      alert("Enter Valid Password");
      return;
    }

    if (
      verifyOtp.isOtpVerify &&
      formData.newPassword !== formData.confirmPassword
    ) {
      alert("Passwords Must Match Each Other");
      return;
    }
    const data = {
      userType: formData.userType,
      email: formData.email,
      password: formData.newPassword,
    };
    try {
      const response = await AXIOS.put("/update-password", data);
      alert("Password updated successfully");
      setTimeout(() => {
        window.location.reload();
      }, 0);
    } catch (error) {
      console.error("Error:", error.response.data);
    }
  };

  const HandleBackToLogin = () => {
    setForgotPasswordSection(false);
    setVerifyOtp({ isOtpSent: false, isOtpVerify: false });
    setFormData({
      ...formData,
      newPassword: "",
      confirmPassword: "",
      otp: "",
      email: "",
      userType: "",
      password: "",
      userId: "",
    });
  };

  return (
    <div>
      <div className="loginContainer">
        {!forgotPasswordSection && (
          <div>
            <h3 className="loginHeader">Login</h3>
          </div>
        )}
        {forgotPasswordSection && (
          <div>
            <h3 className="loginHeader">Reset Password</h3>
          </div>
        )}
        <form>
          {!forgotPasswordSection && (
            <div className="loginFormGroup">
              <label>UserId </label>
              <input
                type="userId"
                className="loginControl"
                placeholder="Enter userId"
                name="userId"
                value={formData.userId.toUpperCase()}
                onChange={handleInputChange}
                required
              />
            </div>
          )}

          {!forgotPasswordSection && (
            <div className="loginFormGroup">
              <label>Password</label>
              <div className="passwordInput">
                <input
                  type={showPassword ? "text" : "password"} // Toggle input type
                  className="loginControl"
                  placeholder="Password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  required
                />
                <button
                  type="button"
                  className={`passwordToggle ${showPassword ? "visible" : ""}`}
                  onClick={togglePasswordVisibility}
                >
                  <span className="passwordIcon">
                    {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                  </span>
                </button>
              </div>
            </div>
          )}

          {!verifyOtp.isOtpVerify && (
            <div className="loginFormGroup">
              <label>User Type</label>
              <select
                className="loginControl"
                name="userType"
                value={formData.userType}
                onChange={handleInputChange}
                required
              >
                <option value="">Select User Type</option>
                <option value="individual">Individual</option>
                <option value="business">Business</option>
              </select>
            </div>
          )}

          {!forgotPasswordSection && (
            <div>
              <button
                type="button"
                className="loginButton"
                onClick={handleLogin}
              >
                Login
              </button>
              {showError.inActive && (
                <p className="verifyOtp inActiveErrorLIne">
                  Your account is currently in a pending process. It will be
                  activated shortly.
                </p>
              )}
              {showError.blocked && (
                <p className="verifyOtp inActiveErrorLIne">
                  Your account is Blocked.Please contact support.
                </p>
              )}
            </div>
          )}

          {forgotPasswordSection && !verifyOtp.isOtpVerify && (
            <div className="loginFormGroup">
              <label>Gmail</label>
              <input
                className="loginControl"
                placeholder="Your email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
              {verifyOtp.waitTime && !verifyOtp.resendClicked && (
                <p className="verifyOtp">
                  {minutes < 10 ? `Wait for 0${minutes}` : minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds}
                </p>
              )}
              {verifyOtp.waitTime && verifyOtp.resendClicked && (
                <p className="verifyOtp">
                  {" "}
                  We apologize.Please click on resend
                </p>
              )}
            </div>
          )}

          {forgotPasswordSection &&
            verifyOtp.isOtpSent &&
            !verifyOtp.isOtpVerify && (
              <div className="loginFormGroup">
                <label>Enter OTP</label>
                <input
                  className="loginControl"
                  placeholder="Your OTP"
                  name="otp"
                  value={formData.otp}
                  onChange={handleInputChange}
                  required
                />
                <p className="verifyOtp">OTP valid for 5 minutes</p>
              </div>
            )}

          {forgotPasswordSection &&
            !verifyOtp.isOtpSent &&
            !verifyOtp.resendClicked && (
              <button type="button" className="saveButton" onClick={handleOTP}>
                Send OTP
              </button>
            )}

          {forgotPasswordSection &&
            !verifyOtp.isOtpSent &&
            verifyOtp.resendClicked && (
              <button type="button" className="saveButton" onClick={handleOTP}>
                Resend OTP
              </button>
            )}

          {forgotPasswordSection &&
            verifyOtp.isOtpSent &&
            !verifyOtp.isOtpVerify && (
              <button type="button" className="saveButton" onClick={verifyOTP}>
                Verify OTP
              </button>
            )}

          {forgotPasswordSection && verifyOtp.isOtpVerify && (
            <div className="loginFormGroup">
              <label>New Password</label>
              <div className="passwordInput">
                <input
                  type={showPassword ? "text" : "password"} // Toggle input type
                  className="loginControl"
                  placeholder="Enter New Password"
                  name="newPassword"
                  value={formData.newPassword}
                  onChange={handleInputChange}
                  maxLength={6}
                  required
                />
                <button
                  type="button"
                  className={`passwordToggle ${showPassword ? "visible" : ""}`}
                  onClick={togglePasswordVisibility}
                >
                  <span className="passwordIcon">
                    {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                  </span>
                </button>
              </div>
              {formData.newPassword && formData.newPassword.length < 6 && (
                <div className="alertPasswordLine">
                  SIX-DIGITS NUMERIC PASSWORD.
                </div>
              )}
            </div>
          )}

          {forgotPasswordSection && verifyOtp.isOtpVerify && (
            <div className="loginFormGroup">
              <label>Confirm Password</label>
              <div className="passwordInput">
                <input
                  type={showPassword ? "text" : "password"} // Toggle input type
                  className="loginControl"
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  maxLength={6}
                  required
                />
                <button
                  type="button"
                  className={`passwordToggle ${showPassword ? "visible" : ""}`}
                  onClick={togglePasswordVisibility}
                >
                  <span className="passwordIcon">
                    {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                  </span>
                </button>
              </div>
              {formData.confirmPassword &&
                formData.newPassword !== formData.confirmPassword && (
                  <div className="errorLine">
                    PASSWORDS MUST MATCH EACH OTHER
                  </div>
                )}
            </div>
          )}

          {forgotPasswordSection && verifyOtp.isOtpVerify && (
            <button type="button" className="saveButton" onClick={handleSave}>
              Save
            </button>
          )}

          {!forgotPasswordSection && (
            <div className="navSignUp">
              <div
                className="forgotPassword"
                onClick={() => {
                  setForgotPasswordSection(true);
                  setShowError({ inActive: false, blocked: false });
                }}
              >
                Forgot Password?
              </div>
              <div>
                <span>Not registered yet? </span>
                <a onClick={() => navigate("/register")} className="navSign">
                  Sign Up
                </a>
              </div>
            </div>
          )}

          {forgotPasswordSection && (
            <div className="navSignUp">
              <div>
                <span>Back to </span>
                <a onClick={HandleBackToLogin} className="navSign">
                  Login
                </a>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default Login;
