import React, { useState } from "react";
import "./profileAccounts.css";
import Points from "./AccountsComponents/Coins/points";
import Recharge from "./AccountsComponents/recharge/recharge";
import ReferAndEarn from "./AccountsComponents/referAndEarn/referAndEarn";
import { IoIosArrowRoundBack } from "react-icons/io";

function ProfileAccounts() {
  const [pointsSection, setPointSection] = useState(false);
  const [rechargeSection, setRechargeSection] = useState(false);
  const [referAndEarnSection, setReferAndEarnSection] = useState(false);

  return (
    <div className="accountsDetails">
      { (pointsSection || rechargeSection || referAndEarnSection) && (
  <IoIosArrowRoundBack
    className="accountsBackIcon"
    onClick={() => {
      setPointSection(false);
      setRechargeSection(false);
      setReferAndEarnSection(false);
    }}
  />
)}

      {!rechargeSection && !referAndEarnSection && (
        <div onClick={() => setPointSection(true)}>
          <Points pointsSection={pointsSection} />
        </div>
      )}
      {!pointsSection && !referAndEarnSection && (
        <div onClick={() => setRechargeSection(true)}>
          <Recharge rechargeSection={rechargeSection} />
        </div>
      )}
      {!pointsSection && !rechargeSection && (
        <div onClick={() => setReferAndEarnSection(true)}>
          <ReferAndEarn referAndEarnSection={referAndEarnSection} />
        </div>
      )}
    </div>
  );
}

export default ProfileAccounts;
