import React, { createContext, useContext, useState, useEffect } from 'react';
import AXIOS from '../axios';
import { useUserContext } from './indexedDbContext';
const contactDataContext = createContext();

export const useContactData = () => {
  return useContext(contactDataContext);
};

export const ContactDataProvider = ({ children }) => {
    const [contactData, setContactData] = useState({});
    const { storedUser } = useUserContext();
  
    useEffect(() => {
      if (storedUser) {
        const fetchContactData = async () => {
          try {
            const response = await AXIOS.post("/user/contact",storedUser);
            setContactData(response.data);
          } catch (error) {
            console.error("Error fetching Contact data:", error);
          }
        };
  
        fetchContactData();
      }
    }, [storedUser]);
  
    const contextValue = {
        contactData,
    };
  
    return (
      <contactDataContext.Provider value={contextValue}>
        {children}
      </contactDataContext.Provider>
    );
  };