import React, { useState } from "react";
import "./dashboard.css";
import PostedRequirementLIst from "../../components/dashboard/postedRequirementList/postedRequirementLIst";
import InterestRequirementList from "../../components/dashboard/interestedRequirementList/interestRequirementList";
import ShortListedRequirement from "../../components/dashboard/shortListedRequirementList/shortListedRequirement";
import ClosedRequirementList from "../../components/dashboard/closedRequirementList/closedRequirementList";
import { IoIosArrowRoundBack } from "react-icons/io";

function Dashboard() {
  const [dashboardModule,setDashboardModule] = useState("")

  return (
    <div className="dashboardSection">
      {dashboardModule !== "" && <IoIosArrowRoundBack className="dashboardBackIcon" onClick={()=>setDashboardModule("")}/>}
    {dashboardModule === "" && (<div className="dashboardContainer">
      <div className="dashboardCard">
        <div className="dashboardHeadline">MY ACTIVITIES</div>
        <div className="dashboardItem">
          <div className="dashboardTitle" onClick={()=>setDashboardModule("postedRequirement")}>
            <span>POSTINGS</span>
          </div>
          <div className="dashboardTitle" onClick={()=>setDashboardModule("interestShownRequirement")}>
            <span>INTEREST SHOWN</span>
          </div>
        </div>
        <div className="dashboardItem" >
          <div className="dashboardTitle" onClick={()=>setDashboardModule("shortListedRequirement")}>
            <span>SHORTLISTED</span>
          </div>
          <div className="dashboardTitle" onClick={()=>setDashboardModule("closedRequirement")}> 
            <span>CLOSED</span>
          </div>
        </div>
      </div>
      </div>)}
      {dashboardModule === "postedRequirement" && (<PostedRequirementLIst setDashboardModule={setDashboardModule}/>)}
      {dashboardModule === "interestShownRequirement" && (<InterestRequirementList/>)}
      {dashboardModule === "shortListedRequirement" && (<ShortListedRequirement/>)}
      {dashboardModule === "closedRequirement" && (<ClosedRequirementList/>)}
    
    </div>
  );
}

export default Dashboard;
