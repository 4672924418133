import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ROUTES } from "./Services/Routes";
import HeadBar from "../src/components/headBar/headBar";
import "./App.css";
import Dexie from "dexie";
import AXIOS from "./Services/axios";
import { useUserContext } from "./Services/contextSevices/indexedDbContext";

function App() {
  const [auth, setAuth] = useState(false);
  const { setStoredUser, storedUser } = useUserContext();
  const { userId, userType } = storedUser ? storedUser : {};

  useEffect(() => {
    const fetchData = async () => {
      if (userType === undefined || userId === undefined) {
        try {
          const res = await AXIOS.get("/check/auth", { withCredentials: true });
          if (res.status === 200) {
            setAuth(true);
          } else {
            // Handle cleanup operations
            const db = new Dexie("myDatabase");
            db.version(1).stores({
              userData: "id,userId,userType",
            });

            await db.userData.clear();
            setStoredUser(null);

            setTimeout(() => {
              window.location.reload();
            }, 0);

            setAuth(false);
          }
        } catch (error) {
          console.error(
            "Error checking authorization:",
            error.response?.data?.error || "Network error"
          );
          setAuth(false);
        }
      }
    };

    fetchData(); // Call the async function immediately
  }, [storedUser]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userType !== undefined && userId !== undefined) {
          const res = await AXIOS.get(`/check/auth/${userId}/${userType}`, {
            withCredentials: true,
          });

          if (res.status === 200) {
            setAuth(true);
          } else {
            // Handle cleanup operations
            const db = new Dexie("myDatabase");
            db.version(1).stores({
              userData: "id,userId,userType",
            });

            await db.userData.clear();
            setStoredUser(null);

            setTimeout(() => {
              window.location.reload();
            }, 0);

            setAuth(false);
          }
        }
      } catch (error) {
        console.error(
          "Error checking authorization:",
          error.response?.data?.error || "Network error"
        );
        setAuth(false);
      }
    };

    fetchData();
  }, [storedUser]);

  //|| path === "/notifications"

  return (
    <div>
      <BrowserRouter>
        <HeadBar />
        <Routes>
          {ROUTES.map(({ path, element }, index) => (
            <Route
              key={path + "_" + index}
              path={path}
              element={
                (!auth &&
                  (path === "/" ||
                    path === "/login" ||
                    path === "/register")) ||
                (auth &&
                  (path === "/" ||
                    path === "/postRequirement" ||
                    path === "/viewRequirement" ||
                    path === "/viewRequirement/:prId" ||
                    path === "/dashboard" ||
                    path === "/profile")) ? (
                  element
                ) : (
                  <Navigate to="/" />
                )
              }
            />
          ))}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
