import React, { useEffect, useState } from "react";
import "./shortListedRequirement.css";
import { BiRupee } from "react-icons/bi";
import { LiaHandPointer } from "react-icons/lia";
import { GiTwoCoins } from "react-icons/gi";
import AXIOS from "../../../Services/axios";
import { useUserContext } from "../../../Services/contextSevices/indexedDbContext";
import { formatDate, interestPoints } from "../../../Services/validation";
import { usePointsData } from "../../../Services/contextSevices/pointsGlobalState";
import { useContactData } from "../../../Services/contextSevices/contactContext";
import loadingGif from "../../images/loading.gif";

function ShortListedRequirement() {
  const { storedUser } = useUserContext();
  const [userRequirements, setUserRequirements] = useState([]);
  const [hoveredIndex, setHoveredIndex] =
    useState(null);
  const [updateEffect, setUpdateEffect] = useState(false);
  const { pointsData, updateTrigger, setUpdateTrigger } = usePointsData();
  const {
    pointsBalance,
    pointsSpentOnShowingInterest,
    interestShownCount,
    totalInterestShownValue,
    totalActionAmount,
  } = pointsData;
  const { contactData } = useContactData();
  const {email} = contactData
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRequirements = async () => {
      try {
        const response = await AXIOS.get(
          `/short-listed/requirement/${storedUser.userId}`
        );
        if (response.status === 200) {
          const data = response.data;
          if (data) {
            setUserRequirements(data);
            setLoading(false)
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchRequirements();
  }, [storedUser.userId, updateEffect]);

  const handleHoverIn = (index) => {
    setHoveredIndex(index);
  };

  const handleHoverOut = () => {
    setHoveredIndex(null);
  };

  const handleInterestShownButton = async (selectedRequirement) => {
    try {
      if (interestPoints(selectedRequirement.totalValue) > pointsBalance) {
        alert("You Are Running Low On Points. Please Add More Points.");
        return;
      } else {
        const confirmed = window.confirm(
          "Are you sure you want to show interest?"
        );
        if (confirmed) {
          const fees = interestPoints(selectedRequirement.totalValue);

          // Calculate updated points balance
          const updatedPointsBalance = pointsBalance - fees;
          const updatedInterestValue =
            totalInterestShownValue +
            parseInt(selectedRequirement.totalValue, 10);
          const updatedInterestCount = interestShownCount + 1;
          const updatedPointsSpentOnInterests =
            pointsSpentOnShowingInterest + fees;
          const updatedTotalActionAmount =
            totalActionAmount + parseInt(selectedRequirement.totalValue, 10);

          // Update pointsBalance using the PUT API
          await AXIOS.put(
            storedUser.userType === "individual"
              ? `/individual/points/${storedUser.userId}`
              : `/business/points/${storedUser.userId}`,
            {
              pointsBalance: updatedPointsBalance,
              totalInterestShownValue: updatedInterestValue,
              interestShownCount: updatedInterestCount,
              pointsSpentOnShowingInterest: updatedPointsSpentOnInterests,
              totalActionAmount: updatedTotalActionAmount,
            }
          );

          setUpdateTrigger(!updateTrigger);

          // Send a POST request to show interest for the selected requirement
          await AXIOS.post(
            `/requirements/${selectedRequirement._id}/interest`,
            {
              userId: storedUser.userId,
            }
          );

          await AXIOS.delete(
            `/requirements/${selectedRequirement._id}/shortlist`,
            {
              data: { userId: storedUser.userId },
            }
          );
          setUpdateEffect(!updateEffect);
          setHoveredIndex(null); 

          const contactResponse = await AXIOS.post("/message/email",{email,prId:selectedRequirement.prId,requirementType:"INTERESTED"})
          
        }
      }
    } catch (error) {
      console.error("Error occurred while showing interest:", error);
    }
  };

  const handleDownloadImage = async (filename) => {
    try {
      const response = await AXIOS.get(
        AXIOS.defaults.baseURL + `/postRequirements/${filename}`,
        {
          responseType: "blob", // Set the response type to blob
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  if (loading) {
    return (
      <div>
        <div className="loading-container">
          <img src={loadingGif} alt="" />
          <p>Loading....</p>
        </div>
      </div>
    );
  }

  return (
    <div className="shortListedList">
      {userRequirements.length > 0 && (
        <div className="postActionTitle">REQUIREMENTS SHORTLISTED</div>
      )}
      <div className="shortListedListBox">
        <div className="shortListedListContainer">
          {userRequirements.length === 0 ? (
            <div className="noRequirementsMessage">
              <div>NO SHORTLISTED REQUIREMENTS FOUND.</div>
              <p className="encouragementText">
                Unlock potential business partnerships and suppliers at your
                fingertips. Share your requirements today, and let us guide you
                to the perfect match for your needs!
              </p>
            </div>
          ) : (
            userRequirements.map((requirement, index) => (
              <div
              className={`requirementListCard shortListedListCard ${
                hoveredIndex === index ? "hovering" : ""
              }`}
                key={requirement._id}
              >
                <div className="cardInnerContent">
                  {hoveredIndex !== index && (
                    <div>
                      <div className="shortListedListHeader">
                        <div className="shortListedListTitle">
                          Requirement#{index + 1}
                        </div>
                        <span className="shortListedListId ">
                          {requirement.prId}
                        </span>
                      </div>
                      <div className="shortListedListSection">
                        <div className="shortListedListInfo">
                          <span className="shortListedListLabel">Industry</span>
                          <span className="shortListedListValue">
                            : {requirement.businessIndustry}
                          </span>
                        </div>
                        <div className="shortListedListInfo">
                          <span className="shortListedListLabel">Category</span>
                          <span className="shortListedListValue">
                            : {requirement.businessCategory}
                          </span>
                        </div>

                        <div className="shortListedListInfo">
                          <span className="shortListedListLabel">Type</span>
                          <span className="shortListedListValue">
                            : {requirement.businessType}
                          </span>
                        </div>
                        <div className="shortListedListInfo">
                          <span className="shortListedListLabel">
                            REQUIRED IN{" "}
                          </span>
                          <span className="shortListedListValue ">
                            : {requirement.city}
                          </span>
                        </div>
                      </div>
                      <div className="customshortListedListValueSection">
                        <div className="customshortListedListInfo">
                          <span className="customshortListedListLabel">
                            Business Value
                          </span>
                          <span className="customshortListedListValue totalValue">
                            <BiRupee /> {requirement.totalValue}
                          </span>
                        </div>
                        <div className="customshortListedListInfo">
                          <span className="customshortListedListLabel">
                            Fullfill By
                          </span>
                          <span className="customshortListedListValue ">
                            {formatDate(
                              new Date(requirement.expire.split("T")[0])
                            )}
                          </span>
                        </div>

                        <div className="customshortListedListInfo">
                          <span className="customshortListedListLabel">
                            Valid For
                          </span>
                          <span className="customshortListedListValue ">
                            {requirement.daysRemaining} Days
                          </span>
                        </div>
                      </div>
                      <div>
                        <button
                          className="shortListedinterestingButton"
                          onClick={() => handleInterestShownButton(requirement)}
                        >
                          Interested <LiaHandPointer />
                        </button>

                        {interestPoints(requirement.totalValue) <=
                        pointsBalance ? (
                        <p className="shortListedPoints">
                        {interestPoints(requirement.totalValue)}{" "}points<GiTwoCoins className="pointsIcon" />
                        </p>) : (
                          <p className="lowShortListedPoints">
                            Points are low, Recharge
                          </p>)}
                      </div>

                      <p
                        onClick={() => handleHoverIn(index)}
                        className="ListaccessLine"
                      >
                        View Description
                      </p>
  
                    </div>
                  )}

                  {hoveredIndex === index && (
                    <div className="backside">
                      <div className="shortListedDescriptionContainer">
                        <span className="shortListedDescriptionLabel">
                          Description :
                        </span>
                        <span className="shortListedDescriptionText">
                          {requirement.comment}
                        </span>
                      </div>
                      <div className="downloadShortlistedImageContainer">
                        {requirement.sampleImage1 && <button
                          className="downloadShortlistedImage"
                          onClick={() =>
                            handleDownloadImage(requirement.sampleImage1)
                          }
                        >
                          Download Image 1
                        </button>}
                        {requirement.sampleImage2 && <button
                          className="downloadShortlistedImage"
                          onClick={() =>
                            handleDownloadImage(requirement.sampleImage2)
                          }
                        >
                          Download Image 2
                        </button>}
                      </div>
                      <p
                        onClick={handleHoverOut}
                        className="ListaccessLine"
                      >
                        Back
                      </p>
                    </div>
                  )}
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}

export default ShortListedRequirement;
