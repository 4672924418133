
import InstaUserInfoForm from "./InstafundUserFrom/InstaUserInfoForm";
import "./instafund.css";
import { BiRupee } from "react-icons/bi";
import { useEffect, useState } from "react";
import { IoIosArrowRoundBack } from "react-icons/io";
import AXIOS from "../../../Services/axios";

const Instafund = () => {
  const [table, setTable] = useState([]);
  const [instaFundForm, setInstaFundForm] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({
    monthlyPay: 0,
    availFunds: 0,
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await AXIOS.get("/instaFund/excel");
        setTable(response.data);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  const handleStartPlan = (monthlyPay, availFunds) => {
    setInstaFundForm(true);
    setSelectedPlan({
      monthlyPay,
      availFunds,
    });
  };

  return (
    <div className={`Instafund-tabe-container ${instaFundForm ? "Instafund-tabe-container2" : ""}`}>
      {instaFundForm &&<IoIosArrowRoundBack className="instaFundBackIcon" onClick={()=>setInstaFundForm(false)}/>}
    <div className="Instafund-tabel">
      {!instaFundForm && (
        <div>
          <h4 className="instafund-Title">Insta Fund</h4>

          <table className="my-table">
            <thead>
              <tr>
                <th className="instafund-name" colSpan={4}>
                  Instant Funding Support for Business Transactions
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="instafund-col"> Slno</td>
                <td className="instafund-col"> Monthly Pay</td>
                <td className="instafund-col"> Avail Funds Upto/ For 7 Days</td>
                <td className="instafund-col"> Select Your Plan</td>
              </tr>

              {table.map((rowData, index) => (
                <tr key={index} className="my-table-row">
                  <td className="instafund-row">{index + 1}</td>
                  <td className="instafund-row">
                    <BiRupee /> {rowData["Monthly Pay"]}
                  </td>
                  <td className="instafund-row">
                    <BiRupee /> {rowData["Avail Funds Upto/ For 7 Days"]}
                  </td>
                  <td className="instafund-row">
                    <span
                      className="instafund-button"
                      onClick={() =>
                        handleStartPlan(
                          parseFloat(rowData["Monthly Pay"]),
                          parseFloat(rowData["Avail Funds Upto/ For 7 Days"])
                        )
                      }
                    >
                      Start Plan
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      
    </div>
    {instaFundForm && <InstaUserInfoForm selectedPlan={selectedPlan} setInstaFundForm={setInstaFundForm}/>}
    </div>
  );
};

export default Instafund;
