import React, { useState, useEffect } from "react";
import "./viewRequirements.css";
import { GiTwoCoins } from "react-icons/gi";
import { BiRupee } from "react-icons/bi";
import { HiOutlineSave } from "react-icons/hi";
import { LiaHandPointer } from "react-icons/lia";
import { FaRegHandPeace } from "react-icons/fa";
import SimpleImageSlider from "react-simple-image-slider";
import { useUserContext } from "../../Services/contextSevices/indexedDbContext";
import AXIOS from "../../Services/axios";
import { formatDate, interestPoints } from "../../Services/validation";
import { usePointsData } from "../../Services/contextSevices/pointsGlobalState";
import { HiSearch } from "react-icons/hi";
import { useContactData } from "../../Services/contextSevices/contactContext";
import noImage from "./noImage.jpg";
import loadingGif from "../../components/images/loading.gif";
import { Navigate, useLocation, useNavigate, useParams } from "react-router";

const ViewRequirement = () => {
  const [requirements, setRequirements] = useState([]);
  const [selectedRequirement, setSelectedRequirement] = useState(null);
  const [search, setSearch] = useState({
    searchPRId: "",
    searchCategory: "",
    searchDays: "",
    searchCity: "",
  });
  const [referenceImages, setReferenceImages] = useState([]);
  const { storedUser } = useUserContext();
  const { userId } = storedUser;
  const { pointsData, updateTrigger, setUpdateTrigger } = usePointsData();
  const {
    pointsBalance,
    pointsSpentOnShowingInterest,
    interestShownCount,
    totalInterestShownValue,
    totalActionAmount,
  } = pointsData;
  const [refresh, setRefresh] = useState(0);
  const [userStatus, setUserStatus] = useState({
    isShortlisted: false,
    isInterested: false,
  });
  const [userRequirements, setUserRequirements] = useState([]);
  const { contactData } = useContactData();
  const { email } = contactData;
  const [loading, setLoading] = useState(true);
  const [myCategory, setMyCategory] = useState(false);
  const [registrations, setRegistrations] = useState([]);
  const { pathname } = useLocation();
  const { prId } = useParams() || {};
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await AXIOS.get("/requirement", {
          withCredentials: true,
        });

        setSearch({
          searchPRId: "",
          searchCategory: "",
          searchDays: "",
          searchCity: "",
        });

        if (response.status === 200) {
          if (pathname !== "/viewRequirement") {
            setSearch({ ...search, searchPRId: prId });
          }
          const data = response.data;
          if (data) {
            // Format the "expire" date for all items in the data array
            const formattedData = data.map((item) => ({
              ...item,
              expire: formatDate(new Date(item.expire.split("T")[0])),
            }));

            setRequirements(formattedData);
            setLoading(false);

            if (formattedData.length > 0) {
              if (prId) {
                const notificationId = formattedData.find(requirement => requirement.prId === prId);

                if (notificationId) {
                  setSelectedRequirement(notificationId);
                  const newReferenceImages = [
                    {
                      url:
                        notificationId.sampleImage1 !== "null"
                          ? notificationId.sampleImage1
                          : notificationId.sampleImage2 !== "null"
                          ? notificationId.sampleImage2
                          : noImage,
                    },
                    {
                      url:
                        notificationId.sampleImage2 !== "null"
                          ? notificationId.sampleImage2
                          : notificationId.sampleImage1 !== "null"
                          ? notificationId.sampleImage1
                          : noImage,
                    },
                  ];

                  setReferenceImages(newReferenceImages);

                  const userShortlisted = notificationId.shortlist?.some(
                    (item) => item.actionId === userId
                  );
                  const userInterested = notificationId.interestShown?.some(
                    (item) => item.actionId === userId
                  );
  
                  setUserStatus({
                    isShortlisted: userShortlisted || false,
                    isInterested: userInterested || false,
                  });
                }
              }

              if (!prId) {
                const firstRequirement = formattedData[0];
                setSelectedRequirement(firstRequirement);

                const newReferenceImages = [
                  {
                    url:
                      response.data[0].sampleImage1 !== "null"
                        ? response.data[0].sampleImage1
                        : response.data[0].sampleImage2 !== "null"
                        ? response.data[0].sampleImage2
                        : noImage,
                  },
                  {
                    url:
                      response.data[0].sampleImage2 !== "null"
                        ? response.data[0].sampleImage2
                        : response.data[0].sampleImage1 !== "null"
                        ? response.data[0].sampleImage1
                        : noImage,
                  },
                ];
                setReferenceImages(newReferenceImages);

                const userShortlisted = firstRequirement.shortlist?.some(
                  (item) => item.actionId === userId
                );
                const userInterested = firstRequirement.interestShown?.some(
                  (item) => item.actionId === userId
                );

                setUserStatus({
                  isShortlisted: userShortlisted || false,
                  isInterested: userInterested || false,
                });
              }
            }
          }
        } else {
          console.log("Failed to fetch requirements");
        }
      } catch (error) {
        console.error("Error occurred while fetching requirements:", error);
      }
    };

    fetchData();
  }, [pathname]);

  useEffect(() => {
    const fetchRequirements = async () => {
      try {
        const response = await AXIOS.get(`/posted-requirement/${userId}`);
        if (response.status === 200) {
          const data = response.data;
          if (data) {
            setUserRequirements(data);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchRequirements();
  }, [userId]);

  useEffect(() => {
    if (storedUser) {
      let apiUrl = "";

      if (storedUser.userType === "individual") {
        apiUrl = `/individual/registrations/${storedUser.userId}`;
      } else if (storedUser.userType === "business") {
        apiUrl = `/business/registrations/${storedUser.userId}`;
      }

      AXIOS.get(apiUrl)
        .then((response) => {
          setRegistrations(response.data);
          setLoading(false); // Data fetched, loading is complete
        })
        .catch((error) => {
          setLoading(false); // Error occurred, loading is complete
        });
    }
  }, [myCategory]);

  const handleRequirementClick = async (requirement) => {
    setSelectedRequirement(requirement);
    setRefresh((prevRefresh) => prevRefresh + 1);

    const newReferenceImages = [
      {
        url:
          requirement.sampleImage1 !== "null"
            ? requirement.sampleImage1
            : requirement.sampleImage2 !== "null"
            ? requirement.sampleImage2
            : noImage,
      },
      {
        url:
          requirement.sampleImage2 !== "null"
            ? requirement.sampleImage2
            : requirement.sampleImage1 !== "null"
            ? requirement.sampleImage1
            : noImage,
      },
    ];

    setReferenceImages(newReferenceImages);

    const userShortlisted = requirement.shortlist?.some(
      (item) => item.actionId === userId
    );
    const userInterested = requirement.interestShown?.some(
      (item) => item.actionId === userId
    );
    setUserStatus({
      isShortlisted: userShortlisted || false,
      isInterested: userInterested || false,
    });
  };

  const handleSearchCategoryChange = (event) => {
    const { value, name } = event.target;
    if (name === "searchDays") {
      const validInput = value.replace(/[^0-9]/g, "");
      setSearch((prevSearchValue) => ({
        ...prevSearchValue,
        [name]: validInput,
      }));
    } else {
      setSearch((prevSearchValue) => ({
        ...prevSearchValue,
        [name]: value,
      }));
    }
  };

  const handleClearAll = () => {
    setSearch({
      searchPRId: "",
      searchCategory: "",
      searchDays: "",
      searchCity: "",
    });
    setMyCategory(false)
  };

  const handleInterestButton = async () => {
    if (
      !userStatus.isInterested &&
      interestPoints(selectedRequirement.totalValue) > pointsBalance
    ) {
      alert("Running Low on points for this requirement. Please add points.");
      return;
    } else if (!userStatus.isInterested) {
      const confirmed = window.confirm(
        "Are you sure you want to show interest?"
      );
      if (confirmed) {
        try {
          const fees = interestPoints(selectedRequirement.totalValue);

          // Calculate updated points balance
          const updatedPointsBalance = pointsBalance - fees;
          const updatedInterestValue =
            totalInterestShownValue +
            parseInt(selectedRequirement.totalValue, 10);
          const updatedInterestCount = interestShownCount + 1;
          const updatedPointsSpentOnInterests =
            pointsSpentOnShowingInterest + fees;
          const updatedTotalActionAmount =
            totalActionAmount + parseInt(selectedRequirement.totalValue, 10);

          // Update pointsBalance using the PUT API
          const updatePointsResponse = await AXIOS.put(
            storedUser.userType === "individual"
              ? `/individual/points/${userId}`
              : `/business/points/${userId}`,
            {
              pointsBalance: updatedPointsBalance,
              totalInterestShownValue: updatedInterestValue,
              interestShownCount: updatedInterestCount,
              pointsSpentOnShowingInterest: updatedPointsSpentOnInterests,
              totalActionAmount: updatedTotalActionAmount,
            }
          );

          setUpdateTrigger(!updateTrigger);

          await AXIOS.post(
            `/requirements/${selectedRequirement._id}/interest`,
            {
              userId: userId,
            }
          );

          const updatedHistory = {
            action: "INTEREST SHOWN",
            id: selectedRequirement.prId,
            value: selectedRequirement.totalValue,
            points: fees,
            closed: "NO",
          };

          const transactionResponse = await AXIOS.post(
            storedUser.userType === "individual"
              ? `/individual/transactions/${storedUser.userId}`
              : `/business/transactions/${storedUser.userId}`,
            { history: updatedHistory }
          );

          const updatedRequirements = requirements.map((requirement) => {
            if (requirement._id === selectedRequirement._id) {
              return {
                ...requirement,
                interestShown: [...requirement.interestShown, userId],
              };
            }
            return requirement;
          });

          setRequirements(updatedRequirements);

          setSelectedRequirement((prevSelectedRequirement) => ({
            ...prevSelectedRequirement,
            interestShown: [...prevSelectedRequirement.interestShown, userId],
          }));

          setUserStatus({
            isShortlisted: userStatus.isShortlisted, // Preserve the previous value
            isInterested: true, // Set to true since the user has shown interest
          });

          await AXIOS.delete(
            `/requirements/${selectedRequirement._id}/shortlist`,
            {
              data: { userId: storedUser.userId },
            }
          );

          const contactResponse = await AXIOS.post("/message/email", {
            email,
            Id: selectedRequirement.prId,
            requirementType: "INTERESTED",
          });
        } catch (error) {
          console.error("Error occurred while showing interest:", error);
        }
      }
    }
  };

  const handleShortlistButton = async () => {
    if (!userStatus.isShortlisted) {
      const confirmed = window.confirm(
        "Are you sure you want to put this in your shortlist?"
      );
      if (confirmed) {
        try {
          await AXIOS.post(
            `/requirements/${selectedRequirement._id}/shortlist`,
            {
              userId: userId,
            }
          );

          const updatedRequirements = requirements.map((requirement) => {
            if (requirement._id === selectedRequirement._id) {
              return {
                ...requirement,
                shortlist: [...requirement.shortlist, userId],
              };
            }
            return requirement;
          });

          setRequirements(updatedRequirements);

          setSelectedRequirement((prevSelectedRequirement) => ({
            ...prevSelectedRequirement,
            shortlist: [...prevSelectedRequirement.shortlist, userId],
          }));

          setUserStatus({
            isShortlisted: true,
            isInterested: userStatus.isInterested, // Preserve the previous value
          });
        } catch (error) {
          console.error("Error occurred while shortlisting:", error);
        }
      }
    } else {
      const confirmed = window.confirm(
        "Are you sure you want to remove this from your shortlist?"
      );
      if (confirmed) {
        try {
          await AXIOS.delete(
            `/requirements/${selectedRequirement._id}/shortlist`,
            {
              data: { userId: userId },
            }
          );

          const updatedRequirements = requirements.map((requirement) => {
            if (requirement._id === selectedRequirement._id) {
              return {
                ...requirement,
                shortlist: requirement.shortlist.filter((id) => id !== userId),
              };
            }
            return requirement;
          });

          setRequirements(updatedRequirements);

          setSelectedRequirement((prevSelectedRequirement) => ({
            ...prevSelectedRequirement,
            shortlist: prevSelectedRequirement.shortlist.filter(
              (id) => id !== userId
            ),
          }));

          setUserStatus({
            isShortlisted: false,
            isInterested: userStatus.isInterested, // Preserve the previous value
          });
        } catch (error) {
          console.error("Error occurred while removing from shortlist:", error);
        }
      }
    }
  };

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === referenceImages.length - 1 ? 0 : prevIndex + 1
      );
    }, 2000);
    return () => clearInterval(interval);
  }, [referenceImages.length]);

  if (loading) {
    return (
      <div>
        <div className="loading-container">
          <img src={loadingGif} alt="" />
          <p>Loading....</p>
        </div>
      </div>
    );
  }

  return (
    <div>
      {requirements.length > 0 ? (
        <div id="requirementBody">
          <div className="filterSections">
            <div className="searchBarSection">
              <div id="searchBar">
                <input
                  type="search"
                  placeholder="PR ID"
                  name="searchPRId"
                  value={search.searchPRId.toUpperCase()}
                  onChange={handleSearchCategoryChange}
                />
                {!search.searchPRId && <HiSearch className="search-icon" />}
              </div>

              <div id="searchBar">
                <input
                  type="search"
                  placeholder="Category"
                  name="searchCategory"
                  value={search.searchCategory}
                  onChange={handleSearchCategoryChange}
                />
                {!search.searchCategory && <HiSearch className="search-icon" />}
              </div>

              <div id="searchBar" className="searchbarDaysHide">
                <input
                  type="search"
                  name="searchDays"
                  value={search.searchDays}
                  placeholder="Validity Days [Only Numbers]"
                  onChange={handleSearchCategoryChange}
                />
                {!search.searchDays && <HiSearch className="search-icon" />}
              </div>
              <div id="searchBar">
                <input
                  type="search"
                  name="searchCity"
                  value={search.searchCity}
                  placeholder="City"
                  onChange={handleSearchCategoryChange}
                />
                {!search.searchCity && <HiSearch className="search-icon" />}
              </div>
            </div>
            <div className="clearAll" onClick={handleClearAll}>
              Clear All
            </div>
          </div>
          <div id="requirementsInfoSection">
            {storedUser.userType === "business" && !myCategory && (
              <button
                onClick={() => {
                  setMyCategory(true);
                  setSearch((prevSearch) => ({
                    ...prevSearch,
                    searchCategory:
                      registrations &&
                      registrations[0] &&
                      registrations[0].businessCategory1
                        ? registrations[0].businessCategory1
                        : "",
                  }));
                }}
                className="myCategoryMobileButton"
              >
                My Category
              </button>
            )}

            {storedUser.userType === "business" && myCategory && (
              <button
                onClick={() => {
                  setMyCategory(false);
                  setSearch((prevSearch) => ({
                    ...prevSearch,
                    searchCategory: "",
                  }));
                }}
                className="myCategoryMobileButton"
              >
                Show All
              </button>
            )}

            <div id="requirementList">
              {storedUser.userType === "business" && !myCategory && !prId && (
                <button
                  onClick={() => {
                    setMyCategory(true);
                    setSearch((prevSearch) => ({
                      ...prevSearch,
                      searchCategory:
                        registrations &&
                        registrations[0] &&
                        registrations[0].businessCategory1
                          ? registrations[0].businessCategory1
                          : "",
                    }));
                  }}
                  className="myCategoryButton"
                >
                  My Category
                </button>
              )}

              {storedUser.userType === "business" && (myCategory || prId) && (
                <button
                  onClick={() => {
                    setMyCategory(false);
                    setSearch((prevSearch) => ({
                      ...prevSearch,
                      searchCategory: "",
                      searchPRId: "",
                    }));
                    navigate("/viewRequirement");
                  }}
                  className="myCategoryButton allShowButton"
                >
                  Show All
                </button>
              )}

              {requirements
                .filter((requirement) =>
                  requirement.prId
                    .toLowerCase()
                    .includes(search.searchPRId.toLowerCase())
                )
                .filter((requirement) =>
                  requirement.businessCategory
                    .toLowerCase()
                    .includes(search.searchCategory.toLowerCase())
                )
                .filter((requirement) =>
                  requirement.expireCount
                    .toLowerCase()
                    .includes(search.searchDays.toLowerCase())
                )
                .filter((requirement) =>
                  requirement.city
                    .toLowerCase()
                    .includes(search.searchCity.toLowerCase())
                )
                .map((requirement) => (
                  <div
                    key={requirement._id}
                    className={`requirementCard ${
                      selectedRequirement &&
                      selectedRequirement._id === requirement._id
                        ? "selected"
                        : ""
                    }`}
                    onClick={() => handleRequirementClick(requirement)}
                  >
                    <div className="requirementHeader">
                      <div className="requirementTitle">Requirement</div>
                      <span className="requirementId ">{requirement.prId}</span>
                    </div>
                    <div className="requirementSection">
                      <div className="requirementInfo">
                        <span className="requirementLabel">Industry</span>
                        <span className="requirementValue">
                          {": "}
                          {requirement.businessIndustry}
                        </span>
                      </div>
                      <div className="requirementInfo">
                        <span className="requirementLabel">Category</span>
                        <span className="requirementValue">
                          {": "}
                          {requirement.businessCategory}
                        </span>
                      </div>

                      <div className="requirementInfo">
                        <span className="requirementLabel">Type</span>
                        <span className="requirementValue">
                          {": "}
                          {requirement.businessType}
                        </span>
                      </div>
                      <div className="requirementInfo">
                        <span className="requirementLabel">City</span>
                        <span className="requirementValue ">
                          {": "}
                          {requirement.city}
                        </span>
                      </div>
                    </div>
                    <div className="customRequirementValueSection">
                      <div className="customRequirementInfo">
                        <span className="customRequirementLabel">
                          Business Value
                        </span>
                        <span className="customRequirementValue totalValue">
                          <BiRupee />
                          {requirement.totalValue}
                        </span>
                      </div>
                      <div className="customRequirementInfo">
                        <span className="customRequirementLabel">
                          Fullfill By
                        </span>
                        <span className="customRequirementValue ">
                          {requirement.expire}
                        </span>
                      </div>
                      <span className="requirementExpireId ">
                        <span>Valid For</span> : {requirement.daysRemaining}{" "}
                        Days
                      </span>
                    </div>
                    <p className="accessLine">
                      CLICK ON CARD TO ACCESS COMPLETE INFORMATION.
                    </p>
                  </div>
                ))}
              {requirements.length > 0 &&
                requirements
                  .filter((requirement) =>
                    requirement.prId
                      .toLowerCase()
                      .includes(search.searchPRId.toLowerCase())
                  )
                  .filter((requirement) =>
                    requirement.businessCategory
                      .toLowerCase()
                      .includes(search.searchCategory.toLowerCase())
                  )
                  .filter((requirement) =>
                    requirement.expireCount
                      .toLowerCase()
                      .includes(search.searchDays.toLowerCase())
                  )
                  .filter((requirement) =>
                    requirement.city
                      .toLowerCase()
                      .includes(search.searchCity.toLowerCase())
                  ).length === 0 && (
                  <p className="emptyRequirementList">
                    "Keep exploring! Unfortunately, there are no requirements
                    that fit your criteria."
                    <p>
                      To see the full list, try clearing your search criteria.
                    </p>
                  </p>
                )}
            </div>

            <div id="requirementFullDetails">
              {selectedRequirement !== null && (
                <div className="fullDetailsCard" key={refresh}>
                  <div className="detailsHeader">
                    Detailed Requirement Information
                  </div>
                  <div className="fullDetailsCardInfo">
                    <div className="detailsContent">
                      <div className="detailsInfoGroup">
                        <div className="detailsInfo">
                          <span className="detailsLabel">PR ID:</span>
                          <span className="detailsValue">
                            {selectedRequirement.prId}
                          </span>
                        </div>
                        <div className="detailsInfo">
                          <span className="detailsLabel">
                            Business Industry:
                          </span>
                          <span className="detailsValue">
                            {selectedRequirement.businessIndustry}
                          </span>
                        </div>
                        <div className="detailsInfo">
                          <span className="detailsLabel">
                            Business Category:
                          </span>
                          <span className="detailsValue">
                            {selectedRequirement.businessCategory}
                          </span>
                        </div>

                        <div className="detailsInfo">
                          <span className="detailsLabel">Business Type:</span>
                          <span className="detailsValue">
                            {selectedRequirement.businessType}
                          </span>
                        </div>
                        <div className="detailsInfo">
                          <span className="detailsLabel">Required At:</span>
                          <span className="detailsValue">
                            {selectedRequirement.city}
                          </span>
                        </div>
                        <div className="detailsValueGroup">
                          <div className="detailsInfo">
                            <span className="detailsLabel">
                              Business Value:
                            </span>
                            <span
                              className="detailsValue"
                              style={{ fontWeight: "650", color: "purple" }}
                            >
                              <BiRupee />
                              {selectedRequirement.totalValue}
                            </span>
                          </div>
                          <div className="detailsInfo">
                            <span className="detailsLabel">Expiry Date:</span>
                            <span
                              className="detailsValue"
                              style={{ fontWeight: "650", color: "purple" }}
                            >
                              {selectedRequirement.expire}
                            </span>
                          </div>
                          <div className="detailsInfo">
                            <span className="detailsLabel">Valid For:</span>
                            <span
                              className="detailsValue"
                              style={{ fontWeight: "650", color: "purple" }}
                            >
                              {selectedRequirement.expireCount} Days
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="imageContainer ">
                        <div className="detailsImgInfo">
                          <span className="detailsImageLabel">Samples</span>

                          <div className="sliderImage">
                            {referenceImages.map((image, index) => (
                              <img
                                key={index}
                                src={image.url}
                                alt={`Slide ${index + 1}`}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "fill",
                                  position:
                                    index === currentIndex
                                      ? "static"
                                      : "absolute",
                                  opacity: index === currentIndex ? 1 : 0,
                                  transition: "opacity 0.5s ease-in-out",
                                }}
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="detailsCommentsSections">
                      <div className="detailsCommentsInfo">
                        <span className="detailsCommentsLabel">
                          Requirement Description:
                        </span>
                        <span className="detailsCommentsValue">
                          {selectedRequirement.comment}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="buttonContainer">
                    {!userStatus.isInterested && !userStatus.isShortlisted && (
                      <div>
                        <button
                          className={`interestingButton ${
                            userStatus.isShortlisted ? "greenBackground" : ""
                          }`}
                          onClick={handleShortlistButton}
                        >
                          Shortlist <HiOutlineSave />
                        </button>
                        <p className="shortListLine">No Coins Deduction</p>
                      </div>
                    )}

                    {!userStatus.isInterested && userStatus.isShortlisted && (
                      <div>
                        <button
                          className={`interestingButton ${
                            userStatus.isShortlisted ? "greenBackground" : ""
                          }`}
                          onClick={handleShortlistButton}
                        >
                          Shortlisted <FaRegHandPeace />
                        </button>
                        <p className="shortListLine">Click to Unshortlist</p>
                      </div>
                    )}

                    {pointsBalance >= 25 && !userStatus.isInterested && (
                      <div>
                        <button
                          className={`interestingButton ${
                            userStatus.isInterested ? "greenBackground" : ""
                          }`}
                          onClick={handleInterestButton}
                        >
                          Interested <LiaHandPointer />
                        </button>
                        {interestPoints(selectedRequirement.totalValue) <=
                        pointsBalance ? (
                          <p className="interestedPoints ">
                            {interestPoints(selectedRequirement.totalValue)}{" "}
                            points <GiTwoCoins className="pointsIcon" />
                          </p>
                        ) : (
                          <p className="lowInterestedPoints">
                            Points are low, Recharge
                          </p>
                        )}
                      </div>
                    )}

                    {pointsBalance >= 25 && userStatus.isInterested && (
                      <div>
                        <button
                          className={`shortListedinterestingButton ${
                            userStatus.isInterested ? "greenBackground" : ""
                          }`}
                          onClick={handleInterestButton}
                        >
                          Shown Interest <FaRegHandPeace />
                        </button>
                      </div>
                    )}

                    {pointsBalance < 25 && (
                      <div
                        onClick={() =>
                          alert("Action needs Sufficient Points in Balance")
                        }
                      >
                        <button className="interestingButton viewAlertButton">
                          Add Coins
                        </button>
                        <p className="shortListLine viewAlertButtonLine">
                          Maintain Balance
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="noRequirements">
          <div className="noRequirementsMessage">
            <div>NO POSTED REQUIREMENTS FOUND.</div>
            <p className="encouragementText">
              We are immensely grateful for the opportunities we have had thus
              far, and while we may not currently have any specific requirements
              but Your next business partner or supplier could be just a click
              away. Post your requirements now, and let us help you find the
              perfect match for your needs!
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewRequirement;
