import React, { useEffect, useState } from "react";
import AXIOS from "../../../Services/axios";
import { useNavigate } from "react-router";
import {
  fetchAutofillData,
  updateFormDataBasedOnBusinessPincode,
  updateFormDataBasedOnPincode,
} from "../../../Services/pincodeFunctionality";
import {
  emailValidation,
  pincodeValidation,
} from "../../../Services/validation";
import "./updateProfile.css";
import Dexie from "dexie";
import { useUserContext } from "../../../Services/contextSevices/indexedDbContext";
import { useContactData } from "../../../Services/contextSevices/contactContext";
import io from "socket.io-client";
import _ from "lodash";

const UpdateProfile = ({ userType, registrations }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    phoneNo: "",
    phoneNo2: "",
    pincode: "",
    area: "",
    city: "",
    state: "",
    businessPincode: "",
    businessArea: "",
    businessCity: "",
    businessState: "",
  });
  const [showError, setShowError] = useState({
    email: false,
    pincode: false,
    phoneNo2: false,
    businessPincode: false,
  });
  const { setStoredUser } = useUserContext();
  const [profileImage, setProfileImage] = useState(null);
  const [registrationCount, setRegistrationCount] = useState(0);
  const [cityPrefixMatch, setCityPrefixMatch] = useState(false);
  const [selectedArea, setSelectedArea] = useState([]);
  const [selectedBusinessArea, setSelectedBusinessArea] = useState([]);
  const [pincode, setPincode] = useState({
    personal: false,
    business: false,
  });
  const { contactData } = useContactData();
  const [socketEmitCount, setSocketEmitCount] = useState(0);

  const generateUserID = (prefix, city, count) => {
    const cityPrefix = city.substring(0, 3).toUpperCase();
    return `${prefix}${cityPrefix}${count}`;
  };

  const { userId, city, businessCity } = registrations[0];

  useEffect(() => {
    const firstThreeLetters =
      userType === "individual"
        ? city.substring(0, 3).toUpperCase()
        : businessCity.substring(0, 3).toUpperCase();
    const formDataFirstThreeLetters = formData[
      userType === "individual" ? "city" : "businessCity"
    ]
      .substring(0, 3)
      .toUpperCase();

    if (
      (userType === "individual" && formData.pincode.length === 6) ||
      (userType !== "individual" && formData.businessPincode.length === 6)
    ) {
      if (firstThreeLetters !== formDataFirstThreeLetters) {
        setCityPrefixMatch(true);
      }

      if (firstThreeLetters === formDataFirstThreeLetters) {
        setCityPrefixMatch(false);
      }
    } else if (
      (userType === "individual" && formData.pincode.length !== 6) ||
      (userType !== "individual" && formData.businessPincode.length !== 6)
    ) {
      setCityPrefixMatch(false);
    }
  }, [formData.pincode, formData.businessPincode]);

  const handleChange = (event) => {
    const { name, value, type, files } = event.target;

    if (name === "pincode" && type !== "file") {
      const isValidPincode = pincodeValidation(value);
      setShowError((prevErrors) => ({
        ...prevErrors,
        pincode: !isValidPincode,
      }));
      setFormData((prevFormData) => ({
        ...prevFormData,
        pincode: value,
      }));
    } else if (name === "businessPincode" && type !== "file") {
      const isValidPincode = pincodeValidation(value);
      setShowError((prevErrors) => ({
        ...prevErrors,
        businessPincode: !isValidPincode,
      }));
      setFormData((prevFormData) => ({
        ...prevFormData,
        businessPincode: value,
      }));
    } else if (type === "file") {
      setProfileImage(files[0]);
    } else if (type !== "file") {
      setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    }

    if (name === "email" && type !== "file") {
      const isValidEmail = emailValidation(value);
      setShowError((prevErrors) => ({
        ...prevErrors,
        email: !isValidEmail,
      }));
    }

    if (name === "phoneNo2" && type !== "file") {
      if (value && value === formData.phoneNo) {
        setShowError((prevErrors) => ({
          ...prevErrors,
          phoneNo2: true,
        }));
      } else {
        setShowError((prevErrors) => ({
          ...prevErrors,
          phoneNo2: false,
        }));
      }
    }
  };

  const fetchData = async () => {
    try {
      const response = await AXIOS.get(`/excel/pincode/${formData.pincode}`);
      if (response.data && response.data !== "NOT FOUND") {
        const { city, state, country, areas } = response.data;
        setPincode((prev) => ({
          ...prev,
          personal: true,
        }));
        setFormData((prevFormData) => ({
          ...prevFormData,
          state: state,
          country: country,
          city: city,
          area: "",
        }));
        setSelectedArea(areas);
      } else if (response.data && response.data === "NOT FOUND") {
        setPincode((prev) => ({
          ...prev,
          personal: false,
        }));
        setFormData((prevFormData) => ({
          ...prevFormData,
          state: "",
          country: "",
          city: "",
          area: "",
        }));
        setSelectedArea([]);
      }
    } catch (error) {
      alert("Internal Server Error");
    }
  };

  const fetchBusinessData = async () => {
    try {
      const response = await AXIOS.get(
        `/excel/pincode/${formData.businessPincode}`
      );
      if (response.data && response.data !== "NOT FOUND") {
        const { city, state, country, areas } = response.data;
        setPincode((prev) => ({
          ...prev,
          business: true,
        }));
        setFormData((prevBasicInfo) => ({
          ...prevBasicInfo,
          businessState: state,
          businessCountry: country,
          businessArea: "",
          businessCity: city,
        }));
        setSelectedBusinessArea(areas);
      } else if (response.data && response.data === "NOT FOUND") {
        setPincode((prev) => ({
          ...prev,
          business: false,
        }));
        // If autofill[value] or autofill[value][0] does not exist, set state, country, and city to empty
        setFormData((prevFormData) => ({
          ...prevFormData,
          businessState: "",
          businessCountry: "",
          businessArea: "",
          businessCity: "",
        }));
        setSelectedBusinessArea([]);
      }
    } catch (error) {
      alert("Internal Server Error");
    }
  };

  useEffect(() => {
    if (formData.pincode.length === 6) {
      fetchData();
    }
  }, [formData.pincode]);

  useEffect(() => {
    if (formData.businessPincode.length === 6) {
      fetchBusinessData();
    }
  }, [formData.businessPincode]);

  useEffect(() => {
    if (formData.phoneNo && formData.phoneNo2 === formData.phoneNo) {
      setShowError((prevErrors) => ({
        ...prevErrors,
        phoneNo2: true,
      }));
    } else {
      setShowError((prevErrors) => ({
        ...prevErrors,
        phoneNo2: false,
      }));
    }
  }, [formData.phoneNo]);

  const handleSave = async (e) => {
    e.preventDefault();

    if (formData.pincode.length === 6 && formData.area === "") {
      alert("Please Fill Area");
      return;
    }

    if (formData.businessPincode === 6 && formData.businessArea === "") {
      alert("Please Fill Business Area");
      return;
    }

    const { email, pincode, phoneNo2 } = showError;

    if (formData.phoneNo && formData.phoneNo.length < 10) {
      alert("Please fill in Phone Number correctly.");
    } else if (formData.phoneNo2 && phoneNo2) {
      alert("Please fill in Alternate Number correctly.");
    } else if (formData.email && email) {
      alert("Please fill in Email correctly.");
    } else if (formData.pincode && (showError.pincode || pincode.personal)) {
      alert("Please fill in Valid Pincode.");
    } else if (
      formData.businessPincode &&
      (showError.businessPincode || pincode.business)
    ) {
      alert("Please fill in Valid Business Pincode.");
    } else {
      try {
        const formDataWithImage = new FormData();
        formDataWithImage.append("userId", userId);

        const imageFieldName =
          userType === "individual" ? "referenceImage" : "profileImg";
        formDataWithImage.append(imageFieldName, profileImage);

        let userID;
        userID = !cityPrefixMatch
          ? userId
          : generateUserID(
              userType === "individual" ? "I" : "B",
              formData[userType === "individual" ? "city" : "businessCity"],
              registrationCount
            );
        formDataWithImage.append("userID", userID);

        for (const key in formData) {
          formDataWithImage.append(key, formData[key]);
        }

        const response = await AXIOS.patch(
          userType === "individual"
            ? "/individual/update-profile"
            : "/business/update-profile",
          formDataWithImage,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        if (response.status === 200) {
          const alertMessage = cityPrefixMatch
            ? "Updated Successfully and Login Again With New UserID"
            : "Updated Successfully";
          alert(alertMessage);
          navigate("/");

          if (cityPrefixMatch) {
            const socket = io(process.env.REACT_APP_BACKEND_BASE_URL);
            if (userType === "individual" && formData.city) {
              socket.emit("newRegistration", {
                type: "individual",
                city: formData.city,
              });
            } else if (userType === "business" && formData.businessCity) {
              socket.emit("newRegistration", {
                type: "business",
                businessCity: formData.businessCity,
              });
            }

            const logoutResponse = await AXIOS.get("/logout", {
              withCredentials: true,
            });

            if (logoutResponse.status === 200) {
              try {
                const db = new Dexie("myDatabase");
                db.version(1).stores({
                  userData: "id,userId,userType",
                });
                await db.userData.clear();
                setStoredUser(null);

                window.location.reload(true);
                navigate("/login");
              } catch (error) {
                console.error("Error clearing database:", error);
                alert("Error clearing database. Please try again.");
              }
            }

            const contactResponse = await AXIOS.post("/message/email", {
              email: contactData.email,
              Id: userID,
              requirementType: "UPDATEID",
            });
          }
        } else {
          alert("Update failed. Please try again.");
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 402) {
            alert("Only JPG, JPEG type files are allowed.");
          } else {
            alert(`${error.response.data.message}`);
          }
        } else {
          // Handle other errors with a generic message
          alert("An error occurred. Please try again later.");
        }
      }
    }
  };

  const emitBusinessCityData = _.debounce(
    (city) => {
      if (socketEmitCount < 1) {
        const socket = io(process.env.REACT_APP_BACKEND_BASE_URL);
        socket.emit("cityData", { city, type: "business" });
        setSocketEmitCount((prevCount) => prevCount + 1);
      } else {
        // Reset the count after emitting 3 times
        setSocketEmitCount(0);
      }
    },
    500 // Adjust the debounce delay as needed
  );

  const emitIndividualCityData = _.debounce(
    (city) => {
      if (socketEmitCount < 1) {
        const socket = io(process.env.REACT_APP_BACKEND_BASE_URL);
        socket.emit("cityData", { city, type: "individual" });
        setSocketEmitCount((prevCount) => prevCount + 1);
      } else {
        // Reset the count after emitting 3 times
        setSocketEmitCount(0);
      }
    },
    500 // Adjust the debounce delay as needed
  );

  useEffect(() => {
    const socket = io(process.env.REACT_APP_BACKEND_BASE_URL);
    if (userType === "business") {
      if (formData.businessCity && formData.businessPincode.length > 5) {
        emitBusinessCityData(formData.businessCity);
        socket.on("liveRegistrationCount", ({ businessCity, count }) => {
          if (businessCity === formData.businessCity) {
            setRegistrationCount(count);
          } else if (businessCity !== formData.businessCity) {
            emitBusinessCityData(formData.city);
          }
        });
      }

      return () => {
        // Clean up the event listener
        socket.off("liveRegistrationCount");
        // Disconnect the socket when the component unmounts
        socket.disconnect();
      };
    } else {
      if (formData.city && formData.pincode.length > 5) {
        emitIndividualCityData(formData.city);
        socket.on("liveRegistrationCount", ({ city, count }) => {
          if (city === formData.city) {
            setRegistrationCount(count);
          } else if (city !== formData.city) {
            emitIndividualCityData(formData.city);
          }
        });
      }

      return () => {
        // Clean up the event listener
        socket.off("liveRegistrationCount");
        // Disconnect the socket when the component unmounts
        socket.disconnect();
      };
    }
  }, [
    formData.email,
    formData.phoneNo,
    formData.phoneNo2,
    formData.area,
    formData.city,
    formData.state,
    formData.businessArea,
    formData.businessCity,
    formData.businessState,
    userType,
    setRegistrationCount,
  ]);

  return (
    <div className="profile-content">
      <h4 className="updateHeading">Update Your Profile</h4>
      <form className="update-form" encType="multipart/form-data">
        {/* Render all input fields */}
        <div className="update-input">
          <label>Email</label>
          <div className="alertErrorSection">
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter your new email Id"
              className={` ${
                formData.email && showError.email ? "invalidInput" : ""
              }`}
            />
            {formData.email && showError.email && (
              <div className="errorLine">ENTER CORRECT EMAIL ID</div>
            )}
          </div>
        </div>
        <div className="update-input">
          <label>Phone No</label>
          <div className="alertErrorSection">
            <input
              type="tel"
              name="phoneNo"
              value={(formData.phoneNo = formData.phoneNo.replace(/\D/g, ""))}
              onChange={handleChange}
              placeholder="Enter your new Phone No"
              maxLength={10}
              className={` ${
                formData.phoneNo &&
                formData.phoneNo.length != 0 &&
                formData.phoneNo.length < 10
                  ? "invalidInput"
                  : ""
              }`}
            />
            {formData.phoneNo &&
              formData.phoneNo.length != 0 &&
              formData.phoneNo.length < 10 && (
                <div className="errorLine">
                  PHONE NUMBER SHOULD HAVE 10 DIGITS
                </div>
              )}
          </div>
        </div>
        <div className="update-input">
          <label>Alternate No</label>
          <div className="alertErrorSection">
            <input
              type="tel"
              name="phoneNo2"
              className={` ${
                formData.phoneNo2 && showError.phoneNo2 ? "invalidInput" : ""
              }`}
              value={(formData.phoneNo2 = formData.phoneNo2.replace(/\D/g, ""))}
              onChange={handleChange}
              placeholder="Enter your new Alternate No"
              maxLength={10}
            />
            {formData.phoneNo2 && showError.phoneNo2 && (
              <div className="errorLine">MOBILE NUMBERS SHOULD NOT BE SAME</div>
            )}
          </div>
        </div>
        <div className="update-input">
          <label>Pincode</label>
          <div className="alertErrorSection">
            <input
              type="text"
              name="pincode"
              value={(formData.pincode = formData.pincode.replace(/\D/g, ""))}
              onChange={handleChange}
              placeholder="Enter new Pincode"
              className={` ${
                formData.pincode && showError.pincode ? "invalidInput" : ""
              }`}
              maxLength={6}
            />
            {formData.pincode && showError.pincode && (
              <div className="errorLine">ENTER CORRECT PINCODE</div>
            )}
            {formData.pincode && !showError.pincode && !pincode.personal && (
              <div className="errorLine">ENTER VALID PINCODE</div>
            )}
          </div>
        </div>
        {formData.pincode.length === 6 && pincode.personal && (
          <div className="update-input">
            <label>Area</label>
            <div className="alertErrorSection">
              <select
                className="alertErrorSection"
                id="area"
                name="area"
                value={formData.area} // Bind selected area to formData.area
                onChange={handleChange} // Handle changes to the area
                required
              >
                <option value="">Select an Area</option>
                {selectedArea.map(
                  (
                    area,
                    index // Map over selectedArea array
                  ) => (
                    <option key={index} value={area}>
                      {area}
                    </option>
                  )
                )}
              </select>
            </div>
          </div>
        )}

        {formData.pincode.length === 6 && pincode.personal && (
          <div className="update-input">
            <label>City</label>
            <div className="alertErrorSection">
              <input
                type="text"
                name="city"
                value={formData.city}
                readOnly
                onChange={handleChange}
                placeholder="Enter your new City"
              />
              {userType === "individual" && formData.city && (
                <div className="alertLine">
                  WHEN YOU CHANGE CITY,YOUR USER ID WILL ALSO CHANGES.
                </div>
              )}
            </div>
          </div>
        )}

        {formData.pincode.length === 6 && pincode.personal && (
          <div className="update-input">
            <label>State</label>
            <div className="alertErrorSection">
              <input
                type="text"
                name="state"
                value={formData.state}
                readOnly
                onChange={handleChange}
                placeholder="Enter your new State"
              />
            </div>
          </div>
        )}

        {userType === "business" && (
          <div>
            <div className="update-input">
              <label>Business Pincode</label>
              <div className="alertErrorSection">
                <input
                  maxLength={6}
                  type="text"
                  className={` ${
                    formData.businessPincode && showError.businessPincode
                      ? "invalidInput"
                      : ""
                  }`}
                  name="businessPincode"
                  value={
                    (formData.businessPincode =
                      formData.businessPincode.replace(/\D/g, ""))
                  }
                  onChange={handleChange}
                  placeholder="Enter new Business Pincode"
                />
                {formData.businessPincode && showError.businessPincode && (
                  <div className="errorLine">ENTER CORRECT PINCODE</div>
                )}
                {formData.businessPincode &&
                  !showError.businessPincode &&
                  !pincode.business && (
                    <div className="errorLine">ENTER VALID PINCODE</div>
                  )}
              </div>
            </div>
            {formData.businessPincode.length === 6 && pincode.business && (
              <div className="update-input">
                <label>Business Area</label>
                <div className="alertErrorSection">
                  <select
                    name="businessArea"
                    value={formData.businessArea}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select an Area</option>
                    {selectedBusinessArea.map(
                      (
                        area,
                        index // Map over selectedArea array
                      ) => (
                        <option key={index} value={area}>
                          {area}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </div>
            )}

            {formData.businessPincode.length === 6 && pincode.business && (
              <div className="update-input">
                <label>Business City</label>
                <div className="alertErrorSection">
                  <input
                    type="text"
                    name="businessCity"
                    value={formData.businessCity}
                    onChange={handleChange}
                    placeholder="Enter your new Business State"
                    readOnly
                  />
                  {userType === "business" && formData.businessCity && (
                    <div className="alertLine">
                      WHEN YOU CHANGE THE CURRENT PINCODE, YOUR USER ID WILL
                      ALSO CHANGE.
                    </div>
                  )}
                </div>
              </div>
            )}

            {formData.businessPincode.length === 6 && pincode.business && (
              <div className="update-input">
                <label>Business State</label>
                <div className="alertErrorSection">
                  <input
                    type="text"
                    name="businessState"
                    value={formData.businessState}
                    onChange={handleChange}
                    placeholder="Enter your new Business Country"
                    readOnly
                  />
                </div>
              </div>
            )}
          </div>
        )}

        <div className="update-input">
          <label>Profile Image</label>
          <div className="alertErrorSection">
            <input
              type="file"
              name="referenceImage"
              onChange={handleChange}
              accept=".jpg, .jpeg"
            />
          </div>
        </div>

        {userType === "individual" && cityPrefixMatch && formData.pincode && (
          <div className="update-input">
            <label>New UserID </label>
            <div className="alertErrorSection">
              <input
                type="text"
                className="form-control userId-input"
                name="userID"
                value={
                  !cityPrefixMatch
                    ? userId
                    : generateUserID("I", formData.city, registrationCount)
                }
                readOnly // Make the input read-only
                required
                placeholder="New UserId"
              />
              <p className="alertLine">
                PLEASE REMEMBER THIS NEW USERID FOR LOGIN PURPOSES.
              </p>
            </div>
          </div>
        )}

        {userType === "business" &&
          cityPrefixMatch &&
          formData.businessCity && (
            <div className="update-input">
              <label>New UserID </label>
              <div className="alertErrorSection">
                <input
                  type="text"
                  className="form-control userId-input"
                  name="userID"
                  value={
                    !cityPrefixMatch
                      ? userId
                      : generateUserID(
                          "B",
                          formData.businessCity,
                          registrationCount
                        )
                  }
                  readOnly
                  required
                  placeholder="New UserId"
                />
                <p className="alertLine">
                  PLEASE REMEMBER THIS NEW USER ID FOR LOGIN PURPOSES.
                </p>
              </div>
            </div>
          )}
        <div className="save-button">
          <button onClick={handleSave}>Save</button>
        </div>
      </form>
    </div>
  );
};

export default UpdateProfile;
