//email validation
export const emailValidation = (text) => {
    // Regular expression for a more accurate email validation
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(text);
  };
  
  //pincode validation
  export const pincodeValidation = (text) => {
    const pincodeRegex = /^[1-9]\d{5}$/;
    return pincodeRegex.test(text);
  };


  //pan card validation
  export const pancardValidation = (text) => {
    let regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;

    if (regex.test(text)) {
      return true;
    }

    return false;
  };

  //gstn validation 
  export const gstnValidation = (text) => {
    let regex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9A-Z]{1}$/;

    return regex.test(text);
  };

  //pointsCalculations
  export const calculatePoints = (value)=> {
    let baseFee = 50;
    
    if (value >= 5000) {
      const extraThousands = Math.ceil((value - 10000) / 10000);
      const extraFee = extraThousands * 50;
      baseFee += extraFee;
    }
    
    return baseFee;
  }

  //interestPoints
  export const interestPoints = (value)=> {
    let baseFee = 50;
    
    if (value >= 2000) {
      const extraThousands = Math.ceil((value - 10000) / 10000);
      const extraFee = extraThousands * 50;
      baseFee += extraFee;
      baseFee /= 2;
    }
    
    return baseFee;
  }

  //formating date
  export const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

 